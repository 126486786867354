import {TbUnlink} from 'react-icons/tb';

import styles from './.module.css';

const NotFound = () => {
  return (
    <div className={styles['notfound']}>
      <div className={styles['top-pane']}>
        <TbUnlink className={styles['icon']} />
      </div>
      <div className={styles['center-pane']}>
        <p className={styles['title']}>NOT FOUND</p>
        <p className={styles['subtitle']}>
          Sorry, the link you followed may not exist
        </p>
      </div>
      <div className={styles['bottom-pane']} />
    </div>
  );
};

export default NotFound;

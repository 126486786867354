import {Typography} from '@mui/material';
import styles from '../styles/DashboardLayout.module.scss';

const DashboardContent = ({children, title}) => {
  return (
    <div className={styles.dashboardLayout}>
      <Typography variant="h4" fontWeight={500} className={styles.heading}>
        {title}
      </Typography>

      {children}
    </div>
  );
};

export default DashboardContent;

import {useEffect, useReducer, useState} from 'react';
import {format} from 'date-fns';
import {connector} from 'ducks';

import FileActions from '../../FileActions';
import {postInvoice, putInvoice} from 'ducks/invoice';
import styles from '../../../styles/Form.module.scss';
import invoiceForm, {
  invoiceFormInitializer,
  invoiceFormInitState
} from 'reducers/invoice';
import FileTypes from 'components/FileTypes';

import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

const Index = ({dispatch, stateChange, router, invoice}) => {
  const [state, setState] = useReducer(invoiceForm, invoiceFormInitState);
  const [upload, setUpload] = useState(null);

  const routerState = router?.location.state;
  const isEditType = routerState.type === 'EDIT';
  const isAddType = routerState.type === 'ADD';

  const onSetState = (key, val) => {
    setState({
      type: 'SET',
      payload: {[key]: val}
    });
  };

  const onChange = (key) => (e) => {
    if (key === 'date') {
      onSetState(key, format(e, 'yyyy-MM-dd'));
    } else if (key === 'document') {
      onSetState(key, e)
    } else if (key === 'file') {
      setUpload(e);
    } else if (key === 'amount') {
      onSetState(key, Number(e.target.value));
    } else onSetState(key, e.target.value);
  };

  const stateListener = () => {
    if (isEditType) {
      setState(
        invoiceFormInitializer({
          ...routerState,
          coca: {...invoice.data_edit_selected?.proposal?.coca},
          purchase_order: {
            ...invoice.data_edit_selected?.proposal?.purchase_order
          }
        })(state)
      );
    } else if (isAddType) {
      setState(
        invoiceFormInitializer({
          purchase_order: {
            id: routerState.data_selected.po_id,
            po_number: routerState.data_selected.po_number
          },
          coca: {
            id: routerState.data_selected.coca_id,
            title: routerState.data_selected.coca_title
          }
        })(state)
      );
    }
  };
  useEffect(stateListener, [routerState, invoice.data_edit_selected]);

  const onStateChange =  () => {
    if (isAddType) stateChange(postInvoice(state, upload));
    if (isEditType) stateChange(putInvoice(state, upload, routerState.id));
  }
  useEffect(onStateChange, [state]);

  return (
    <form className={styles.form}>
      <Grid container spacing={4}>
        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <Typography mb={2}>
              For Purchase Order Number <span className="required">*</span>
            </Typography>
            <TextField
              variant="outlined"
              value={state.purchase_order.po_number}
              disabled={isEditType}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <Typography mb={2}>
              For Coca Title <span className="required">*</span>
            </Typography>
            <TextField
              variant="outlined"
              value={state.coca.title}
              fullWidth
              disabled={isEditType}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Invoice Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            value={state.invoice_no}
            onChange={onChange('invoice_no')}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Status <span className="required">*</span>
          </Typography>
          <Select onChange={onChange('status')} value={state.status} fullWidth>
            <MenuItem value="10">Sent</MenuItem>
            <MenuItem value="11">Pending</MenuItem>
            <MenuItem value="12">Complete</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Date <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.date}
              onChange={onChange('date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Amount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            value={state.amount}
            onChange={onChange('amount')}
            className={styles.textWidth}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              value={state.document}
              fullWidth
            />
            <FileActions 
              handleFileDrive={onChange('document')} 
              docPath={onChange('file')}
              isAdd={isAddType}
              isEdit={isEditType}
              docFile={state.document}
            />
          </Stack>
          <FileTypes />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

import AvTimerIcon from '@mui/icons-material/AvTimer';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaidIcon from '@mui/icons-material/Paid';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const iconSize = {
  md: '2rem',
  lg: '3rem',
  xl: '4rem'
};

export const iconCategory = {
  PENDING: {
    icon: (
      <PendingActionsIcon
        sx={{
          color: '#C2166A',
          fontSize: iconSize
        }}
      />
    ),
    color: '#C2166A'
  },

  ONGOING: {
    icon: (
      <AvTimerIcon
        sx={{
          color: '#E87E29',
          fontSize: iconSize
        }}
      />
    ),
    color: '#E87E29'
  },

  RENDERED: {
    icon: (
      <CheckCircleOutlineIcon
        sx={{
          color: '#1E989A',
          fontSize: iconSize
        }}
      />
    ),
    color: '#1E989A'
  },

  PAID: {
    icon: (
      <PaidIcon
        sx={{
          color: '#169865',
          fontSize: iconSize
        }}
      />
    ),
    color: '#169865'
  }
};

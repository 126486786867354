import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from '../../Button';
import ModalLayout from '../ModalLayout';

import styles from '../../../styles/ModalLayout.module.scss';

const index = ({open, onClose, type = '', title, content}) => {
  return (
    <ModalLayout open={open} type={type}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={styles.logoutModal}
      >
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h5"> {title} </Typography>
          <Typography variant="p" sx={{width: '50rem !important'}}>
            {content}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2.5}
          justifyContent="center"
          sx={{width: '100%'}}
        >
          <Button
            onClick={onClose}
            buttonType="delete"
            variant="contained"
            label="close"

          />
        </Stack>
      </Stack>
    </ModalLayout>
  );
};

export default index;

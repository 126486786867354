import {replace} from 'redux-first-history';

import {UNAUTHORIZED, INTERNAL_SERVER, TOKEN_EXPIRED} from 'constants/numbers';
import storage from 'utils/storage';

function resolve(store) {
  return function ({data}) {
    return data;
  };
}

function reject(store) {
  return function (res) {
    const {response, message} = res;
    const {status} = response;
    let _message = message;

    if (status > INTERNAL_SERVER) {
      _message = 'INTERNAL SERVER';
    }

    if (UNAUTHORIZED === status || TOKEN_EXPIRED === status) {
      storage.remove('at');
      store.dispatch(replace('/'));
    }

    return Promise.reject(_message);
  };
}

const response = {
  resolve,
  reject
};
export default response;

const SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER';

const POST_PURCHASE_ORDER = 'POST-PURCHASE-ORDER';
const PUT_PURCHASE_ORDER = 'PUT-PURCHASE-ORDER';
const DEL_PURCHASE_ORDER = 'DEL-PURCHASE-ORDER';
const POST_DOC_PURCHASE_ORDER = 'POST_DOC_PURCHASE+ORDER';
const GET_ALL_PURCHASE_ORDER = 'GET_ALL_PURCHASE_ORDER';
const GET_PURCHASE_ORDER_DETAILS = 'GET_PURCHASE_ORDER_DETAILS';
const SET_ERROR_PURCHASE_ORDER = 'SET_ERROR_PURCHASE_ORDER';

export {
  SET_PURCHASE_ORDER,
  PUT_PURCHASE_ORDER,
  POST_PURCHASE_ORDER,
  DEL_PURCHASE_ORDER,
  POST_DOC_PURCHASE_ORDER,
  GET_ALL_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_DETAILS,
  SET_ERROR_PURCHASE_ORDER
};

import {useCallback, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardLayout from 'components/DashboardLayout';
import StepFormLayout from 'components/StepFormLayout';

import AddFormButton from 'components/AddFormButton';
import EditFormButton from 'components/EditFormButton';
import CocaEditForm from 'components/Forms/CocaEditForm';
import CocaForm from 'components/Forms/CocaForm';
import CancelModal from 'components/Modals/Cancel';
import SaveButton from 'components/StepFormButtons/SaveButton';

import {connector} from 'ducks';
import {COCA, DASHBOARD} from 'utils/const';
import {getAllCOCA, getIDDetailCOCA, putCOCA} from 'ducks/coca';

import styles from 'styles/Form.module.scss';
import cocaForm, {cocaFormInitState} from 'reducers/coca';

const EditCoca = ({dispatch, router}) => {
  const {location} = router;
  const navigate = useNavigate();

  const [cancel, setCancel] = useState(false);
  const [cocaData, setCocaData] = useState({});

  const [state, setState] = useReducer(cocaForm, cocaFormInitState);

  const actionState = (action) => {
    setState({type: 'SET', payload: {actions: action}});
  };

  const onSubmit = () => {
    dispatch(state.actions);
    dispatch(getAllCOCA({page: 1, size: 10}));
  };

  const handleSave = () => {
    if (
      cocaData.purchase_order &&
      cocaData.title &&
      cocaData.original_amount &&
      cocaData.discount &&
      cocaData.document &&
      cocaData.discounted_amount &&
      cocaData.start_date !== null &&
      cocaData.end_date !== null &&
      cocaData.end_date >= cocaData.start_date
    ) {
      dispatch(putCOCA(cocaData, cocaData.id));
    } else {
      alert('INVALID INPUT! Please review your inputs.');
    }
  };

  const handleCancelForm = useCallback(() => {
    navigate(`/${DASHBOARD}/${COCA}`);
  }, []);

  const initListener = () => {
    dispatch(getIDDetailCOCA({id: location.state.id}));
  };
  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <CancelModal
        open={cancel}
        onClose={() => setCancel(false)}
        onCancel={handleCancelForm}
      />
      <StepFormLayout variant="edit-form" active={2}>
        <div className={styles.titleSpacing}>
          <Typography variant="h4">EDIT COCA</Typography>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">COCA DETAILS</Typography>
          {/* <Stack spacing={2} direction="row">
            <AddFormButton title="Coca" toastSucess="COCA">
              <CocaForm setCocaData={setCocaData} />
            </AddFormButton>
            <EditFormButton title="COCA">
              <CocaEditForm />
            </EditFormButton>
          </Stack> */}
        </Stack>
        <CocaEditForm
          cocaData={cocaData}
          setCocaData={setCocaData}
          router={location}
          actionState={actionState}
        />
        <SaveButton
          title="Save"
          absolute
          onSave={onSubmit}
          onClose={() => setCancel(true)}
        />
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(EditCoca);

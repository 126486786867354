import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setCOCA} from '../actions';

function* getAll({type, params}) {
  try {
    const {
      result: {data, metadata}
    } = yield call(poServer.get, '/api/v1/coca/list', {
      params
    });
    yield put(
      setCOCA({
        data,
        size: metadata.current_size,
        page: metadata.current_page,
        total_page: metadata.total_page,
        total_data: metadata.total_size
      })
    );
  } catch (error) {
    yield put(setCOCAError(error));
  } finally {
    yield put(setCOCA({loading: false}));
  }
}

export default getAll;

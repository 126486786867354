import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';

import {setCOCA} from '../actions';

function* del({type, payload}) {
  try {
    yield put(setCOCA({loading: true}));
    const result = yield call(poServer.del, '/api/v1/coca', payload);
    yield put(push('/dashboard/coca'));
  } catch (error) {
  } finally {
    yield put(setCOCA({loading: false}));
  }
}

export default del;

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import styles from '../../../styles/ModalLayout.module.scss';

import {category} from './category.const';

const index = ({children, open, onClose, type = ''}) => {
  const getStyle = () => category[type.toUpperCase()];

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Box
        className={styles.modalContent}
        style={{borderTop: `.75rem solid ${getStyle()?.color}`}}
      >
        <div
          className={styles.modalIcon}
          style={{background: getStyle()?.color}}
        >
          {getStyle()?.icon}
        </div>

        <div className={styles.modalContentNode}>{children}</div>
      </Box>
    </Modal>
  );
};

export default index;

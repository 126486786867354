import {takeLatest} from 'redux-saga/effects';

import {
  GET_ALL_GOOD_RECEIPT,
  GET_GOOD_RECEIPT_DETAIL,
  POST_GOOD_RECEIPT,
  POST_GOOD_RECEIPT_DOC,
  DELETE_GOOD_RECEIPT,
  DELETE_GOOD_RECEIPT_DOC,
  PUT_GOOD_RECEIPT_DOC,
  PUT_GOOD_RECEIPT,
  GET_GOOD_RECEIPT,
  SEARCH_GOOD_RECEIPT,
  GET_ONE_GOOD_RECEIPT
} from '../types';

import getAll from './getAll';
import get from './get';
import getDetail from './getDetail';
import post from './post';
import delById from './delete';
import delDoc from './deleteDoc';
import postDoc from './postDoc';
import putDoc from './putDoc';
import edit from './put';
import search from './search';
import getOne from './getOne';

function* goodReceiptSaga() {
  yield takeLatest(GET_ALL_GOOD_RECEIPT, getAll);
  yield takeLatest(GET_GOOD_RECEIPT, get);
  yield takeLatest(GET_GOOD_RECEIPT_DETAIL, getDetail);
  yield takeLatest(GET_ONE_GOOD_RECEIPT, getOne);
  yield takeLatest(POST_GOOD_RECEIPT, post);
  yield takeLatest(POST_GOOD_RECEIPT_DOC, postDoc);
  yield takeLatest(DELETE_GOOD_RECEIPT, delById);
  yield takeLatest(DELETE_GOOD_RECEIPT_DOC, delDoc);
  yield takeLatest(PUT_GOOD_RECEIPT, edit);
  yield takeLatest(PUT_GOOD_RECEIPT_DOC, putDoc);
  yield takeLatest(SEARCH_GOOD_RECEIPT, search);
}

export default goodReceiptSaga;

import {useState} from 'react';

import Button from '@mui/material/Button';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import AddFormModal from '../Modals/AddFormModal';

const Index = ({children, title = ''}) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <AddFormModal
        title={`ADD ${title.toUpperCase()} ENTRY`}
        open={openForm}
        onClose={() => setOpenForm(false)}
      >
        {children}
      </AddFormModal>
      <Button
        variant="text"
        sx={{fontSize: {md: '.7rem', lg: '.8rem', xl: '.9rem'}}}
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={() => setOpenForm(true)}
      >
        Add new {title}
      </Button>
    </>
  );
};

export default Index;

import {useState} from 'react';

import FileActions from '../../FileActions';

import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';

const Index = ({divider = false}) => {
  const [endtDate, setEndDate] = useState(null);

  return (
    <form className={styles.form}>
      {divider && <Divider sx={{marginBottom: '3rem', borderWidth: '2px'}} />}

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            For Purchase Order Number <span className="required">*</span>
          </Typography>
          <Select fullWidth>
            <MenuItem>Ongoing</MenuItem>
            <MenuItem>Paid</MenuItem>
            <MenuItem>Pending</MenuItem>
            <MenuItem>Rendered</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            For COCA Title <span className="required">*</span>
          </Typography>
          <TextField variant="outlined" fullWidth disabled />
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Status <span className="required">*</span>
          </Typography>
          <Select fullWidth>
            <MenuItem>Ongoing</MenuItem>
            <MenuItem>Paid</MenuItem>
            <MenuItem>Pending</MenuItem>
            <MenuItem>Rendered</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Invoice Number <span className="required">*</span>
          </Typography>
          <Select fullWidth>
            <MenuItem>Ongoing</MenuItem>
            <MenuItem>Paid</MenuItem>
            <MenuItem>Pending</MenuItem>
            <MenuItem>Rendered</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Date <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={endtDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Amount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            className={styles.textWidth}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              fullWidth
            />
            <FileActions />
          </Stack>
          <FileTypes />
        </Grid>
      </Grid>
    </form>
  );
};

export default Index;

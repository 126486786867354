import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setInvoice} from '../actions';

function* getOne({type, payload}) {
  try {
    const {result: proposal} = yield call(
      poServer.get,
      `/api/v1/invoice/${payload.id}`
    );
    //GET REQUEST
    yield put(
      setInvoice({
        data_edit_selected: {
          proposal
        }
      })
    );
  } catch (error) {
    yield put(setInvoice({error}));
  } finally {
    yield put(setInvoice({loading: false}));
  }
}

export default getOne;

import {useReducer} from 'react';

import FileActions from '../../FileActions';

import {Grid, Stack, TextField, Typography} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';
import grForm, {grFormInitState} from 'reducers/gr';
import {format} from 'date-fns';
import {connector} from 'ducks';
import {postGoodReceipt} from 'ducks/good_receipt';

const Index = ({dispatch}) => {
  const [state, setState] = useReducer(grForm, grFormInitState);

  const onSetState = (key, val) => {
    setState({
      type: 'SET',
      payload: {[key]: val}
    });
  };

  const onChange = (key) => (e) => {
    if (key === 'start_date' || key === 'end_date') {
      onSetState(key, format(e, 'yyyy-MM-dd'));
    } else onSetState(key, e.target.value);
  };

  const handleSubmit = () => {
    dispatch(postGoodReceipt(state));
  };

  return (
    <form className={styles.form}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            For Purchase Order Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={state.purchase_order.po_number}
            disabled
          />
        </Grid>

        <Grid item xs={5}>
          <Typography mb={2}>
            For COCA Title <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            onChange={onChange('title')}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            GR Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            onChange={onChange('gr_number')}
            fullWidth
          />
        </Grid>

        <Grid item xs={5}>
          <Typography mb={2}>
            Date Created <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.date_created}
              onChange={onChange('date_created')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              value={state.document}
              fullWidth
            />
            <FileActions handleFileDrive={onChange('document')} />
          </Stack>
          <FileTypes />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

import {useEffect, useReducer, useState} from 'react';

import FileActions from '../../FileActions';

import {Divider, Grid, Stack, TextField, Typography} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';
import grForm, {grFormInitState, grInitializer} from 'reducers/gr';
import {putGoodReceipt} from 'ducks/good_receipt';
import {connector} from 'ducks';

const Index = ({divider = false, router, actionState, goodReceipt}) => {
  const routerState = router?.location.state;
  const [state, setState] = useReducer(grForm, grFormInitState);
  const [upload, setUpload] = useState(null);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };

  const onChange = (key) => (e) => {
    if (key === 'date_created') onSetState(key, e.toISOString());
    else if (key === 'document') onSetState(key, e);
    else if (key === 'file') setUpload(e);
    else onSetState(key, e.target.value);
  };
  const stateListener = () => {
    setState(
      grInitializer({
        ...routerState,
        coca: {...goodReceipt.data_edit_selected?.proposal?.coca},
        purchase_order: {
          ...goodReceipt.data_edit_selected?.proposal?.purchase_order
        }
      })(state)
    );
  };
  useEffect(stateListener, [goodReceipt.data_edit_selected]);

  const onActionState = () => {
    actionState(putGoodReceipt(state, upload, routerState.id));
  }
  useEffect(onActionState, [state, upload, routerState.id])

  return (
    <form className={styles.form}>
      {divider && <Divider sx={{marginBottom: '3rem', borderWidth: '2px'}} />}

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            For Purchase Order Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={state.purchase_order.po_number}
            disabled
            onChange={onChange('po_number')}
          />
        </Grid>

        <Grid item xs={6}></Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            GR Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={state.gr_no}
            onChange={onChange('gr_no')}
          />
        </Grid>

        <Grid item xs={4.8}>
          <Typography mb={2}>
            Date Created <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.date_created}
              onChange={onChange('date_created')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              fullWidth
              value={state.document}
            />
            <FileActions 
              handleFileDrive={onChange('document')} 
              docPath={onChange('file')}
              docFile={state.document}
              isEdit  
            />
          </Stack>
          <FileTypes />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

import {isType} from 'utils';

const initState = {
  is_collapsed: false,
  is_checked: false
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {is_collapsed, is_checked} = action.payload;
      return {
        is_collapsed: isType('boolean', is_collapsed, state.is_collapsed),
        is_checked: isType('boolean', is_checked, state.is_checked)
      };
    default:
      return state;
  }
};

export {initState};

export default reducer;

import {Outlet} from 'react-router-dom';

import {homeRoutes} from './routes';
import styles from './.module.css';

const Home = () => {
  return (
    <div className={styles['home-pane']}>
      <Outlet />
    </div>
  );
};

export {homeRoutes};
export default Home;

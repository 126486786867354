import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {connector} from 'ducks';
import {getAllPurchaseOrder, setErrorPurchaseOrder} from 'ducks/PO';
import {getAllStatus, getStatusCount} from 'ducks/status';
import {setErrorCoca} from 'ducks/coca';
import {setErrorGoodReceipt} from 'ducks/good_receipt';
import {setErrorProposalResource} from 'ducks/proposal_resource';
import {setErrorInvoice} from 'ducks/invoice';

import {dashboardRoutes} from './routes';
import styles from './.module.css';
import {getStatusPrefixName} from 'utils/helper';

const Dashboard = ({purchaseOrder, status, dispatch = () => {}}) => {
  const initListener = () => {
    const {page, size, sortBy, orderBy} = purchaseOrder;
    dispatch(getAllPurchaseOrder({page, size, sortBy, orderBy}));
    dispatch(getAllStatus({module: 'ALL'}));
    dispatch(getStatusCount());

    dispatch(setErrorCoca(''));
    dispatch(setErrorGoodReceipt(''));
    dispatch(setErrorProposalResource(''));
    dispatch(setErrorPurchaseOrder(''));
    dispatch(setErrorInvoice(''));
  };
  const statusListener = () => {
    const root_vars = document.querySelector(':root');
    for (const data of status.data) {
      const {name, color} = data;
      const status_name = getStatusPrefixName(name);
      root_vars.style.setProperty(`--${status_name.toUpperCase()}`, `${color}`);
    }
  };

  useEffect(initListener, []);
  useEffect(statusListener, [status]);
  return (
    <div className={styles['dashboard-pane']}>
      <Outlet />
    </div>
  );
};

export {dashboardRoutes};
export default connector(Dashboard);

import {useEffect, useState, useReducer} from 'react';

import {
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import FileActions from '../../FileActions';
import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';
import proposalForm, {proposalInitState} from '../../../reducers/proposal';
import {
  postProposalResource,
  putProposalResource
} from 'ducks/proposal_resource';

import {connector} from 'ducks';
import {format} from 'date-fns';
import {Toast} from 'components';
import {isAlphaNumeric} from 'utils/helper';


const Index = ({dispatch, proposalResourceData, stateChange, router}) => {
  const [state, setState] = useReducer(proposalForm, proposalInitState);
  const [upload, setUpload] = useState(null);
  const [errorToast, setErrorToast] = useState(false);

  const isAddType = router?.location.state.type === 'ADD';
  const isEditType = router?.location.state.type === 'EDIT';
  const routerState = router?.location.state;

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };
  const onChange = (key) => (e) => {
    if (key === 'date') onSetState(key, format(e, 'yyyy-MM-dd'));
    else if (key === 'proposal_no') {
      if (isAlphaNumeric(e.target.value)) {
        setErrorToast(false);
        onSetState(key, e.target.value);
      } else setErrorToast(true);
    } else if (key === 'document') {
      onSetState(key, e);
    } 
    else if (key === 'file') {
      setUpload(e);
    } 
    else onSetState(key, e.target.value);
  };

  const handleDeleteFile = (e) => {
    if (e) {
      onSetState('document', '');
      return false;
    }
  };

  const stateListener = () => {
    if (isEditType) {
      setState({
        type: 'SET',
        payload: {
          name: routerState.name,
          status: routerState.status.id,
          proposal_no: routerState.proposal_no,
          date: routerState.date,
          document: routerState.document
        }
      });
    }
  };
  useEffect(stateListener, [routerState]);

  const onStateChange = () => {
    if (isAddType) {
      stateChange(postProposalResource(state, upload));
    }
    if (isEditType) {
      stateChange(putProposalResource(state, upload, routerState.id));
    }
  };
  useEffect(onStateChange, [state]);

  return (
    <form className={styles.form}>
      <Toast 
        severity="warning" 
        open={errorToast} 
        title="Proposal ID field cannot contain special characters." 
        vertical="top" 
        horizontal="right" 
      />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            Name <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            value={state.name}
            onChange={onChange('name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={4.5}>
          <Typography mb={2}>
            Status <span className="required">*</span>
          </Typography>
          <Select fullWidth value={state.status} onChange={onChange('status')}>
            <MenuItem value={13}>Signed</MenuItem>
            <MenuItem value={14}>Pending</MenuItem>
            <MenuItem value={15}>Rejected</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Proposal ID <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={state.proposal_no}
            onChange={onChange('proposal_no')}
          />
        </Grid>
        <Grid item xs={4.5}>
          <Typography mb={2}>
            Date <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.date}
              onChange={onChange('date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={10.5}>
          <Typography mb={2}>
            Document File{' '}
            <span className="required" disabled>
              *
            </span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              value={state.document}
              fullWidth
            />
            <FileActions
              handleFileDrive={onChange('document')}
              handleDelFile={handleDeleteFile}
              docPath={onChange('file')}
              isEdit={isEditType}
              isAdd={isAddType}
              docFile={isEditType ? state.document : ''}
            />
          </Stack>
          <FileTypes />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

import {combineReducers} from 'redux';

import proposalResource from './proposal_resource';
import coca from './coca';
import goodReceipt from './good_receipt';
import invoice from './invoice';
import pokemon from './pokemon';
import purchaseOrder from './PO';
import auth from './auth';
import status from './status';
import documents from './document';

function reducer(router) {
  return combineReducers({
    router,
    pokemon,
    auth,
    invoice,
    goodReceipt,
    purchaseOrder,
    proposalResource,
    coca,
    status,
    documents
  });
}

export default reducer;

import {takeLatest} from 'redux-saga/effects';

import {GET_POKEMONS} from '../types';
import get from './get';

function* pokemonSaga() {
  yield takeLatest(GET_POKEMONS, get);
}

export default pokemonSaga;

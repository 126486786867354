const GET_INVOICE = 'GET-INVOICE';
const GET_ALL_INVOICE = 'GET-ALL-INVOICE';
const GET_ONE_INVOICE = 'GET-ONE-INVOICE';
const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';

const POST_INVOICE = 'POST-INVOICE';

const PUT_INVOICE = 'PUT-INVOICE';

const SET_INVOICE = 'SET_INVOICE';

const DELETE_INVOICE = 'DELETE-INVOICE';
const SEARCH_INVOICE = 'SEARCH_INVOICE';
const SET_ERROR_INVOICE = 'SET_ERROR_INVOICE';

export {
  GET_INVOICE,
  GET_ALL_INVOICE,
  POST_INVOICE,
  SET_INVOICE,
  DELETE_INVOICE,
  SEARCH_INVOICE,
  GET_INVOICE_DETAILS,
  GET_ONE_INVOICE,
  PUT_INVOICE,
  SET_ERROR_INVOICE
};

import {poServer} from 'api';
import {call} from 'redux-saga/effects';

function* del({type, payload}) {
  try {
    const result = yield call(poServer.del, '/api/v1/purchase-order', payload);
  } catch (error) {
  } finally {
  }
}

export default del;

import {takeLatest} from 'redux-saga/effects';
import {AUTH_LOGIN, AUTH_LOGOUT} from '../types';

import login from './login';
import logout from './logout';

function* authSaga() {
  yield takeLatest(AUTH_LOGIN, login);
  yield takeLatest(AUTH_LOGOUT, logout);
}

export default authSaga;

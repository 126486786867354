import {useEffect} from 'react';

import {ModalContext} from 'context/Modal';
import {replace} from 'redux-first-history';
import {connector} from 'ducks';

import storage from 'utils/storage';

import Dashboard, {dashboardRoutes} from './Dashboard';
import Home, {homeRoutes} from './Home';
import {PrivateRoute} from './components';

import 'styles/App.scss';

const Navigator = ({dispatch, history, router, auth}) => {
  const isAuth = auth.data.authorized || storage.get('at');

  // const initListener = () => {};
  // useEffect(initListener, []);

  const initListener = () => {
    if (!isAuth) {
      dispatch(replace('/'));
      storage.removeAll();
      return;
    }
    dispatch(replace('/dashboard'));
  };
  useEffect(initListener, []);

  return (
    <ModalContext.Provider value={{show: true, component: <></>}}>
      <PrivateRoute
        history={history}
        basepath={isAuth ? '/dashboard' : '/'}
        navigator={isAuth ? Dashboard : Home}
        routes={isAuth ? dashboardRoutes : homeRoutes}
      />
    </ModalContext.Provider>
  );
};

export default connector(Navigator);

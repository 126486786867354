import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setDocument, setErrorDownload} from '../actions';

function* getDoc({document}) {
    try {
        yield put(setDocument({loading: true}));
        const res = yield call(poServer.get, `/documents/${document}`);
        yield put(setDocument({data: res}));
    } catch (error) {
        yield put(setErrorDownload(error));
    } finally {
        yield put(setDocument({loading: false}));
    }
}

export default getDoc;
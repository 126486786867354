import {isType} from 'utils';

import {
  getPokemons,
  patchPokemon,
  postPokemon,
  postPokemons,
  pushPokemon
} from './actions';
import pokemonSaga from './saga';
import {GET_POKEMONS, POST_POKEMON, SET_POKEMON} from './types';

const initState = {
  data: [],
  selected_data: {},
  size: 0,
  page: 0,
  total_data: 0,
  error: '',
  loading: false
};

const pokemon = (state = initState, action) => {
  switch (action.type) {
    /* REQUEST */
    case GET_POKEMONS:
      return {...state, loading: true};

    case POST_POKEMON:
      return {...state, loading: true};

    /* REDUX-STATE */
    case SET_POKEMON:
      const {data, selected_data, size, page, total_data, error, loading} =
        action.payload;
      return {
        data: isType('array', data, state.data),
        selected_data: isType('object', selected_data, state.selected_data),
        size: isType('number', size, state.size),
        page: isType('number', page, state.page),
        total_data: isType('number', total_data, state.total_data),
        error: isType('string', error, state.error),
        loading: isType('boolean', loading, state.loading)
      };

    default:
      return state;
  }
};

export {
  pokemonSaga,
  getPokemons,
  patchPokemon,
  postPokemon,
  postPokemons,
  pushPokemon
};
export default pokemon;

import {isType} from 'utils';

import purchaseOrderSaga from './saga';
import {
  postPurchaseOrder,
  putPurchaseOrder,
  delPurchaseOrder,
  getAllPurchaseOrder,
  setPurchaseOrder,
  setErrorPurchaseOrder
} from './actions';
import {SET_PURCHASE_ORDER, GET_ALL_PURCHASE_ORDER, SET_ERROR_PURCHASE_ORDER} from './types';

const initState = {
  data: [],
  page: 1,
  size: 10,
  sortBy: 'start_date',
  orderBy: 'DESC',
  total_data: 0,
  total_page: 0,
  error: '',
  loading: false,
  data_selected: {}
};

const purchaseOrder = (state = initState, action) => {
  switch (action.type) {
    case SET_ERROR_PURCHASE_ORDER: 
      return {
        ...state,
        error: action.payload
      }
    case SET_PURCHASE_ORDER:
      const {
        data,
        error,
        loading,
        page,
        size,
        sortBy,
        orderBy,
        total_page,
        total_data,
        data_selected
      } = action.payload;
      return {
        data: isType('object', data, state.data),
        page: isType('number', page, state.page),
        size: isType('number', size, state.size),
        sortBy: isType('string', sortBy, state.sortBy),
        orderBy: isType('string', orderBy, state.orderBy),
        total_data: isType('number', total_data, state.total_data),
        total_page: isType('number', total_page, state.total_page),
        error: isType('string', error, state.error),
        loading: isType('boolean', loading, state.loading),
        data_selected: isType('object', data_selected, state.data_selected)
      };

    case GET_ALL_PURCHASE_ORDER:
      return {...state, loading: true};

    default:
      return state;
  }
};

export {
  purchaseOrderSaga,
  postPurchaseOrder,
  putPurchaseOrder,
  delPurchaseOrder,
  getAllPurchaseOrder,
  setPurchaseOrder,
  setErrorPurchaseOrder
};
export default purchaseOrder;

import {useEffect, useReducer, useState} from 'react';

import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import {push} from 'redux-first-history';
import {filterRequest} from 'api';

import {connector} from 'ducks';
import {
  getAllProposalResource,
  getIDDetailProposalResource,
  deleteProposalResource,
  setErrorProposalResource
} from 'ducks/proposal_resource';

import DashboardContent from 'components/DashboardContent';
import DashboardLayout from 'components/DashboardLayout';
import FilterWithDropdown from 'components/Filter/FilterWithDropdown';
import DashboardTable from 'components/Table/DashboardTable';
import TableRownUpload from 'components/Table/TableRownUpload';
import TableFilterWrapper from 'components/TableFilterWrapper';

import {
  PROPOSAL_COLUMNS,
  PROPOSAL_FILTER_OPTION,
  ROW_OPTION
} from 'constants/arrays';
import styles from 'styles/Table.module.scss';
import {toPrettyData} from 'utils';
import {moduleInitState, moduleReducer} from 'reducers';

import {setErrorPurchaseOrder} from 'ducks/PO';
import {setErrorCoca} from 'ducks/coca';
import {setErrorGoodReceipt} from 'ducks/good_receipt/actions';
import {setErrorInvoice} from 'ducks/invoice/actions';

const Proposal = ({dispatch, proposalResource}) => {
  const [state, setState] = useReducer(moduleReducer, moduleInitState);

  const onFilterRequest = (params) => {
    const _params = filterRequest({
      state,
      params,
      reducer: proposalResource
    });
    dispatch(getAllProposalResource(_params));
  };

  const onClick = (ae) => (value) => {
    const item = state.selected_item.length ? state.selected_item[0] : [];
    const proposalID = item.id;
    if (ae === 'search') onFilterRequest({[ae]: value});
    if (ae === 'add') {
      dispatch(
        push('/dashboard/add', {
          proposal_id: proposalID,
          type: 'ADD',
          // if an entry is selected, it will proceed to purchase order form
          // and set proposal_id
          module: !proposalID ? 0 : 1
        })
      );
    }
    if (ae === 'edit') {
      dispatch(
        push('/dashboard/proposal/edit', {
          ...item,
          type: 'EDIT'
        })
      );
    }
  };
  const onSelect = (item) => {
    setState({type: 'SELECT-ITEM', payload: item});
  };

  const onPaginate = (ae) => (event, page) => {
    if (ae === 'page') onFilterRequest({[ae]: page + 1});
    if (ae === 'size') {
      const size = event.target.value;
      onFilterRequest({size, page: 1});
    }
  };
  const initListener = () => {
    dispatch(getAllProposalResource({page: 1, size: 10}));
    dispatch(setErrorCoca(''));
    dispatch(setErrorGoodReceipt(''));
    dispatch(setErrorProposalResource(''));
    dispatch(setErrorPurchaseOrder(''));
    dispatch(setErrorInvoice(''));
  };
  useEffect(initListener, []);

  // For future use
  // const onDelete = (id) => {
  //   dispatch(deleteProposalResource({ids: [id]}));
  // };

  return (
    <DashboardLayout>
      <DashboardContent title="Proposals">
        <TableFilterWrapper>
          <FilterWithDropdown
            selectItems={PROPOSAL_FILTER_OPTION}
            title="Proposal"
            state={state}
            setState={setState}
            onAdd={onClick('add')}
            onEdit={onClick('edit')}
            onSearch={onClick('search')}
            add
          />
          <DashboardTable
            className={styles.tableWrapper}
            headTitles={PROPOSAL_COLUMNS}
            rightSpace
          >
            <TableBody
              className={`${styles.tableBody} ${styles.overviewTable}`}
            >
              {proposalResource.data.length > 0
                ? toPrettyData(proposalResource.data, [
                    'id',
                    'proposal_no',
                    'name',
                    'date',
                    'document',
                    // Field/s hidden in table
                    'status'
                  ]).map((content, index) => (
                    <TableRownUpload
                      key={index}
                      content={content}
                      index={index}
                      module="proposal"
                      path="/dashboard/proposal"
                      selected={proposalResource.data_selected}
                      onSelectItem={onSelect}
                      action={getIDDetailProposalResource}
                    />
                  ))
                : []}
            </TableBody>
          </DashboardTable>
        </TableFilterWrapper>
        <TablePagination
          component="div"
          rowsPerPageOptions={ROW_OPTION}
          className={styles.pagination}
          count={proposalResource.total_data}
          rowsPerPage={proposalResource.size}
          page={proposalResource.page - 1}
          onPageChange={onPaginate('page')}
          onRowsPerPageChange={onPaginate('size')}
        />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default connector(Proposal);

import {useState} from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';

import {ROLE} from 'constants/strings';
import {connector} from 'ducks';
import {deleteProposalResource} from 'ducks/proposal_resource';
import {deleteCOCA} from 'ducks/coca';

import DeleteModal from '../Modals/Delete';

import styles from '../../styles/TableFilter.module.scss';

import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import {Dropdown} from 'components';

const FilterWithDropdown = ({
  state,
  title,
  onEdit,
  onAdd,
  add = false,
  dispatch,
  selectItems = [],
  setState = () => {},
  onSearch = () => {}
}) => {
  const has_one_selected = state.selected_item?.length === 1;

  const [deleteModal, setDeleteModal] = useState(false);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };

  const onChange = (ae) => (event) => {
    let value = event.target?.value;
    if (ae === 'search_by') onSetState(ae, event);
    if (ae === 'search') onSetState(ae, value);
    if (ae === 'search' && value.length <= 0) onSearch('');
  };
  const onCLick = (ae) => (event) => {
    if (ae === 'search' && event.key === 'Enter') {
      const {search, search_by} = state;
      onSearch({search, search_by});
    }
  };

  return (
    <div className={styles.filterWithDropdown}>
      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={() => {
          if (title === 'Proposal') {
            dispatch(deleteProposalResource({ids: state.selected_item}));
          }
          if (title === 'coca') {
            dispatch(deleteCOCA({ids: state.selected_item}));
          }
          setDeleteModal(false);
        }}
        title="Delete Entry ?"
        content="Are you sure you want to delete this entry? This action can’t be reverted."
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={{md: 2, lg: 3, xl: 4}}
      >
        <TextField
          className={styles.searchInput}
          variant="outlined"
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={onChange('search')}
          onKeyDown={onCLick('search')}
        />
        <Dropdown
          selected={state.search_by}
          selectedChange={onChange('search_by')}
          data={selectItems}
          placeholder="Select --"
        />
        {/* <Select
          className={styles.dropdown}
          defaultValue={'25'}
          onChange={onChange('search_by')}
        >
          {selectItems.map((item, indexItem) => (
            <MenuItem value={item.value} key={indexItem}>
              {item.label}
            </MenuItem>
          ))}
        </Select> */}

        <Stack direction="row" spacing={1} ml={10}>
          <IconButton
            disabled={!has_one_selected}
            onClick={() => setDeleteModal(true)}
          >
            <DeleteOutlineIcon
              id={has_one_selected ? 'red-btn' : ''}
              sx={{md: '1.25rem', xl: 'auto'}}
            />
          </IconButton>
          {add && (
            <IconButton
              disabled={title === 'Proposal' ? false : !has_one_selected}
              onClick={onAdd}
            >
              <AddCircleOutlineIcon
                id={has_one_selected || title === 'Proposal' ? 'blue-btn' : ''}
                sx={{md: '1.25rem', xl: 'auto'}}
              />
            </IconButton>
          )}

          <IconButton disabled={!has_one_selected} onClick={onEdit}>
            <EditOutlinedIcon
              id={has_one_selected ? 'blue-btn' : ''}
              sx={{md: '1.25rem', xl: 'auto'}}
            />
          </IconButton>
        </Stack>
      </Stack>
    </div>
  );
};
export default connector(FilterWithDropdown);

import {useEffect, useReducer} from 'react';

import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';

import {connector} from 'ducks';
import {getAllCOCA, getIDDetailCOCA} from 'ducks/coca';

import DashboardContent from '../../components/DashboardContent';
import DashboardLayout from '../../components/DashboardLayout';
import FilterWithDropdown from '../../components/Filter/FilterWithDropdown';
import DashboardTable from '../../components/Table/DashboardTable';
import TableRowDefault from '../../components/Table/TableRowDefault';
import TableFilterWrapper from '../../components/TableFilterWrapper';

import styles from '../../styles/Table.module.scss';
import {toPrettyData} from 'utils';
import {COCA_COLUMNS, COCA_FILTER_OPTION, ROW_OPTION} from 'constants/arrays';
import {filterRequest} from 'api';
import {moduleInitState, moduleReducer} from 'reducers';
import {push} from 'redux-first-history';

import {setErrorPurchaseOrder} from 'ducks/PO';
import {setErrorProposalResource} from 'ducks/proposal_resource';
import {setErrorCoca} from 'ducks/coca';
import {setErrorGoodReceipt} from 'ducks/good_receipt/actions';
import {setErrorInvoice} from 'ducks/invoice/actions';


const Coca = ({dispatch, coca}) => {
  const [state, setState] = useReducer(moduleReducer, moduleInitState);

  const onSetState = (key, val) => {
    setState({type: 'SET', [key]: val});
  };
  const onFilterRequest = (params) => {
    const _params = filterRequest({
      state,
      params,
      reducer: coca
    });
    dispatch(getAllCOCA(_params));
  };

  const onClick = (ae) => (value) => {
    const item = state.selected_item.length ? state.selected_item[0] : [];
    if (ae === 'search') onFilterRequest({[ae]: value});
    if (ae === 'add') {
      dispatch(
        push('/dashboard/add', {
          module: 3,
          data_selected: item
        })
      );
    }
    if (ae === 'edit') {
      dispatch(push('/dashboard/coca/edit', item));
    }
  };
  const onSelect = (item) => {
    setState({type: 'SELECT-ITEM', payload: item});
  };

  const onPaginate = (ae) => (event, page) => {
    if (ae === 'page') onFilterRequest({[ae]: page + 1});
    if (ae === 'size') {
      const size = event.target.value;
      onFilterRequest({size, page: 1});
    }
  };

  const initListener = () => {
    dispatch(getAllCOCA({page: 1, size: 10}));
    dispatch(setErrorCoca(''));
    dispatch(setErrorGoodReceipt(''));
    dispatch(setErrorProposalResource(''));
    dispatch(setErrorPurchaseOrder(''));
    dispatch(setErrorInvoice(''));
  };
  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <DashboardContent title="Certificate of Completion Acceptance">
        <TableFilterWrapper>
          <FilterWithDropdown
            add
            selectItems={COCA_FILTER_OPTION}
            title="coca"
            state={state}
            setState={setState}
            onAdd={onClick('add')}
            onEdit={onClick('edit')}
            onSearch={onClick('search')}
          />
          <DashboardTable
            className={styles.tableWrapper}
            headTitles={COCA_COLUMNS}
            rightSpace
          >
            <TableBody
              className={`${styles.tableBody} ${styles.overviewTable}`}
            >
              {coca.data.length > 0
                ? toPrettyData(coca.data, [
                    'id',
                    'title',
                    'start_date',
                    'original_amount',
                    'discounted_amount',
                    'end_date',
                    'status',
                    'document',
                    // Fields hidden in table
                    'po_number',
                    'discount',
                    'purchase_order',
                    'po_id'
                  ]).map((content, index) => (
                    <TableRowDefault
                      index={index}
                      content={content}
                      key={index}
                      path="/dashboard/coca"
                      selected={coca.data_selected}
                      onSelectItem={onSelect}
                      action={getIDDetailCOCA}
                    />
                  ))
                : []}
            </TableBody>
          </DashboardTable>
        </TableFilterWrapper>
        <TablePagination
          className={styles.pagination}
          component="div"
          rowsPerPageOptions={ROW_OPTION}
          count={coca.total_data}
          rowsPerPage={coca.size}
          page={coca.page - 1}
          onPageChange={onPaginate('page')}
          onRowsPerPageChange={onPaginate('size')}
        />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default connector(Coca);

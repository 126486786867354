import {useReducer, useState} from 'react';

import {format} from 'date-fns';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import {IconButton} from '@mui/material';
import Link from '@mui/material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {connector} from 'ducks';
import {DownloadFile, Status, FileActions} from 'components';
import styles from 'styles/Table.module.scss';

import reducer, {initState} from './reducers/table_rown_upload';
import TableDropdown from './TableDropdownDetails/TableDropdown';
import AddLink from '../Modals/AddLink';
import {MAX_LENGTH_FILENAME} from 'constants/numbers';

const TableRownUpload = ({
  dispatch,
  index,
  content,
  selected,
  module,
  onSelectItem = () => {},
  onDelete = () => {},
  action = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useReducer(reducer, initState);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };

  const onChange = (key) => (val) => {
    if (key === 'is_collapsed') {
      onSetState(key, !state.is_collapsed);
      if (!state.is_collapsed) {
        dispatch(action({id: content.id}));
      }
    }
    onSetState(key, val);
  };
  const onSelect = () => {
    onSelectItem(content);
  };

  return (
    <>
      <TableRow className={index % 2 === 0 ? styles.highlight : styles.normal}>
        <AddLink open={open} onClose={() => setOpen(false)} />
        <TableCell className={`${styles.tableCellBody} ${styles.checkboxCell}`}>
          <Checkbox onChange={onSelect} />
        </TableCell>
        {Object.keys(content)?.map((item, index) => {
          // To hide data that is needed for the forms but is not part of the table
          if (item === 'status' && module === 'proposal') {
            return;
          }

          if (item !== 'id') {
            if (item === 'status') {
              return (
                <TableCell
                  key={index}
                  align="center"
                  className={`${styles.tableCellBody} ${styles.borderCell} `}
                >
                  <Status {...content[item]} />
                </TableCell>
              );
            }

            if (item === 'document') {
              return (
                <TableCell
                  key={index}
                  align="center"
                  width="150px"
                  className={`${styles.tableCellBody}`}
                >
                  {content[item].includes('https://') &&
                  content[item] !== '' ? (
                    <Link
                      href={content[item]}
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                    >
                      {`${content[item].substring(0, MAX_LENGTH_FILENAME)}...`}
                    </Link>
                  ) : (
                    <DownloadFile fileName={content[item]} />
                  )}
                </TableCell>
              );
            }

            if (
              item === 'date_created' ||
              item === 'start_date' ||
              item === 'end_date'
            ) {
              let date = new Date(content[item]);
              return (
                <TableCell
                  key={index}
                  align="center"
                  className={styles.tableCellBody}
                >
                  {format(date, 'yyyy-MM-dd')}
                </TableCell>
              );
            }

            // To hide data that is needed for the form but is not part of the table
            if (
              item === 'coca_id' ||
              item === 'po_id' ||
              item === 'coca_title'
            ) {
              return;
            }

            return (
              <TableCell
                key={`${content[item]}-${index}`}
                align="center"
                className={styles.tableCellBody}
                width="150px"
              >
                {content[item]}
              </TableCell>
            );
          }
        })}
        <TableCell className={styles.tableCellBody}>
          <FileActions onDeleteSubmit={() => onDelete(content.id)} />
        </TableCell>
        <TableCell className={styles.tableCellBody} align="center">
          <IconButton
            onClick={onChange('is_collapsed')}
            className={styles.toggleIcon}
          >
            {state.is_collapsed ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableDropdown open={state.is_collapsed} details={selected} />
    </>
  );
};

export default connector(TableRownUpload);

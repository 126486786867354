import {useEffect, useReducer, useState} from 'react';

import FileActions from '../../FileActions';

import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';
import {connector} from 'ducks';
import cocaForm, {cocaFormInitState, cocaInitializer} from 'reducers/coca';
import {format} from 'date-fns';
import {putCOCA} from 'ducks/coca';

const Index = ({
  divider = false,
  cocaData,
  setCocaData,
  router,
  actionState,
  coca
}) => {
  const routerState = router?.location.state;
  const {purchase_order} = coca.data_selected;
  const [state, setState] = useReducer(cocaForm, cocaFormInitState);
  const [upload, setUpload] = useState(null);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };
  const onChange = (key) => (e) => {
    if (key === 'date_created') onSetState(key, (e, ''));
    else if (key === 'end_date') onSetState(key, format(e, 'yyyy-MM-dd'));
    else if (key === 'document') onSetState(key, e);
    else if (key === 'original_amount') onSetState(key, Number(e.target.value));
    else if (key === 'discount') onSetState(key, Number(e.target.value));
    else if (key === 'file') setUpload(e);
    else onSetState(key, e.target.value);
    onSetState('purchase_order', {
      id: purchase_order?.id,
      po_number: purchase_order?.po_number
    })
  };

  const stateListener = () => {
    setState(cocaInitializer(routerState)(state));
  };
  useEffect(stateListener, [routerState]);

  const handleDiscountAmount = () => {
    let discAmt = 0;
    discAmt = state.original_amount - (state.discount || 0);
    onSetState('discounted_amount', parseInt(discAmt));
  };
  useEffect(handleDiscountAmount, [state.original_amount, state.discount]);

  const onActionState = () => {
    actionState(putCOCA(state, upload, routerState.id));
  }
  useEffect(onActionState, [state, upload, routerState.id])

  return (
    <form className={styles.form}>
      {divider && <Divider sx={{marginBottom: '3rem', borderWidth: '2px'}} />}

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            For Purchase Order Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            value={purchase_order?.po_number}
            onChange={onChange('po_number')}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Status <span className="required">*</span>
          </Typography>
          <Select value={state.status} onChange={onChange('status')} fullWidth>
            <MenuItem value="5">Pending</MenuItem>
            <MenuItem value="6">Submitted to Client</MenuItem>
            <MenuItem value="7">Signed by Client</MenuItem>
            <MenuItem value="8">Invoiced</MenuItem>
            <MenuItem value="9">Paid</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            COCA Title <span className="required">*</span>
            <TextField
              variant="outlined"
              value={state.title}
              onChange={onChange('title')}
              fullWidth
            />
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography mb={2}>
            Date Created <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.start_date}
              disabled
              onChange={onChange('start_date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Last Day of COCA <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.end_date}
              onChange={onChange('end_date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Original Amount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            value={state.original_amount}
            onChange={onChange('original_amount')}
            className={styles.textWidth}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              value={state.document}
              className={styles.textWidth}
              fullWidth
            />
            <FileActions 
              handleFileDrive={onChange('document')} 
              docPath={onChange('file')}
              docFile={state.document}
              isEdit
            />
          </Stack>
          <FileTypes />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Discount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            value={state.discount}
            onChange={onChange('discount')}
            className={styles.textWidth}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Discounted Amount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            className={styles.textWidth}
            value={state.discounted_amount}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

import React from 'react';
import Checkbox from 'components/Checkbox';
import styles from './.module.css';

const List = ({
  multiselect,
  selectedList,
  style,
  data = [],
  onSelect = () => {}
}) => {
  const isChecked = (item) => {
    const isSelected = selectedList.find((select) => select.code === item.code);
    if (isSelected) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles['list']} style={style}>
      {data.map((item, index) => (
        <div key={index} className={styles['item-pane']}>
          {multiselect && (
            <div className={styles['checkbox-pane']}>
              <Checkbox
                isCheck={isChecked(item)}
                onToggle={() => onSelect(item)}
              />
            </div>
          )}
          <button className={styles['item']} onClick={() => onSelect(item)}>
            {item.value}
          </button>
        </div>
      ))}
    </div>
  );
};

export default List;

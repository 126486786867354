import {isType} from 'utils';

const initState = {
  search: '',
  status: {},
  search_by: {},
  selected_item: []
};

const moduleReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {search, status, search_by, selected_item} = action.payload;
      return {
        search: isType('string', search, state.search),
        search_by: isType('object', search_by, state.search_by),
        status: isType('object', status, state.status),
        selected_item: isType('array', selected_item, state.selected_item)
      };

    case 'SELECT-ITEM':
      const select_item_payload = action.payload;
      const is_exist = state.selected_item.find(
        (select_item) => select_item.id === select_item_payload.id
      );
      let upt_selected_item = [];
      if (is_exist) {
        upt_selected_item = state.selected_item.filter(
          (item) => item.id !== is_exist.id
        );
      } else {
        upt_selected_item = [...state.selected_item, select_item_payload];
      }
      return {...state, selected_item: upt_selected_item};
    default:
      return state;
  }
};

export {initState as moduleInitState};
export default moduleReducer;

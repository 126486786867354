import {isType} from 'utils';
import {SET_STATUS} from './types';
import {getAllStatus, getStatusCount, setStatus} from './actions';
import statusSaga from './saga';

const initState = {
  data: [],
  count: [],
  error: '',
  message: '',
  loading: false
};

const status = (state = initState, action) => {
  switch (action.type) {
    case SET_STATUS:
      const {data, count, error, message, loading} = action.payload;
      return {
        data: isType('array', data, state.data),
        count: isType('array', count, state.count),
        error: isType('string', error, state.error),
        message: isType('string', message, state.message),
        loading: isType('boolean', loading, state.loading)
      };

    default:
      return state;
  }
};

export {statusSaga, getAllStatus, getStatusCount, setStatus};
export default status;

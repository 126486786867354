export const category = {
  SAVE: '#4A78DF',
  NEXT: '#1E989A',
  DELETE: '#D03C31',
  BACKBLUE: '#4A78DF',
  CANCEL: '#414141',
  BACKBLACK: '#414141',
  LOGOUT: '#0E0D47',
  SUCCESS: '#169865',
  ERROR: '#E87E29'
};

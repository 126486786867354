import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setProposalResource} from '../actions';

function* getDetails() {
  try {
    const result = yield call(poServer.get, '/api/v1/proposal/details');
    yield put(setProposalResource({data: result}));
  } catch (error) {
    yield put(setProposalResource({error}));
  } finally {
    yield put(setProposalResource({loading: false}));
  }
}

export default getDetails;

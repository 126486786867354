const {isType} = require('utils');

const initState = {
  on_close: false
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {on_close} = action.payload;
      return {
        on_close: isType('boolean', on_close, state.on_close)
      };
    default:
      return state;
  }
};

export {initState};
export default reducer;

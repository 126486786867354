import {useEffect, useReducer, useState} from 'react';

import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import {useNavigate} from 'react-router-dom';

import {connector} from 'ducks';
import {
  getAllGoodReceipt,
  delGoodReceipt,
  searchGoodReceipt,
  getGoodReceiptDetail
} from 'ducks/good_receipt';

import DashboardContent from 'components/DashboardContent';
import DashboardLayout from 'components/DashboardLayout';
import FilterWithDropdown from 'components/Filter/FilterWithDropdown';
import DashboardTable from 'components/Table/DashboardTable';
import TableRownUpload from 'components/Table/TableRownUpload';
import TableFilterWrapper from 'components/TableFilterWrapper';

import styles from 'styles/Table.module.scss';
import {toPrettyData} from 'utils';
import {GR_COLUMNS, GR_FILTER_OPTION, ROW_OPTION} from 'constants/arrays';
import {filterRequest} from 'api';
import {moduleInitState, moduleReducer} from 'reducers';
import {push} from 'redux-first-history';

import {setErrorPurchaseOrder} from 'ducks/PO';
import {setErrorProposalResource} from 'ducks/proposal_resource';
import {setErrorCoca} from 'ducks/coca';
import {setErrorGoodReceipt} from 'ducks/good_receipt/actions';
import {setErrorInvoice} from 'ducks/invoice/actions';


const GoodReceipt = ({dispatch, goodReceipt}) => {
  const [state, setState] = useReducer(moduleReducer, moduleInitState);

  const onFilterRequest = (params) => {
    const _params = filterRequest({
      state,
      params,
      reducer: goodReceipt
    });
    dispatch(getAllGoodReceipt(_params));
  };

  const onClick = (ae) => (value) => {
    const item = state.selected_item.length ? state.selected_item[0] : [];
    if (ae === 'search') onFilterRequest({[ae]: value});
    if (ae === 'add') {
      dispatch(
        push('/dashboard/add', {
          module: 4,
          data_selected: item,
          type: 'ADD'
        })
      );
    }
    if (ae === 'edit') {
      dispatch(push('/dashboard/good-receipt/edit', {...item, type: 'EDIT'}));
    }
  };
  const onSelect = (item) => {
    setState({type: 'SELECT-ITEM', payload: item});
  };

  const onPaginate = (ae) => (event, page) => {
    if (ae === 'page') onFilterRequest({[ae]: page + 1});
    if (ae === 'size') {
      const size = event.target.value;
      onFilterRequest({size, page: 1});
    }
  };
  const initListener = () => {
    dispatch(getAllGoodReceipt({page: 1, size: 10}));
    dispatch(setErrorCoca(''));
    dispatch(setErrorGoodReceipt(''));
    dispatch(setErrorProposalResource(''));
    dispatch(setErrorPurchaseOrder(''));
    dispatch(setErrorInvoice(''));
  };
  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <DashboardContent title="Goods Receipt">
        <TableFilterWrapper>
          <FilterWithDropdown
            add
            selectItems={GR_FILTER_OPTION}
            title="good-receipt"
            state={state}
            setState={setState}
            onAdd={onClick('add')}
            onEdit={onClick('edit')}
            onSearch={onClick('search')}
          />
          <DashboardTable
            className={styles.tableWrapper}
            headTitles={GR_COLUMNS}
            rightSpace
          >
            <TableBody
              className={`${styles.tableBody} ${styles.overviewTable}`}
            >
              {goodReceipt.data.length > 0
                ? toPrettyData(goodReceipt.data, [
                    'gr_no',
                    'date_created',
                    'po_number',
                    'document',
                    'id',
                    // Fields hidden in table
                    'coca_id',
                    'coca_title',
                    'po_id'
                  ]).map((content, index) => {
                    return (
                      <TableRownUpload
                        key={index}
                        selected={goodReceipt.data_selected}
                        content={content}
                        path="/dashboard/good-receipt"
                        action={getGoodReceiptDetail}
                        onSelectItem={onSelect}
                      />
                    );
                  })
                : []}
            </TableBody>
          </DashboardTable>
        </TableFilterWrapper>
        <TablePagination
          className={styles.pagination}
          component="div"
          rowsPerPageOptions={ROW_OPTION}
          count={goodReceipt.total_data}
          rowsPerPage={goodReceipt.size}
          page={goodReceipt.page - 1}
          onPageChange={onPaginate('page')}
          onRowsPerPageChange={onPaginate('size')}
        />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default connector(GoodReceipt);

import {Login, NotFound} from 'pages';

const routes = [
  {
    index: true,
    element: <Login />
  }
];

export {routes as homeRoutes};

import CheckIcon from '@mui/icons-material/Check';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {Grid, Stack, Step, StepLabel, Stepper} from '@mui/material';

import styles from '../../styles/StepForm.module.scss';

const stepsTitle = [
  'Proposals',
  'Purchase Order',
  'Certificate of Completion Acceptance',
  'Goods Receipt',
  'Sales Invoice'
];

const Index = ({children, active, variant}) => {
  const classesDefault = (index) => {
    if (active > index) {
      return styles.completeStep;
    }
    if (active === index) {
      return styles.activeStep;
    }
    return styles.disabledStep;
  };

  const classesEdit = (index) => {
    if (active !== index) {
      return styles.completeStepEdit;
    }
    if (active === index) {
      return styles.activeStepEdit;
    }
    return styles.disabledStepEdit;
  };

  const getVariant = (item, index) => {
    if (variant === 'edit-form') {
      return (
        <Step
          className={classesEdit(index)}
          key={index}
          sx={{
            marginBottom: {sm: '.5rem', md: '1rem', xl: '1.75rem'}
          }}
        >
          <StepLabel
            StepIconComponent={
              active !== index ? CheckIcon : ModeEditOutlinedIcon
            }
          >
            {item}
          </StepLabel>
        </Step>
      );
    }

    return (
      <Step
        className={classesDefault(index)}
        key={index}
        sx={{
          marginBottom: {sm: '.5rem', md: '1rem', xl: '1.75rem'}
        }}
      >
        <StepLabel>{item}</StepLabel>
      </Step>
    );
  };

  return (
    <Stack className={styles.stepFormLayout} justifyContent="space-between">
      <Grid container justifyContent="space-between">
        <Grid item xs={2} className={styles.stepFormSteps}>
          <Stepper orientation="vertical" activeStep={active}>
            {stepsTitle.map((item, index) => {
              return getVariant(item, index);
            })}
          </Stepper>
        </Grid>
        <Grid item xs={10} className={styles.stepForms}>
          {children}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Index;

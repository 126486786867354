import {isType} from 'utils';

const initState = {
  title: '',
  original_amount: 0,
  discount: 0,
  discounted_amount: 0,
  start_date: '',
  end_date: '',
  document: '',
  purchase_order: {},
  status: '5',
  actions: null
};

const initializer = (payload) => (state) => {
  return {
    type: 'SET',
    payload: {
      ...state,
      ...payload
    }
  };
};

const cocaForm = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {
        title,
        original_amount,
        discount,
        discounted_amount,
        start_date,
        end_date,
        document,
        status,
        purchase_order,
        actions
      } = action.payload;
      return {
        title: isType('string', title, state.title),
        original_amount: isType(
          'number',
          original_amount,
          state.original_amount
        ),
        discount: isType('number', discount, state.discount),
        discounted_amount: isType(
          'number',
          discounted_amount,
          state.discounted_amount
        ),
        start_date: isType('string', start_date, state.start_date),
        end_date: isType('string', end_date, state.end_date),
        document: isType('string', document, state.document),
        purchase_order: isType('object', purchase_order, state.purchase_order),
        status: isType('string', status, state.status),
        actions: isType('object', actions, state.actions)
      };
    default:
      return state;
  }
};

export {initState as cocaFormInitState, initializer as cocaInitializer};

export default cocaForm;

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import DashboardLayout from '../../components/DashboardLayout';
import StepFormLayout from '../../components/StepFormLayout';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddFormButton from '../../components/AddFormButton';
import EditFormButton from '../../components/EditFormButton';
import CancelModal from '../../components/Modals/Cancel';

import MultiButton from '../../components/StepFormButtons/MultiButton';

import CocaEditForm from '../../components/Forms/CocaEditForm';
import EditGoodReceiptForm from '../../components/Forms/EditGoodReceiptForm';
import InvoiceEditForm from '../../components/Forms/InvoiceEditForm';
import POForm from '../../components/Forms/PoForm';
import PropposalForm from '../../components/Forms/ProposalsForm';

import CocaForm from '../../components/Forms/CocaForm';
import GRForm from '../../components/Forms/EditGoodReceiptForm';
import InvoicesForm from '../../components/Forms/InvoiceForm';

import {DASHBOARD} from '../../utils/const';
import {header} from '../../utils/stepform.const';

import {Box} from '@mui/material';
import styles from '../../styles/StepForm.module.scss';
import {connector} from 'ducks';
import EditGR from './EditGR';
import InvoiceForm from '../../components/Forms/InvoiceForm';

const OverviewEditForm = ({router}) => {
  const navigate = useNavigate();

  const [cancel, setCancel] = useState(false);
  const [active, setActive] = useState(0);
  const headerForm = header('EDIT', active);
  const titleBtn = header('', active);

  const EditForms = {
    2: <CocaForm />,
    3: <GRForm />,
    4: <InvoicesForm />
  };

  const forms = {
    0: <PropposalForm />,
    1: <POForm />,
    2: <CocaEditForm />,
    3: <GRForm />,
    4: <InvoiceForm />
  };

  const cancelForm = () => {
    navigate(`/${DASHBOARD}`);
  };

  const handleBack = () => {
    if (active !== 0) setActive(active - 1);
  };

  const handleNext = () => {
    if (active !== 4) setActive(active + 1);
  };

  return (
    <DashboardLayout>
      <CancelModal
        open={cancel}
        onCancel={cancelForm}
        onClose={() => setCancel(false)}
      />

      <StepFormLayout active={active}>
        <Stack
          direction="column"
          justifyContent="space-between"
          className={styles.formWrapper}
          mb={5}
        >
          <div className={styles.titleSpacing}>
            <Typography variant="h3">{headerForm?.header}</Typography>
          </div>
          <Stack direction="row" justifyContent="space-between" mt={3}>
            <Typography variant="h6">{headerForm?.details}</Typography>
            {active > 1 && (
              <Stack spacing={2} direction="row">
                <AddFormButton
                  title={titleBtn?.header}
                  toastSucess={titleBtn?.header}
                >
                  {forms[active]}
                </AddFormButton>
                <EditFormButton title={titleBtn?.header}>
                  {EditForms[active]}
                </EditFormButton>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            height: '65%',
            overflowY: 'auto',
            paddingRight: '2rem',
            //future use
            // pointerEvents: 'none',
            '&::-webkit-scrollbar': {
              width: '4px',
              background: '#f0f0f0',
              color: 'black',
              borderRadius: '20rem'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '20rem',
              background: 'gray'
            }
          }}
        >
          {forms[active]}
        </Box>
        <MultiButton
          onCancel={() => setCancel(true)}
          onBack={handleBack}
          onNext={handleNext}
          step={active}
        />
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(OverviewEditForm);

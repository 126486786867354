import {Typography} from '@mui/material';
import React from 'react';

const FileTypes = () => {
  return (
    <Typography fontSize={{lg: '0.7rem !important'}}>
      ACCEPTED FILE TYPES: PDF, DOCX, DRIVE, LINK; MAX FILE SIZE: 10MB
    </Typography>
  );
};

export default FileTypes;

import {GET_ALL_STATUS, GET_STATUS_COUNT, SET_STATUS} from './types';

const getAllStatus = ({module, params}) => ({
  type: GET_ALL_STATUS,
  module,
  params
});

const getStatusCount = () => ({
  type: GET_STATUS_COUNT
});

const setStatus = ({data, count, error, message, loading}) => ({
  type: SET_STATUS,
  payload: {data, count, error, message, loading}
});

export {getAllStatus, getStatusCount, setStatus};

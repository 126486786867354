import {GET_DOCUMENT, SET_ERROR_DOWNLOAD, SET_DOCUMENT} from './types';

const getDocument = (document) => ({
    type: GET_DOCUMENT,
    document
});

const setErrorDownload = (payload) => ({
    type: SET_ERROR_DOWNLOAD,
    payload
});

const setDocument = ({data, error, message, loading}) => ({
    type: SET_DOCUMENT,
    payload: {data, error, message, loading}
})

export {getDocument, setErrorDownload, setDocument};
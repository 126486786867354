import {isType} from 'utils';

import {AUTH_LOGIN, AUTH_LOGOUT, SET_AUTH} from './types';
import {authLogin, authLogout, setAuth} from './actions';
import authSaga from './saga';

const initState = {
  data: {
    authorized: false,
    role: ''
  },
  error: '',
  loading: false
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return initState;

    case AUTH_LOGIN:
      return {
        ...state,
        loading: true
      };

    case SET_AUTH:
      const {authorized, error, loading} = action.payload;
      const data = {authorized};
      return {
        data: isType('object', data, state.data),
        error: isType('string', error, state.error),
        loading: isType('boolean', loading, state.loading)
      };

    default:
      return state;
  }
};

export {authLogin, authLogout, setAuth, authSaga};
export default auth;

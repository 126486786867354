import {useCallback, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardLayout from '../../components/DashboardLayout';
import StepFormLayout from '../../components/StepFormLayout';

import CancelModal from '../../components/Modals/Cancel';
import SaveButton from '../../components/StepFormButtons/SaveButton';

import {DASHBOARD} from '../../utils/const';

import {Box} from '@mui/material';
import styles from '../../styles/Form.module.scss';
import {connector} from 'ducks';
import {getOneGoodReceipt} from 'ducks/good_receipt/actions';
import PoForm from 'components/Forms/PoForm';
import poForm, { poFormInitState } from 'reducers/po';
import { putPurchaseOrder } from 'ducks/PO';

const EditPO = ({dispatch, router}) => {
  const navigate = useNavigate();
  const {location} = router;
  const [state, setState] = useReducer(poForm, poFormInitState);
  const [cancel, setCancel] = useState(false);
  const [payload, setPayload] = useState(null);

  const actionState = (action) => {
    setState({type: 'SET', payload: {actions: action}});
  };

  const onSubmit = () => {
    dispatch(payload);
  };

  const initListener = () => {
    dispatch(getOneGoodReceipt({id: location.state.id}));
  };

  useEffect(initListener, []);
  const handleCancelForm = useCallback(() => {
    navigate(`/${DASHBOARD}`);
  }, []);

  return (
    <DashboardLayout>
      <CancelModal
        open={cancel}
        onClose={() => setCancel(false)}
        onCancel={handleCancelForm}
      />
      <StepFormLayout variant="edit-form" active={1}>
        <div className={styles.titleSpacing}>
          <Typography variant="h4">EDIT PURCHASE ORDER</Typography>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">PURCHASE ORDER DETAILS</Typography>
        </Stack>
        <Box
        // for future use
        // sx={{
        //   height: '65%',
        //   overflowY: 'auto',
        //   paddingRight: '2rem',
        //   pointerEvents: 'none',
        //   '&::-webkit-scrollbar': {
        //     width: '4px',
        //     background: '#f0f0f0',
        //     color: 'black',
        //     borderRadius: '20rem'
        //   },
        //   '&::-webkit-scrollbar-thumb': {
        //     borderRadius: '20rem',
        //     background: 'gray'
        //   }
        // }}
        >
          <PoForm 
            data={location.state} 
            stateChange={setPayload} />
        </Box>
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignContent="center"
          sx={{
            marginTop: '5rem'
          }}>
          <SaveButton
            title="Save"
            absolute
            onClose={() => setCancel(true)}
            onSave={onSubmit}
          />
        </Stack>
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(EditPO);

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from '../../Button';
import ModalLayout from '../ModalLayout';

import styles from '../../../styles/ModalLayout.module.scss';

const index = ({open, onClose, onSave}) => {
  return (
    <ModalLayout open={open} type="update">
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={styles.logoutModal}
      >
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h5"> Update File? </Typography>
          <Typography variant="p" sx={{width: '25rem !important'}}>
            Selected file will be updated
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2.5}
          justifyContent="center"
          sx={{width: '100%'}}
        >
          <Button
            buttonType="CANCEL"
            variant="outlined"
            label="cancel"
            small
            onClick={onClose}
          />
          <Button
            buttonType="update"
            variant="contained"
            label="UPDATE"
            onClick={onSave}
            small
          />
        </Stack>
      </Stack>
    </ModalLayout>
  );
};

export default index;

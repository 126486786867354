import {poServer} from 'api';
import {call} from 'redux-saga/effects';

function* postDoc({type, payload}) {
  try {
    const result = yield call(
      poServer.post,
      '/api/v1/goods-receipt/document',
      payload
    );
  } catch (error) {
  } finally {
  }
}

export default postDoc;

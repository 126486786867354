import {useCallback, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardLayout from '../../components/DashboardLayout';
import StepFormLayout from '../../components/StepFormLayout';

import AddNewModalGRForm from '../../components/Forms/AddNewModalGRForm';
import EditGoodReceiptForm from '../../components/Forms/EditGoodReceiptForm';
import GRForm from '../../components/Forms/GREditForm';

import AddFormButton from '../../components/AddFormButton';
import EditFormButton from '../../components/EditFormButton';

import CancelModal from '../../components/Modals/Cancel';
import SaveButton from '../../components/StepFormButtons/SaveButton';

import {DASHBOARD, GR} from '../../utils/const';

import {Box} from '@mui/material';
import styles from '../../styles/Form.module.scss';
import {connector} from 'ducks';
import grForm, {grFormInitState} from 'reducers/gr';
import {getAllGoodReceipt} from 'ducks/good_receipt';
import {getOneGoodReceipt} from 'ducks/good_receipt/actions';

const EditGR = ({dispatch, router, goodReceipt}) => {
  const navigate = useNavigate();
  const {location} = router;

  const [state, setState] = useReducer(grForm, grFormInitState);
  const [cancel, setCancel] = useState(false);

  const actionState = (action) => {
    setState({type: 'SET', payload: {actions: action}});
  };

  const onSubmit = () => {
    dispatch(state.actions);
    dispatch(getAllGoodReceipt({page: 1, size: 10}));
  };

  const initListener = () => {
    dispatch(getOneGoodReceipt({id: location.state.id}));
  };

  useEffect(initListener, []);
  const handleCancelForm = useCallback(() => {
    navigate(`/${DASHBOARD}/${GR}`);
  }, []);

  return (
    <DashboardLayout>
      <CancelModal
        open={cancel}
        onClose={() => setCancel(false)}
        onCancel={handleCancelForm}
      />
      <StepFormLayout variant="edit-form" active={3}>
        <div className={styles.titleSpacing}>
          <Typography variant="h4">EDIT GR</Typography>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">GR DETAILS</Typography>
          <Stack spacing={2} direction="column">
            <AddFormButton title="good receipt" toastSucess="Good Receipt">
              <AddNewModalGRForm />
            </AddFormButton>
            {/* <EditFormButton title="Goods Receipt">
              <EditGoodReceiptForm />
            </EditFormButton> */}
          </Stack>
        </Stack>
        <Box
        // for future use
        // sx={{
        //   height: '65%',
        //   overflowY: 'auto',
        //   paddingRight: '2rem',
        //   pointerEvents: 'none',
        //   '&::-webkit-scrollbar': {
        //     width: '4px',
        //     background: '#f0f0f0',
        //     color: 'black',
        //     borderRadius: '20rem'
        //   },
        //   '&::-webkit-scrollbar-thumb': {
        //     borderRadius: '20rem',
        //     background: 'gray'
        //   }
        // }}
        >
          <GRForm router={location} actionState={actionState} />
        </Box>
        <Stack justifyContent="space-between" alignContent="center">
          <SaveButton
            title="Save"
            absolute
            onClose={() => setCancel(true)}
            onSave={onSubmit}
          />
        </Stack>
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(EditGR);

import React, {useState} from 'react';
import {getDate} from './utils';

import styles from './.module.css';
import {isType} from 'utils';

const DatePicker = ({
  containerClassName,
  controllerClassName,
  disabled,
  readOnly,
  value,
  minimum,
  maximum,
  onChange = () => {}
}) => {
  const [state, setState] = useState(getDate(value));

  const onChangeDate = (value) => {
    setState(value);
    onChange(value);
  };

  return (
    <div className={`${styles['date-picker']} ${containerClassName}`}>
      <input
        type="date"
        value={isType('string', value, state)}
        min={getDate(minimum)}
        max={getDate(maximum)}
        className={`${styles['date']} ${controllerClassName}`}
        onChange={({target: {value}}) => onChangeDate(value)}
        readOnly={readOnly}
        disabled={disabled}
      />
    </div>
  );
};

export default DatePicker;

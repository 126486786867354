import React, {useEffect, useReducer, useState} from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Stack
} from '@mui/material';
import {connector} from 'ducks';
import {getImage} from 'utils/helper';
import {loginReducer, loginInitState} from 'reducers';

import styles from '../styles/Login.module.scss';
import {authLogin} from 'ducks/auth';
import {VERSION} from 'constants/env';

import moduleStyle from './.module.css';

const Login = ({dispatch, auth}) => {
  const [state, setState] = useReducer(loginReducer, loginInitState);
  /**
   * Set the key and value of login State
   * @param {string} key
   * @param {boolean | string | number } val
   */
  const handleSetState = (key, val) => {
    setState({
      type: 'SET',
      payload: {
        [key]: val
      }
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(authLogin({username: state.username, password: state.password}));
  };

  const fetch = () => {};
  const initListener = () => {};
  useEffect(initListener, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} className={styles.container}>
          <Stack
            direction="column"
            className={styles.box}
            justifyContent="center"
            alignItems="center"
          >
            <img
              className={styles.logo}
              src={getImage('/xurpas-logo.png')}
              alt="logo"
            />

            <Typography className={styles.LoginTypography}>Login</Typography>
            <TextField
              error={auth.error}
              className={styles.userNameTextfield}
              placeholder="Username"
              type="text"
              name="username"
              value={state.username}
              onChange={(e) => handleSetState('username', e.target.value)}
            />

            <div className={styles.passwordWrapper}>
              <TextField
                error={auth.error}
                className={styles.passwordTextField}
                placeholder="Password"
                type={state.showPassword ? 'text' : 'password'}
                value={state.password}
                name="password"
                onChange={(e) => handleSetState('password', e.target.value)}
              />

              <IconButton
                className={styles.iconBtn}
                onClick={() =>
                  handleSetState('showPassword', !state.showPassword)
                }
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>

              {auth.error && (
                <span className={styles.errorMsg}>
                  Incorrent email or password
                </span>
              )}
            </div>

            <Button
              className={styles.BtnLogin}
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </Stack>
          <p className={moduleStyle['version']}>{VERSION}</p>
        </form>
      </Grid>
    </Grid>
  );
};

export default connector(Login);

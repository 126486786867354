import {takeLatest} from 'redux-saga/effects';

import {
  GET_COCA,
  GET_ALL_COCA,
  GET_ID_DETAIL_COCA,
  GET_DETAILS_COCA,
  POST_COCA,
  PUT_COCA,
  DELETE_COCA,
  SEARCH_COCA
} from '../types';
import get from './get';
import getAll from './getAll';
import getIDDetail from './getIDDetail';
import getDetails from './getDetails';
import post from './post';
import edit from './put';
import del from './delete';
import search from './search';

function* COCASaga() {
  yield takeLatest(GET_COCA, get);
  yield takeLatest(GET_ALL_COCA, getAll);
  yield takeLatest(GET_ID_DETAIL_COCA, getIDDetail);
  yield takeLatest(GET_DETAILS_COCA, getDetails);
  yield takeLatest(SEARCH_COCA, search);
  yield takeLatest(POST_COCA, post);
  yield takeLatest(PUT_COCA, edit);
  yield takeLatest(DELETE_COCA, del);
}

export default COCASaga;

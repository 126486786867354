const PO_COLUMNS = [
  'Proposal ID',
  'PO#',
  'Name',
  'Original Amount',
  'Date',
  'Status',
  'Action'
];

const PROPOSAL_COLUMNS = [
  'Proposal ID',
  'Name',
  'Date',
  'File Uploaded',
  'File Actions'
];

const COCA_COLUMNS = [
  'COCA Title',
  'Date Created',
  'Original Amount',
  'Discounted Amount',
  'Last Date of COCA',
  'Status',
  'File Uploaded'
];

const GR_COLUMNS = [
  'GR#',
  'Date',
  'For PO Number',
  'File Uploaded',
  'File Actions'
];

const INVOICE_COLUMNS = [
  'Invoice #',
  'Date',
  'For PO Number',
  'Amount',
  'Status',
  'File Uploaded',
  'File Actions'
];

const ROW_OPTION = [10, 15, 20];

const PROPOSAL_FILTER_OPTION = [
  {label: 'by Proposal ID', value: 'PROPOSAL_ID'}
];

const INVOICE_FILTER_OPTION = [
  {label: 'by Invoices', value: 'INVOICE_NO'},
  {label: 'by Purchase Order#', value: 'PURCHASE_ORDER_NO'}
];
const GR_FILTER_OPTION = [
  {label: 'by Good Receipt ID', value: 'GR_NO'},
  {label: 'by Purchase Order#', value: 'PURCHASE_ORDER_NO'}
];
const COCA_FILTER_OPTION = [{label: 'by COCA TITLE', value: 'TITLE'}];
export {
  PO_COLUMNS,
  ROW_OPTION,
  PROPOSAL_COLUMNS,
  COCA_COLUMNS,
  GR_COLUMNS,
  INVOICE_COLUMNS,
  INVOICE_FILTER_OPTION,
  GR_FILTER_OPTION,
  COCA_FILTER_OPTION,
  PROPOSAL_FILTER_OPTION
};

import {useState, useEffect, useReducer} from 'react';

import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import {useNavigate} from 'react-router-dom';

import DashboardContent from '../../components/DashboardContent';
import DashboardLayout from '../../components/DashboardLayout';
import FilterWithDropdown from '../../components/Filter/FilterWithDropdown';
import DashboardTable from '../../components/Table/DashboardTable';
import TableRownUpload from '../../components/Table/TableRownUpload';
import TableFilterWrapper from '../../components/TableFilterWrapper';

import {connector} from 'ducks';
import {getAllInvoice} from 'ducks/invoice';
import styles from '../../styles/Table.module.scss';
import {toPrettyData} from 'utils';
import {
  INVOICE_COLUMNS,
  INVOICE_FILTER_OPTION,
  ROW_OPTION
} from 'constants/arrays';
import {getInvoiceDetails} from 'ducks/invoice/actions';
import {filterRequest} from 'api';
import {moduleInitState, moduleReducer} from 'reducers';
import {push} from 'redux-first-history';

import {setErrorPurchaseOrder} from 'ducks/PO';
import {setErrorProposalResource} from 'ducks/proposal_resource';
import {setErrorCoca} from 'ducks/coca';
import {setErrorGoodReceipt} from 'ducks/good_receipt/actions';
import {setErrorInvoice} from 'ducks/invoice/actions';

const Invoices = ({dispatch, invoice}) => {
  const [state, setState] = useReducer(moduleReducer, moduleInitState);

  const onSetState = (key, val) => {
    setState({type: 'SET', [key]: val});
  };
  const onFilterRequest = (params) => {
    const _params = filterRequest({
      state,
      params,
      reducer: invoice
    });
    dispatch(getAllInvoice(_params));
  };
  const onClick = (ae) => (value) => {
    if (ae === 'search') onFilterRequest({[ae]: value});
    if (ae === 'edit') {
      const item = state.selected_item.length ? state.selected_item[0] : [];
      dispatch(push('/dashboard/invoices/edit', {...item, type: 'EDIT'}));
    }
  };
  const onSelect = (item) => {
    setState({type: 'SELECT-ITEM', payload: item});
  };
  const onPaginate = (ae) => (event, page) => {
    if (ae === 'page') onFilterRequest({[ae]: page + 1});
    if (ae === 'size') {
      const size = event.target.value;
      onFilterRequest({size, page: 1});
    }
  };
  const initListener = () => {
    dispatch(getAllInvoice({page: 1, size: 10}));
    dispatch(setErrorCoca(''));
    dispatch(setErrorGoodReceipt(''));
    dispatch(setErrorProposalResource(''));
    dispatch(setErrorPurchaseOrder(''));
    dispatch(setErrorInvoice(''));
  };
  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <DashboardContent title="Invoices">
        <TableFilterWrapper>
          <FilterWithDropdown
            selectItems={INVOICE_FILTER_OPTION}
            title="invoices"
            state={state}
            setState={setState}
            onEdit={onClick('edit')}
            onSearch={onClick('search')}
          />
          <DashboardTable
            className={styles.tableWrapper}
            headTitles={INVOICE_COLUMNS}
            rightSpace
          >
            <TableBody
              className={`${styles.tableBody} ${styles.overviewTable}`}
            >
              {invoice.data.length > 0
                ? toPrettyData(invoice.data, [
                    'id',
                    'invoice_no',
                    'date',
                    'po_number',
                    'amount',
                    'status',
                    'document'
                  ]).map((content, index) => {
                    return (
                      <TableRownUpload
                        key={index}
                        selected={invoice.data_selected}
                        content={content}
                        path="/dashboard/good-receipt"
                        action={getInvoiceDetails}
                        onSelectItem={onSelect}
                      />
                    );
                  })
                : []}
            </TableBody>
          </DashboardTable>
        </TableFilterWrapper>
        <TablePagination
          className={styles.pagination}
          component="div"
          rowsPerPageOptions={ROW_OPTION}
          count={invoice.total_data}
          rowsPerPage={invoice.size}
          page={invoice.page - 1}
          onPageChange={onPaginate('page')}
          onRowsPerPageChange={onPaginate('size')}
        />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default connector(Invoices);

import {useEffect, useReducer, useState} from 'react';
import {connector} from 'ducks';
import TableBody from '@mui/material/TableBody';
import {Stack} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import {getAllPurchaseOrder} from 'ducks/PO';
import {formatDropdownObj, toPrettyData} from 'utils';
import {PO_COLUMNS, ROW_OPTION} from 'constants/arrays';
import {getStatus} from 'utils/helper';
import {filterRequest} from 'api';
import {overviewReducer, overviewInitState} from 'reducers';

import TableBodyAccordion from '../../components/Table/TableBodyAccordion';
import UpperCard from '../../components/UpperCards';
import styles from '../../styles/Table.module.scss';

import DashboardContent from '../../components/DashboardContent';
import DashboardLayout from '../../components/DashboardLayout';
import FilterOverview from '../../components/Filter/FilterOverview';

import DashboardTable from '../../components/Table/DashboardTable';
import TableFilterWrapper from '../../components/TableFilterWrapper';
import {getPurchaseOrderDetails} from 'ducks/PO/actions';

const Overview = ({purchaseOrder, status, dispatch}) => {
  const [state, setState] = useReducer(overviewReducer, overviewInitState);
  const [selectItem, setSelectItem] = useState(false);
  const [moduleForm, setModuleForm] = useState(0);
  const [data, setData] = useState(null);

  const onSetState = (key, val) => {
    setState({type: 'SET', payload: {[key]: val}});
  };
  const onFilterRequest = (params) => {
    const _params = filterRequest({
      state,
      params,
      reducer: purchaseOrder
    });
    dispatch(getAllPurchaseOrder(_params));
  };
  const onChange = (ae) => (value) => {
    if (ae === 'date_start') onFilterRequest({dateStart: value});
    if (ae === 'date_end') onFilterRequest({dateEnd: value});
    if (ae === 'status') onFilterRequest({[ae]: value.id});
    if (ae === 'search' && value.length <= 0) onFilterRequest({search: ''});
    onSetState(ae, value);
  };
  const onClick = (ae) => (value) => {
    if (ae === 'search') onFilterRequest({[ae]: value});
  };
  const onStatusChange = (status_str) => () => {
    const _status = getStatus(status.data, status_str);
    if (_status.id === state.status.id) {
      onSetState('status', {id: '', value: 'All'});
      onFilterRequest({status: ''});
      return;
    }
    const _status_obj = formatDropdownObj(_status, 'name');
    onSetState('status', _status_obj);
    onFilterRequest({status: _status.id});
  };

  const onPaginate = (ae) => (event, page) => {
    if (ae === 'page') onFilterRequest({[ae]: page + 1});
    if (ae === 'size') {
      const size = event.target.value;
      onFilterRequest({[ae]: size, page: 1});
    }
  };

  const getFullPurchaseOrderDetails = (id) => {
    dispatch(getPurchaseOrderDetails(id));
  };
  const onShowAccordion = ({is_collapsed}, id) => {
    if (is_collapsed) getFullPurchaseOrderDetails(id);
  };
  const onSelect = (item) => {
    const selectData = {
      id: item.id,
      proposal: {
        id: item.proposal_id
      },
      name: item.name,
      po_number: item.po_number,
      original_amount: item.original_amount,
      discount: item.discount,
      discounted_amount: item.discounted_amount,
      status: item.status.id,
      start_date: item.start_date,
      end_date: item.end_date,
      document: item.document
    };

    if (item) {
      setState({
        type: 'SET',
        payload: {
          seleced_items: item
        }
      });
      setSelectItem(true);
      setModuleForm(2);
      setData(selectData);
    } else {
      setModuleForm(0);
      setSelectItem(false);
    }
  };

  const handleStatusCount = () => {
    if (status.count.length) {
      let statCount = {
        pending: status.count[0].cnt,
        ongoing: status.count[1].cnt,
        rendered: status.count[2].cnt,
        paid: status.count[3].cnt
      };
      onSetState('status_count', statCount);
    }
  };
  useEffect(handleStatusCount, [status.count]);

  const initListener = () => {
    dispatch(getAllPurchaseOrder({page: 1, size: 10}));
  };
  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <DashboardContent title="Purchase Order Status">
        <Stack direction={'row'} justifyContent="space-evenly">
          <UpperCard
            title="Pending"
            data={state.status_count.pending || 0}
            onClick={onStatusChange('pending')}
          />
          <UpperCard
            title="Ongoing"
            data={state.status_count.ongoing || 0}
            onClick={onStatusChange('ongoing')}
          />
          <UpperCard
            title="Rendered"
            data={state.status_count.rendered || 0}
            onClick={onStatusChange('rendered')}
          />
          <UpperCard
            title="Paid"
            data={state.status_count.paid || 0}
            onClick={onStatusChange('paid')}
          />
        </Stack>
        <TableFilterWrapper>
          <FilterOverview
            items={state.seleced_items}
            state={state}
            onChange={onChange}
            onSearch={onClick('search')}
            onAdd={selectItem}
            moduleForm={moduleForm}
            dataSelected={data}
          />
          <DashboardTable
            rightSpace
            className={styles.tableWrapperOverview}
            headTitles={PO_COLUMNS}
          >
            <TableBody className={styles.tableBody}>
              {purchaseOrder.data.length > 0
                ? toPrettyData(purchaseOrder.data, [
                    'proposal_id',
                    'po_number',
                    'name',
                    'original_amount',
                    'start_date',
                    'status',
                    // Fields hidden in table
                    'id',
                    'discount',
                    'discounted_amount',
                    'document',
                    'end_date'
                  ]).map((content, index) => (
                    <TableBodyAccordion
                      key={index}
                      selected={purchaseOrder.data_selected}
                      content={content}
                      status={status.data}
                      onShowAccordion={onShowAccordion}
                      onSelectItem={onSelect}
                    />
                  ))
                : null}
            </TableBody>
          </DashboardTable>
        </TableFilterWrapper>
        <TablePagination
          className={styles.pagination}
          component="div"
          rowsPerPageOptions={ROW_OPTION}
          onPageChange={onPaginate('page')}
          onRowsPerPageChange={onPaginate('size')}
          count={purchaseOrder.total_data}
          rowsPerPage={purchaseOrder.size}
          page={purchaseOrder.page - 1}
        />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default connector(Overview);

import {useReducer, useState, useRef} from 'react';

import IconButton from '@mui/material/IconButton';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Stack from '@mui/material/Stack';

import {ROLE} from 'constants/strings';

import DeleteModal from '../Modals/Delete';
import AddLink from '../Modals/AddLink';
import reducer, {initState} from './reducer';

const FileActions = ({handleFileDrive, isEdit, isAdd, docFile, docPath, onDeleteSubmit = () => {}}) => {
  const [addModal, setAddModal] = useState(false);

  const [state, setState] = useReducer(reducer, initState);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };

  const iconSize = {
    fontSize: {md: '1.2rem', xl: 'auto'}
  };

  const handleUpload = () => setAddModal(true);
  //handles the uploadfile from local button
  const inputRef = useRef(null);
  const handleClick = () => {
    //open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    } else {
      const name = event.target.files[0].name;
      const path = new FormData();
      if (isAdd) path.append('file', event.target.files[0]);
      else if (isEdit) {
        path.append('old-file', docFile);
        path.append('new-file', event.target.files[0])
      }
      handleFileDrive(name);
      docPath(path);
    }
  };

  const onDelete = () => {
    onSetState('on_close', !state.on_close);
    onDeleteSubmit();
  };

  const onClick = (ae) => () => {
    if (ae === 'open-modal' || ae === 'close-modal')
      onSetState('on_close', !state.on_close);
    if (ae === 'delete') onDelete();
  };

  //up to here
  return (
    <>
      <DeleteModal
        open={state.on_close}
        onClose={onClick('close-modal')}
        onDelete={onClick('delete')}
        title="Delete File ?"
        content="Selected file will be deleted"
      />

      <AddLink
        open={addModal}
        handleFile={handleFileDrive}
        onClose={() => setAddModal(false)}
      />

      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {ROLE.SUPERADMIN === 'super-admin' && (
          <IconButton>
            <DeleteOutlineIcon
              onClick={onClick('open-modal')}
              className="delete-btn"
              sx={iconSize}
            />
          </IconButton>
        )}
        <IconButton onClick={handleClick}>
          <input
            style={{display: 'none'}}
            name="file"
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            accept=".pdf,.docx"
          />
          <UploadFileIcon id="upload-btn" sx={iconSize} />
        </IconButton>
        <IconButton onClick={handleUpload}>
          <AddToDriveIcon id="gdrive-btn" sx={iconSize} />
        </IconButton>
      </Stack>
    </>
  );
};

export default FileActions;

import {HistoryRouter as Router} from 'redux-first-history/rr6';
import {Route, Routes} from 'react-router-dom';

import {SUBPATH} from 'constants/strings';
import {NotFound} from 'pages';

const PrivateRoute = ({
  basepath,
  history,
  navigator: Navigator,
  routes = []
}) => {
  return (
    <Router history={history} basename={SUBPATH}>
      <Routes>
        <Route path={basepath} element={<Navigator />}>
          {routes.map((page, index) => (
            <Route key={index} {...page} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default PrivateRoute;

import {
  GET_COCA,
  GET_ALL_COCA,
  GET_ID_DETAIL_COCA,
  GET_DETAILS_COCA,
  POST_COCA,
  POST_DOCUMENT_COCA,
  PUT_COCA,
  PUT_DOCUMENT_COCA,
  DELETE_COCA,
  DELETE_DOCUMENT_COCA,
  SET_COCA,
  SEARCH_COCA,
  SET_ERROR_COCA
} from './types';

// GET
const getCOCA = (payload) => ({
  type: GET_COCA,
  payload
});
const getAllCOCA = (params) => ({
  type: GET_ALL_COCA,
  params
});
const getIDDetailCOCA = (payload) => ({
  type: GET_ID_DETAIL_COCA,
  payload
});
const getDetailsCOCA = () => ({
  type: GET_DETAILS_COCA
});
const searchCoca = (payload) => ({
  type: SEARCH_COCA,
  payload
});

// POST
const postCOCA = (payload, document) => ({
  type: POST_COCA,
  payload,
  document
});
const postDocumentCOCA = (payload) => ({
  type: POST_DOCUMENT_COCA,
  payload
});

// PUT
const putCOCA = (payload, document, params) => ({
  type: PUT_COCA,
  payload,
  document,
  params
});
const putDocumentCOCA = (payload, params) => ({
  type: PUT_DOCUMENT_COCA,
  payload,
  params
});

// DELETE
const deleteCOCA = (payload) => ({
  type: DELETE_COCA,
  payload
});
const deleteDocumentCOCA = (payload) => ({
  type: DELETE_DOCUMENT_COCA,
  payload
});

const setErrorCoca = (payload) => ({
  type: SET_ERROR_COCA,
  payload
})

// STATE ACTIONS
const setCOCA = (payload) => ({
  type: SET_COCA,
  payload
});

export {
  getCOCA,
  getAllCOCA,
  getIDDetailCOCA,
  getDetailsCOCA,
  postCOCA,
  postDocumentCOCA,
  putCOCA,
  putDocumentCOCA,
  deleteCOCA,
  deleteDocumentCOCA,
  setCOCA,
  searchCoca,
  setErrorCoca,
};

import {call, put} from "redux-saga/effects";

function* get({type, payload}) {
  try{

  } catch(error) {

  } finally {

  }
}

export default get;
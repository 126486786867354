import {call, put} from 'redux-saga/effects';

import {poServer} from 'api';
import storage from 'utils/storage';
import {setAuth} from '../actions';
import {push} from 'redux-first-history';

function* login({type, payload}) {
  try {
    const res = yield call(poServer.post, '/auth/login', payload);
    const {
      result: {token}
    } = res;
    yield put(setAuth({authorized: true}));
    yield put(push('/dashboard'));
    yield call(storage.set, 'at', token);
  } catch (error) {
    yield put(setAuth({error}));
  } finally {
    yield put(setAuth({loading: false}));
  }
}

export default login;

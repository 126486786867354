import axios from 'axios';
import poServer from './services';

import {PO_API_URL} from 'constants/env';

const poServerConfig = axios.create({
  baseURL: `${PO_API_URL}`
});

export {poServer as default, poServerConfig};

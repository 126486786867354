import {
  DEL_PURCHASE_ORDER,
  GET_ALL_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_DETAILS,
  POST_PURCHASE_ORDER,
  PUT_PURCHASE_ORDER,
  SET_ERROR_PURCHASE_ORDER,
  SET_PURCHASE_ORDER
} from './types';

//EXAMPLE
const postPurchaseOrder = (payload, document) => ({
  type: POST_PURCHASE_ORDER,
  payload,
  document
});

const putPurchaseOrder = (payload, document, params) => ({
  type: PUT_PURCHASE_ORDER,
  params,
  payload,
  document
});

const delPurchaseOrder = (payload) => ({
  type: DEL_PURCHASE_ORDER,
  payload
});

const getAllPurchaseOrder = (params) => ({
  type: GET_ALL_PURCHASE_ORDER,
  params
});

const getPurchaseOrderDetails = (params) => ({
  type: GET_PURCHASE_ORDER_DETAILS,
  params
});

const setErrorPurchaseOrder = (payload) => ({
  type: SET_ERROR_PURCHASE_ORDER,
  payload
})

const setPurchaseOrder = ({
  data,
  size,
  page,
  total_data,
  total_page,
  data_selected
}) => ({
  type: SET_PURCHASE_ORDER,
  payload: {data, size, page, total_data, total_page, data_selected}
});

export {
  postPurchaseOrder,
  delPurchaseOrder,
  putPurchaseOrder,
  getAllPurchaseOrder,
  setPurchaseOrder,
  getPurchaseOrderDetails,
  setErrorPurchaseOrder
};

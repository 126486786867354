const {isType} = require('utils');

const initState = {
  proposal_no: '',
  name: '',
  status: 14,
  date: '',
  document: '',
  actions: null
};

const initializer = (payload) => (state) => {
  return {
    type: 'SET',
    payload: {
      ...state,
      ...payload
    }
  };
};

const proposalForm = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {proposal_no, name, status, date, document, actions} =
        action.payload;
      return {
        proposal_no: isType('string', proposal_no, state.proposal_no),
        name: isType('string', name, state.name),
        status: isType('number', status, state.status),
        date: isType('string', date, state.date),
        document: isType('string', document, state.document),
        actions: isType('object', actions, state.actions)
      };
    default:
      return state;
  }
};

export {
  initState as proposalInitState,
  initializer as proposalResourceInitializer
};

export default proposalForm;

import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import DropdownDetails from './DropdownDetails';
import DropdownInfo from './DropdownInfo';
import {createRequests} from 'utils';

const sampleRequest = [
  {
    title: 'Project CoCas',
    data: [
      {
        info: 'Sample 1',
        status: 'pending'
      },
      {
        info: 'Sample 2',
        status: 'paid'
      },

      {
        info: 'Sample 3',
        status: 'ongoing'
      }
    ]
  },

  {
    title: 'Issued GRs',
    data: [
      {
        info: 'Sample 1'
      },
      {
        info: 'Sample 2'
      },

      {
        info: 'Sample 3'
      }
    ]
  },

  {
    title: 'Invoices',
    data: [
      {
        info: 'Sample 1',
        status: 'rendered'
      },
      {
        info: 'Sample 2',
        status: 'pending'
      },

      {
        info: 'Sample 3',
        status: 'pending'
      }
    ]
  }
];

const proposalDetailsTitle = [
  {label: 'Proposal ID', value: 'id'},
  {label: 'Date', value: 'date'},
  {label: 'File Upload', value: 'file_upload'}
];
const poDetailsTitle = [
  {label: 'Original Amount', value: 'original_amount'},
  {label: 'Discounted Amount', value: 'discounted_amount'},
  {label: 'Discount', value: 'discount'},
  {label: 'Duration', value: ''},
  {label: 'Start Date', value: 'start_date'},
  {label: 'End Date', value: 'end_date'},
  {label: 'File Location', value: 'document'}
];
//note:might be use in future
// const details = {
//   'Proposal ID': '00010701202223',
//   Date: '07-01-2022',
//   'File Upload': 'PROPOSAL 00010701202223',
//   'Original Amount': '₱ 2 345 678.00',
//   'Discounted Amount': '₱ 1 876 542.40',
//   Discount: '20.00%',
//   Duration: '169 days',
//   'Start Date': '07-18-2022',
//   'End Date': '01-01-2023',
//   'File Location': 'PO4540455000'
// };

const TableDropdown = ({open, details, status}) => {
  const _details = createRequests(details);

  return (
    <TableRow>
      <TableCell style={{padding: 0}} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box paddingX={4} paddingY={2} bgcolor="#FCFBFB">
            <Grid container>
              <Grid item xs={4}>
                <DropdownInfo
                  title="Proposal Detaills"
                  headTitles={proposalDetailsTitle}
                  content={details?.proposal}
                />

                <DropdownInfo
                  title="PO Details"
                  headTitles={poDetailsTitle}
                  content={details?.purchase_order}
                />
              </Grid>
              {_details?.map(({title, data}) => (
                <Grid item xs={title === 'Issued GRs' ? 2 : 3} key={title}>
                  <DropdownDetails title={title} content={data && data} status={status} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default TableDropdown;

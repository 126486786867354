import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import {setErrorPurchaseOrder} from '../actions';

function* post({type, document, payload}) {
  try {
    if (document)
      yield call(poServer.post, '/api/v1/purchase-order/document', document);
    yield call(poServer.post, '/api/v1/purchase-order', payload);
    yield put(push('/dashboard'));
  } catch (error) {
    yield put(setErrorPurchaseOrder(error));
  } finally {
  }
}

export default post;

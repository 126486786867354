function getEnv(name) {
  return process.env[name];
}
const PO_API_URL = getEnv('REACT_APP_POTRACKER_API_URL');
const POKEMON_API_URL = getEnv('REACT_APP_POKEMON_API_URL');

const PUBLIC_URL = getEnv('PUBLIC_URL');
const VERSION = getEnv('REACT_APP_VERSION');

export {PO_API_URL, POKEMON_API_URL, VERSION, PUBLIC_URL};

import {poServer} from 'api';
import {push} from 'redux-first-history';
import {call, put} from 'redux-saga/effects';
import {setErrorGoodReceipt} from '../actions';

function* post({type, payload, document}) {
  delete payload['actions'];
  delete payload['coca']['title'];

  try {
    if (document)
      yield call(poServer.post, '/api/v1/goods-receipt/document', document);
    yield call(poServer.post, '/api/v1/goods-receipt', payload);
    yield put(push('/dashboard/good-receipt'));
  } catch (error) {
    yield put(setErrorGoodReceipt(error));
  } finally {
  }
}

export default post;

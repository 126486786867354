import poServer, {poServerConfig} from './po-server';
import pokemonServer, {pokemonServerConfig} from './pokemon-server';

import request from './interceptors/request';
import response from './interceptors/response';
import {cleanPayload} from 'utils';

const filterRequest = ({params, reducer, state}) => {
  const {page, size, sortBy, orderBy} = reducer;
  let _params = {
    page: page,
    size: size,
    sortBy: sortBy,
    orderBy: orderBy,
    search: state.search,
    searchBy: state.search_by.value,
    status: state.status.id,
    dateStart: state.date_start,
    dateEnd: state.date_end
  };

  Object.keys(params).forEach((key, keyIndex) => {
    const val = Object.values(params)[keyIndex];
    if (typeof val === 'string' || typeof val === 'number') _params[key] = val;
  });
  if (params.search || params.status || params.dateStart || params.dateEnd) {
    _params['page'] = 1;
  }
  return cleanPayload(_params);
};

const apiConfig = (store) => {
  poServerConfig.interceptors.request.use(
    request.resolve(store),
    request.reject(store)
  );
  poServerConfig.interceptors.response.use(
    response.resolve(store),
    response.reject(store)
  );

  pokemonServerConfig.interceptors.request.use(
    request.resolve(store),
    request.reject(store)
  );
  pokemonServerConfig.interceptors.response.use(
    response.resolve(store),
    response.reject(store)
  );
};

export {poServer, pokemonServer, filterRequest, apiConfig};

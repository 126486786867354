import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import {setErrorCoca, setCOCA} from '../actions';

function* edit({type, payload, document, params}) {
  delete payload['actions'];
  try {
    yield put(setCOCA({loading: true}));
    if (document) yield call(poServer.put, '/api/v1/coca/document', document);
    yield call(poServer.put, `/api/v1/coca/${params}`, payload);
    yield put(push('/dashboard/coca'));
  } catch (error) {
    yield put(setErrorCoca(error));
  } finally {
    yield put(setCOCA({loading: false}));
  }
}

export default edit;

import {useReducer, useEffect, useState} from 'react';

import FileActions from '../../FileActions';

import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';
import {connector} from 'ducks';
import {format} from 'date-fns';
import cocaForm, {cocaFormInitState} from 'reducers/coca';
import {postCOCA} from 'ducks/coca';

const Index = ({dispatch, stateChange, router}) => {
  const [state, setState] = useReducer(cocaForm, cocaFormInitState);
  const [upload, setUpload] = useState(null);
  const routerState = router.location.state;

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };

  const onChange = (key) => (e) => {
    if (key === 'start_date' || key === 'end_date') {
      onSetState(key, format(e, 'yyyy-MM-dd'));
    } else if (key === 'original_amount' || key === 'discount') {
      onSetState(key, parseInt(e.target.value));
    } else if (key === 'document') {
      onSetState(key, e);
    } else if (key === 'file') {
      setUpload(e)
    } else onSetState(key, e.target.value);
  };

  const onFetchData = () => {
    onSetState('purchase_order', routerState.data_selected);
  };
  useEffect(onFetchData, [location]);

  const handleDiscountAmount = () => {
    let discAmt = 0;
    discAmt = state.original_amount - (state.discount || 0);
    onSetState('discounted_amount', parseInt(discAmt));
  };
  useEffect(handleDiscountAmount, [state.original_amount, state.discount]);

  const onStateChange = () => {
    stateChange(postCOCA(state, upload));
  }
  useEffect(onStateChange, [state, upload]);

  return (
    <form className={styles.form}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            For Purchase Order Number <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            value={state.purchase_order.po_number}
            onChange={onChange('purchase_order.id')}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Status <span className="required">*</span>
          </Typography>
          <Select fullWidth value={state.status} onChange={onChange('status')}>
            <MenuItem value="5">Pending</MenuItem>
            <MenuItem value="6">Submitted to Client</MenuItem>
            <MenuItem value="7">Signed by Client</MenuItem>
            <MenuItem value="8">Invoiced</MenuItem>
            <MenuItem value="9">Paid</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            COCA Title <span className="required">*</span>
            <TextField
              variant="outlined"
              value={state.title}
              onChange={onChange('title')}
              fullWidth
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Date Created <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.start_date}
              onChange={onChange('start_date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Last Day of COCA <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.end_date}
              onChange={onChange('end_date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Original Amount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            className={styles.textWidth}
            onChange={onChange('original_amount')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              value={state.document}
              fullWidth
            />
            <FileActions 
              handleFileDrive={onChange('document')}
              docPath={onChange('file')}
              isAdd={true}
              isEdit={false}
            />
          </Stack>
          <FileTypes />
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Discount<span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            className={styles.textWidth}
            onChange={onChange('discount')}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Discounted Amount<span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            className={styles.textWidth}
            value={state.discounted_amount}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

/**
 * Get the locale string of date with format of YYYY-MM-DD
 * @param {string | number} value
 * @return {string} date
 */
const getDate = (value) => {
  const date = new Date(value?.trim());
  if (!String(date).includes('Invalid')) {
    return date.toISOString().split('T')[0];
  }
  return '';
};

export {getDate};

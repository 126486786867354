import {useState, useReducer} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Status from '../Status';

import styles from '../../styles/Table.module.scss';
import TableDropdown from './TableDropdownDetails/TableDropdown';
import reducer, {initState} from './reducers/table_rown_default';
import {connector} from 'ducks';
import {DASHBOARD} from 'utils/const';
import {push} from 'redux-first-history';

const TableBodyAccordion = ({
  content = {},
  status = {},
  index,
  selected = {},
  onShowAccordion = () => {},
  onSelectItem = () => {},
  dispatch
}) => {
  const [state, setState] = useReducer(reducer, initState);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
    const updatedState = {...state, [key]: value};
    onShowAccordion(updatedState, content.id);
    onSelect(updatedState, content.id);
  };

  const handleCollapse = () => {
    if (state.is_collapsed) onSetState('is_collapsed', !state.is_collapsed);
    else onSetState('is_collapsed', true);
    // note:might be use in future
    // if (open) {
    //   setOpen(false);
    //   navigate('/dashboard');
    //   return;
    // }

    // setOpen(true);
    // navigate(`/dashboard?id=${content.id}`);
  };

  const handleCheckbox = () => {
    onSelectItem(content);
  };

  const onEdit = () => {
    dispatch(
      push(`/${DASHBOARD}/purchase-order/edit`, {...content, type: 'EDIT'})
    );
  };

  return (
    <>
      <TableRow key={content.id} className={styles.tableBodyRow}>
        <TableCell
          className={`${styles.tableCellBody} ${styles.checkboxCell}`}
          align="center"
        >
          <Checkbox onChange={handleCheckbox} />
        </TableCell>
        {Object.keys(content).map((key, index) => {
          if (key === 'status') {
            return (
              <TableCell
                key={index}
                className={`${styles.tableCellBody} ${styles.borderCell} `}
              >
                <Status {...content[key]} />
              </TableCell>
            );
          }

          // To hide data that is needed for the forms but is not part of the table
          if (
            key === 'id' ||
            key === 'discounted_amount' ||
            key === 'document' ||
            key === 'discount' ||
            key === 'end_date'
          )
            return;

          return (
            <TableCell
              key={index}
              align="center"
              className={`${styles.tableCellBody} ${styles.borderCell} `}
            >
              {content[key]}
            </TableCell>
          );
        })}
        <TableCell className={styles.tableCellBody} align="center">
          <IconButton>
            <ModeEditOutlineOutlinedIcon id="edit-icon" onClick={onEdit} />
          </IconButton>
        </TableCell>

        <TableCell className={styles.tableCellBody} align="center">
          <IconButton onClick={handleCollapse} className={styles.toggleIcon}>
            {state.is_collapsed ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableDropdown
        open={state.is_collapsed}
        details={selected}
        status={status.data}
        //might be use in future
        // poDetails={[]} proposalDetails={[]}
      />
    </>
  );
};

export default connector(TableBodyAccordion);

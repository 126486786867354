/**
 * It returns the represented data val1 if the type expect is true
 * @param {string} type
 * @param {any} val1
 * @param {any} val2
 * @returns val1 or val2
 */
const isType = (type, val1, val2) => {
  if (type === 'array') {
    return Array.isArray(val1) ? val1 : val2;
  }
  return typeof val1 === type ? val1 : val2;
};

/**
 * register only the properties that are included on arrangement
 * and redestructure the object by its possition base on the arrangement
 * @param {object} item
 * @param {Array<string>} arrangement
 * @returns object
 */
const arrangedObject = (item, arrangement) => {
  const KEYS = Object.keys(item);
  let new_obj = {};
  arrangement.forEach((anchor) => {
    KEYS.forEach((key) => {
      if (anchor.toLowerCase() === key.toLowerCase()) {
        Object.assign(new_obj, {[key]: item[key]});
      }
    });
  });
  return new_obj;
};

/**
 * Recreate the object and returns flat object base on the arrangment parmeters
 * and returns object[]
 * @param {Array<object>} data
 * @param {Array<string>} arrangement
 * @returns object[]
 */
const toPrettyData = (data = [], arrangement = []) => {
  let _data = [];
  data.forEach((item) => {
    const new_obj = arrangedObject(item, arrangement);
    _data.push(new_obj);
  });
  return _data;
};

const formatDropdownObj = (data = {}, baseProperty) => {
  const keys = Object.keys(data);
  const vals = Object.values(data);
  let obj = {};
  for (let i in keys) {
    if (keys[i] === baseProperty) {
      obj['value'] = vals[i];
      continue;
    }
    obj[keys[i]] = data[keys[i]];
  }
  return obj;
};

const formatDropdownData = (data = [], baseProperty) => {
  let _data = [{id: '', value: 'All'}];
  data.forEach((item) => {
    const obj = formatDropdownObj(item, baseProperty);
    _data.push(obj);
  });
  return _data;
};

const cleanPayload = (obj) => {
  let payload = {};
  const keys = Object.keys(obj);
  const vals = Object.values(obj);
  for (let i in vals) {
    if (vals[i]) {
      payload[keys[i]] = vals[i];
    }
  }
  return payload;
};

const flattenObj = (data = {}) => {
  const keys = Object.keys(data);
  const values = Object.values(data);
  let object = {};
  for (let key in keys) {
    if (typeof values[key] === 'object') {
      const _object = flattenObj(values[key]);
      const _keys = Object.keys(_object);
      const _values = Object.values(_object);
      for (let _key in _keys) {
        object[`${keys[key]}.${_keys[_key]}`] = _values[_key];
      }
      continue;
    }
    object[keys[key]] = values[key];
  }
  return object;
};

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  });
};

const createRequests = (data) => {
  const titles = [
    {title: 'Project CoCas', value: 'coca'},
    {title: 'Issued GRs', value: 'goods_receipt'},
    {title: 'Invoices', value: 'invoice'}
  ];

  return titles.map(({title, value}) => {
    return {
      title,
      data: data && data[value]
    };
  });
};

const defaultValue = (type, routerState, onChangeValue) => {
  let value = '';
  if (type === 'EDIT') {
    value = routerState;
  } else {
    value = onChangeValue;
  }

  return value;
};

export {
  formatDropdownData,
  formatDropdownObj,
  cleanPayload,
  isType,
  arrangedObject,
  toPrettyData,
  flattenObj,
  formatDate,
  createRequests,
  defaultValue
};

import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import styles from 'styles/Table.module.scss';
import {DownloadFile} from 'components';
import {formatDate} from 'utils';

const DropdownInfo = ({title, headTitles, content = []}) => {
  return (
    <Table size="small" aria-label="purchases" className={styles.tableDropdown}>
      <TableHead>
        <TableRow className={styles.tableDropdownHeader}>
          <TableCell>{title}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {headTitles?.map((item, index) => (
          <TableRow key={index}>
            <TableCell className={styles.tableDropdownColumn}>
              {item.label}:
            </TableCell>

            <TableCell>
              {item.value.includes('date') ? (
                formatDate(content[item.value])
              ) : item.label.includes('File') ? (
                <DownloadFile fileName={content[item.value]} />
              ) : (
                content[item.value]
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DropdownInfo;

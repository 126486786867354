import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import {useNavigate} from 'react-router-dom';

import DashboardContent from '../../components/DashboardContent';
import DashboardLayout from '../../components/DashboardLayout';
import FilterWithDropdown from '../../components/Filter/FilterWithDropdown';
import DashboardTable from '../../components/Table/DashboardTable';
import TableFilterWrapper from '../../components/TableFilterWrapper';
import TableRowUserMgmt from '../../components/Table/TableRowUserMgmt';
import {DASHBOARD, PROPOSAL, USERMGMT} from '../../utils/const';

import {useState} from 'react';
import styles from '../../styles/Table.module.scss';

const sampleData = new Array(15).fill().map((_, i) => ({
  id: i,
  employeeID: 123141231,
  userName: 'testUser',
  lastName: 'Batumbakal',
  firstname: 'Agapito',
  email: 'agapitobatumbakal43@rocketmail.com'
}));

const UserManagement = () => {
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState([]);

  const handleEdit = () => {
    navigate(`/${DASHBOARD}/${USERMGMT}/edit`);
  };

  const selectItem = (bool, id) => {
    if (bool) setSelectedItem((prev) => [...prev, id]);
    else setSelectedItem((prev) => prev.filter((item) => item !== id));
  };

  return (
    <DashboardLayout>
      <DashboardContent title="User Management">
        <TableFilterWrapper>
          <FilterWithDropdown
            selectItems={['by Proposal ID', 'by Purchase Order#']}
            title="Proposal"
            onEdit={handleEdit}
            items={selectedItem}
          />
          <DashboardTable
            className={styles.tableWrapper}
            headTitles={[
              'Employee ID',
              'Username',
              'Last Name',
              'First Name',
              'Email Address'
            ]}
          >
            <TableBody
              className={`${styles.tableBody} ${styles.overviewTable}`}
            >
              {sampleData?.map((content, index) => (
                <TableRowUserMgmt
                  select={selectItem}
                  content={content}
                  key={content.id}
                  index={index}
                />
              ))}
            </TableBody>
          </DashboardTable>
        </TableFilterWrapper>
        <TablePagination
          className={styles.pagination}
          component="div"
          rowsPerPageOptions={[10, 15, 20]}
          onPageChange={() => {}}
          count={32}
          rowsPerPage={5}
          page={0}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page'
            },
            native: true
          }}
        />
      </DashboardContent>
    </DashboardLayout>
  );
};

export default UserManagement;

import {isType} from 'utils';

import proposalResourceSaga from './saga';

import {
  getProposalResource,
  getAllProposalResource,
  getIDDetailProposalResource,
  getDetailsProposalResource,
  putProposalResource,
  putDocumentProposalResource,
  postProposalResource,
  postDocumentProposalResource,
  deleteProposalResource,
  deleteDocumentProposalResource,
  setProposalResource,
  searchProposalResource,
  setErrorProposalResource
} from './actions';

import {
  GET_PROPOSAL_RESOURCE,
  GET_ALL_PROPOSAL_RESOURCE,
  GET_ID_DETAIL_PROPOSAL_RESOURCE,
  GET_DETAILS_PROPOSAL_RESOURCE,
  POST_PROPOSAL_RESOURCE,
  PUT_PROPOSAL_RESOURCE,
  SET_PROPOSAL_RESOURCE,
  SET_ERROR_PROPOSAL
} from './types';

const initState = {
  data: [],
  page: 0,
  size: 0,
  total_data: 0,
  error: '',
  loading: false,
  data_selected: {}
};

const proposalResource = (state = initState, action) => {
  switch (action.type) {
    case GET_PROPOSAL_RESOURCE: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_ALL_PROPOSAL_RESOURCE: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_ID_DETAIL_PROPOSAL_RESOURCE: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_DETAILS_PROPOSAL_RESOURCE: {
      return {
        ...state,
        loading: true
      };
    }
    case POST_PROPOSAL_RESOURCE: {
      return {
        ...state,
        data: action.payload,
        loading: true
      };
    }
    case PUT_PROPOSAL_RESOURCE: {
      return {
        ...state,
        data: action.payload,
        loading: true
      };
    }
    case SET_ERROR_PROPOSAL: {
      return {
        ...state,
        error: action.payload,
        loading: true
      };
    }
    case SET_PROPOSAL_RESOURCE: {
      const {data, error, loading, page, size, total_data, data_selected} =
        action.payload;
      return {
        data: isType('object', data, state.data),
        page: isType('number', page, state.page),
        size: isType('number', size, state.size),
        total_data: isType('number', total_data, state.total_data),
        error: isType('string', error, state.error),
        loading: isType('boolean', loading, state.loading),
        data_selected: isType('object', data_selected, state.data_selected)
      };
    }
    default:
      return state;
  }
};

export {
  getProposalResource,
  getAllProposalResource,
  getIDDetailProposalResource,
  getDetailsProposalResource,
  putProposalResource,
  putDocumentProposalResource,
  postProposalResource,
  postDocumentProposalResource,
  deleteProposalResource,
  deleteDocumentProposalResource,
  setProposalResource,
  proposalResourceSaga,
  searchProposalResource,
  setErrorProposalResource
};
export default proposalResource;

import {Button} from '@mui/material';
import React from 'react';

import {category} from './category.const';

const index = ({
  buttonType = '',
  variant = '',
  label = '',
  small = false,
  onClick,
  className,
  disabled = false
}) => {
  const getColor = () => category[buttonType.toUpperCase()];

  const getButtonType = () => {
    if (variant === 'text') return {...textStyle, ...otherStyles};
    if (variant === 'outlined') return {...outlinedStyle, ...otherStyles};
    if (variant === 'contained') return {...containedStyle, ...otherStyles};
    if (variant === 'error') return {...errorStyle, ...otherStyles};
  };

  const containedStyle = {
    background: getColor(),
    color: 'white',
    border: 'none'
  };

  const textStyle = {
    background: 'transparent',
    color: getColor(),
    border: 'none'
  };

  const outlinedStyle = {
    background: 'transparent',
    color: getColor(),
    border: `1px solid ${getColor()}`
  };

  const errorStyle = {
    background: getColor(),
    color: 'white',
    border: 'none'
  };

  const otherStyles = {
    width: !small ? '100%' : 'fit-content',
    padding: small ? '.5rem 3rem' : '.55rem 0',
    borderRadius: '.6rem'
  };

  return (
    <Button
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}
      sx={getButtonType()}
    >
      {label.toUpperCase()}
    </Button>
  );
};

export default index;

import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {Link} from '@mui/material';

import Status from '../../Status';
import DownloadFile from '../../DownloadFile';
import styles from '../../../styles/Table.module.scss';

const DropdownDetails = ({ title, tableTitle, content, status }) => {

  return (
    <Table size="small" aria-label="purchases" className={styles.tableDropdown}>
      <TableHead>
        <TableRow className={styles.tableDropdownHeader}>
          <TableCell>{title}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {content?.map((item, itemIndex) => {
          let statusObj = status?.find(el => {
            return el.id == item.status
          });
          return (
            <TableRow key={itemIndex}>
              <TableCell className={styles.tableCell}>
                {(item?.document && item?.title) && item?.title}
                {(item?.document && item?.gr_no) && `GR #${item?.gr_no}`}
                {(item?.document && item?.invoice_no) && `#${item?.invoice_no}`}
                {!item.document.includes('https://') ? (
                  <DownloadFile 
                    fileName={item?.document}
                    isAccordion
                  />
                ) : null}
              </TableCell>
              {Object.keys(item).length >= 2 && (
                <TableCell>
                  {statusObj && <Status name={statusObj?.name} color={statusObj?.color} outlined />}
                </TableCell>
              )}
            </TableRow>
          )
        }
        )}
      </TableBody>
    </Table>
  );
};

export default DropdownDetails;

import {isType} from 'utils';

import COCASaga from './saga';
import {
  getCOCA,
  getAllCOCA,
  getIDDetailCOCA,
  getDetailsCOCA,
  postCOCA,
  postDocumentCOCA,
  putCOCA,
  putDocumentCOCA,
  deleteCOCA,
  deleteDocumentCOCA,
  setCOCA,
  searchCoca,
  setErrorCoca
} from './actions';
import {
  GET_COCA,
  GET_ALL_COCA,
  GET_ID_DETAIL_COCA,
  GET_DETAILS_COCA,
  POST_COCA,
  PUT_COCA,
  SET_COCA,
  SET_ERROR_COCA
} from './types';

const initState = {
  data: [],
  page: 0,
  size: 0,
  total_data: 0,
  error: '',
  loading: false,
  data_selected: {}
};

const coca = (state = initState, action) => {
  switch (action.type) {
    case GET_COCA: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_ALL_COCA: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_ID_DETAIL_COCA: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_DETAILS_COCA: {
      return {
        ...state,
        loading: true
      };
    }
    case POST_COCA: {
      return {
        ...state,
        data: action.payload,
        loading: true
      };
    }
    case PUT_COCA: {
      return {
        ...state,
        data: action.payload,
        loading: true
      };
    }
    case SET_ERROR_COCA: {
      return {
        ...state,
        error: action.payload
      }
    }
    case SET_COCA:
      const {data, error, loading, page, size, total_data, data_selected} =
        action.payload;
      return {
        data: isType('array', data, state.data),
        page: isType('number', page, state.page),
        size: isType('number', size, state.size),
        total_data: isType('number', total_data, state.total_data),
        error: isType('string', error, state.error),
        loading: isType('boolean', loading, state.loading),
        data_selected: isType('object', data_selected, state.data_selected)
      };

    default:
      return state;
  }
};

export {
  getCOCA,
  getAllCOCA,
  getIDDetailCOCA,
  getDetailsCOCA,
  postCOCA,
  postDocumentCOCA,
  putCOCA,
  putDocumentCOCA,
  deleteCOCA,
  deleteDocumentCOCA,
  setCOCA,
  setErrorCoca,
  COCASaga,
  searchCoca
};
export default coca;

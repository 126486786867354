import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setProposalResource} from '../actions';
import {push} from 'redux-first-history';
import getAll from './getAll';

function* del({type, payload}) {
  try {
    const result = yield call(poServer.del, '/api/v1/proposal', payload);
    //GET REQUEST
    // yield getAll();
    yield put(push('/dashboard/proposal'));
  } catch (error) {
  } finally {
    yield put(setProposalResource({loading: false}));
  }
}

export default del;

import {useEffect, useState} from 'react';
import {push} from 'redux-first-history';
import {connector} from 'ducks';
import {useLocation} from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CancelModal from '../../components/Modals/Cancel';
import AddFormButton from '../../components/AddFormButton';

import ProposalForm from '../../components/Forms/ProposalsForm';
import POForm from '../../components/Forms/PoForm';
import CocaForm from '../../components/Forms/CocaForm';
import GRForm from '../../components/Forms/AddNewGR';
import InvoicesForm from '../../components/Forms/InvoiceForm';
import DuplicateModal from '../../components/Modals/Duplicate';
import MultiButton from '../../components/StepFormButtons/MultiButton';
import DashboardLayout from '../../components/DashboardLayout';
import StepFormLayout from '../../components/StepFormLayout';
import {header} from '../../utils/stepform.const';
import styles from '../../styles/StepForm.module.scss';
import {DASHBOARD} from '../../utils/const';
import {Toast} from 'components';

import {setErrorPurchaseOrder} from 'ducks/PO';
import {setErrorProposalResource} from 'ducks/proposal_resource';
import {setErrorCoca} from 'ducks/coca';
import {setErrorGoodReceipt} from 'ducks/good_receipt/actions';
import {setErrorInvoice} from 'ducks/invoice/actions';

const OverviewAddForm = ({dispatch, proposalResource, purchaseOrder, coca, goodReceipt, invoice}) => {
  const location = useLocation();
  const [cancel, setCancel] = useState(false);
  const [active, setActive] = useState(0);
  const headerForm = header('ADD NEW', active);
  const titleBtn = header('', active);
  const [payload, setPayload] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);

  const handleSubmit = () => {
    dispatch(payload);
  };

  const handleDuplicateData = () => {
    if (proposalResource.error.includes('404')) {
      setOpenModal(true);
    }
    if (
      proposalResource.error.includes('400') ||
      purchaseOrder.error.includes('400') ||
      coca.error.includes('400') || 
      goodReceipt.error.includes('400') ||
      invoice.error.includes('400')
    ) {
      setErrorUpload(true);
    }
  };
  useEffect(handleDuplicateData, 
    [proposalResource.error, purchaseOrder.error, coca.error, goodReceipt.error, invoice.error]);

  const handleModule = () => {
    const activeForm = location.state.module;
    setActive(activeForm);
  };
  useEffect(handleModule, [location]);

  const initListener = () => {
    dispatch(setErrorCoca(''));
    dispatch(setErrorGoodReceipt(''));
    dispatch(setErrorProposalResource(''));
    dispatch(setErrorPurchaseOrder(''));
    dispatch(setErrorInvoice(''));
  }
  useEffect(initListener, [])

  const addForms = {
    2: <CocaForm />,
    3: <GRForm />,
    4: <InvoicesForm />
  };

  const forms = {
    0: <ProposalForm stateChange={setPayload} />,
    1: <POForm stateChange={setPayload} />,
    2: <CocaForm stateChange={setPayload} />,
    3: <GRForm stateChange={setPayload} />,
    4: <InvoicesForm stateChange={setPayload} />
  };

  const cancelForm = () => {
    dispatch(push(`/${DASHBOARD}`));
  };

  // For future use
  // const handleBack = () => {
  //   if (active !== 0) setActive(active - 1);
  // };

  // For future use
  // const handleNext = () => {
  //   if (active !== 4) setActive(active + 1);
  // };

  return (
    <DashboardLayout>
      <Toast 
        severity="warning"
        open={errorUpload}
        title="File already exists."
        vertical="top"
        horizontal="right"
      />
      <CancelModal
        open={cancel}
        onCancel={cancelForm}
        onClose={() => setCancel(false)}
      />
      <DuplicateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        field="Proposal ID"
      />
      <StepFormLayout active={active}>
        <Stack
          direction="column"
          justifyContent="space-between"
          className={styles.formWrapper}
          mb={5}
        >
          <Typography variant="h3">{headerForm?.header}</Typography>
          <Stack direction="row" justifyContent="space-between" mt={3}>
            <Typography variant="h6">{headerForm?.details}</Typography>
            {active > 1 && (
              <Stack spacing={2} direction="row">
                <AddFormButton
                  title={titleBtn?.header}
                  toastSucess={titleBtn?.header}
                >
                  {addForms[active]}
                </AddFormButton>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            height: '65%',
            overflowY: 'auto',
            paddingRight: '2rem',
            '&::-webkit-scrollbar': {
              width: '4px',
              background: '#f0f0f0',
              color: 'black',
              borderRadius: '20rem'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '20rem',
              background: 'gray'
            }
          }}
        >
          {forms[active]}
        </Box>
        <MultiButton
          onCancel={() => setCancel(true)}
          // For future use
          // onBack={handleBack}
          // onNext={handleNext}
          step={active}
          onSave={handleSubmit}
        />
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(OverviewAddForm);

import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setStatus} from '../actions';

function* getAll({type, module, params}) {
  try {
    yield put(setStatus({loading: true}));
    const res = yield call(poServer.get, `/api/v1/status/${module}`, {
      params
    });
    yield put(setStatus({data: res.result}));
  } catch (error) {
    yield put(setStatus({error}));
  } finally {
    yield put(setStatus({loading: false}));
  }
}

export default getAll;

import {useCallback, useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import {Grid, IconButton, Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';

import useBasePath from 'hooks/useBasePath';
import styles from 'styles/Sidebar.module.scss';
import {getImage} from 'utils/helper';

import {connector} from 'ducks';
import {authLogout} from 'ducks/auth';

import LogoutModal from '../Modals/Logout';
import {sidebarLinks} from './sidebar.const';

const Sidebar = ({dispatch}) => {
  const navigate = useNavigate();
  const BasePath = useBasePath();

  const [url, setUrl] = useState(BasePath);
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    setUrl(BasePath);
  }, [BasePath]);

  const handleLogout = () => {
    dispatch(authLogout());
  };

  // const handleLogout = useCallback(() => {
  //   sessionStorage.removeItem('token');
  //   sessionStorage.removeItem('role');
  //   navigate('/');
  // }, [navigate]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      height="100vh"
      gridTemplateRows="30%"
    >
      <Stack
        className={styles.stack}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        py={{md: 5, xl: 2.5}}
      >
        <Stack spacing={{md: 1.5, lg: 1.7, xl: 2.5}}>
          <img
            className={styles.whiteLogo}
            src={getImage('/white-xurpas-logo.png')}
            // src={xurpasWhiteLogo}
            alt="white Logo"
          />

          {sidebarLinks.map((item) => (
            <NavLink
              to={item.path}
              key={item.label}
              className={
                url === item.path ? styles.iconBtnActive : styles.iconBtn
              }
              name="dashboard"
              textDecoration="none"
            >
              <IconButton className="iconBtnActive">{item.icon}</IconButton>
              <Typography className={styles.label}>{item.label}</Typography>
            </NavLink>
          ))}
        </Stack>

        <Box>
          <IconButton
            className={styles.logoutBtn}
            onClick={() => setLogout(true)}
          >
            <LogoutIcon />
          </IconButton>
          <Typography className={styles.label}>LOGOUT</Typography>
        </Box>
      </Stack>

      <LogoutModal
        onClose={() => setLogout(false)}
        open={logout}
        onLogout={handleLogout}
      />
    </Grid>
  );
};

export default connector(Sidebar);

const GET_GOOD_RECEIPT = 'GET-GOOD-RECEIPT';
const GET_ALL_GOOD_RECEIPT = 'GET-ALL-GOOD-RECEIPT';
const GET_GOOD_RECEIPT_DETAIL = 'GET-GOOD-RECEIPT-DETAIL';
const GET_ONE_GOOD_RECEIPT = 'GET-ONE-GOOD-RECEIPT';
const SEARCH_GOOD_RECEIPT = 'SEARCH_GOOD_RECEIPT';

const POST_GOOD_RECEIPT = 'POST-GOOD-RECEIPT';
const POST_GOOD_RECEIPT_DOC = 'POST-GOOD-RECEIPT-DOC';

const SET_GOOD_RECEIPT = 'SET-GOOD-RECEIPT';

const DELETE_GOOD_RECEIPT = 'DELETE-GOOD-RECEIPT';
const DELETE_GOOD_RECEIPT_DOC = 'DELETE-GOOD-ECEIPT-DOC';

const PUT_GOOD_RECEIPT = 'PUT-GOOD-RECEIPT';
const PUT_GOOD_RECEIPT_DOC = 'PUT-GOOD-RECEIPT-DOC';
const SET_ERROR_GOOD_RECEIPT = 'SET_ERROR_GOOD_RECEIPT';

export {
  GET_GOOD_RECEIPT,
  GET_ALL_GOOD_RECEIPT,
  GET_GOOD_RECEIPT_DETAIL,
  POST_GOOD_RECEIPT,
  POST_GOOD_RECEIPT_DOC,
  SET_GOOD_RECEIPT,
  DELETE_GOOD_RECEIPT,
  DELETE_GOOD_RECEIPT_DOC,
  PUT_GOOD_RECEIPT,
  PUT_GOOD_RECEIPT_DOC,
  SEARCH_GOOD_RECEIPT,
  GET_ONE_GOOD_RECEIPT,
  SET_ERROR_GOOD_RECEIPT
};

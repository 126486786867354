import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHeadFilter from './TableHeadFilter';

const DashboardTable = ({children, headTitles, rightSpace, className}) => {
  return (
    <TableContainer
      sx={{
        '&::-webkit-scrollbar': {
          width: '1px'
        }
      }}
      className={className}
    >
      <Table>
        <TableHeadFilter headTitle={headTitles} rightSpace={rightSpace} />
        {children}
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;

import {isType} from "utils";
import {SET_DOCUMENT} from "./types";
import {getDocument, setErrorDownload, setDocument} from "./actions";

const initState = {
    data: '',
    error: '',
    message: '',
    loading: false,
};

const documents = (state = initState, action) => {
    switch (action.type) {
        case SET_DOCUMENT: 
            const {data, error, message, loading} = action.payload;
            return {
                data: isType('string', data, state.data),
                error: isType('string', error, state.error),
                message: isType('string', message, state.message),
                loading: isType('boolean', loading, state.loading)
            }
        default: 
            return state;
    }
}

export {getDocument, setErrorDownload, setDocument};
export default documents;
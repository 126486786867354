import {useState} from 'react';
import {AiOutlineSearch} from 'react-icons/ai';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {Dropdown, DatePicker, TextInput} from 'components';

import DeleteModal from '../../components/Modals/Delete';
import SuccessDeleteModal from '../../components/Modals/SuccessDelete';

import {delPurchaseOrder} from 'ducks/PO';
import {connector} from 'ducks';
import {Button, Stack, Typography} from '@mui/material';

import {formatDropdownData} from 'utils';

import {role, superAdmin} from '../../utils/const';
import {DASHBOARD} from '../../utils/const';
import styles from '../../styles/TableFilter.module.scss';

import {push} from 'redux-first-history';

const FilterOverview = ({
  state,
  onChange,
  status,
  items = [],
  dispatch,
  moduleForm,
  dataSelected,
  onSearch = () => {}
}) => {
  const [del, setDel] = useState(false);
  const [suc, setSuc] = useState(false);

  const handleAddButton = () => {
    dispatch(
      push(`/${DASHBOARD}/add`, {
        ...state,
        type: 'ADD',
        module: moduleForm,
        data_selected: dataSelected
      })
    );
  };

  const onClick = (ae) => () => {
    if (ae === 'search') onSearch(state.search);
  };

  const handleDeleteButton = (e) => {
    e.preventDefault();
    setDel(true);
  };

  const handleCloseButton = (e) => {
    e.preventDefault();
    setDel(false);
  };

  const handleSuccessButton = (e) => {
    e.preventDefault();
    setSuc(true);
    dispatch(
      delPurchaseOrder({
        ids: [10]
      })
    );
  };

  const handleSuccessCloseButton = (e) => {
    e.preventDefault();
    setDel(false);
    setSuc(false);
  };

  return (
    <div className={styles.filterOverview}>
      <Stack
        direction="row"
        spacing={{md: 3, xl: 1}}
        alignItems="center"
        justifyContent="space-between"
      >
        <DeleteModal
          open={del}
          onClose={(e) => {
            handleCloseButton(e);
          }}
          title={'Delete Entry?'}
          content={`Are you sure you want to delete this entry?
          This action can't be reverted`}
          onDelete={(e) => {
            handleSuccessButton(e);
          }}
        />
        <SuccessDeleteModal
          open={suc}
          onClose={(e) => {
            handleSuccessCloseButton(e);
          }}
          type="delete"
          title={'Deleted Entry'}
          content={'Selected Entry was deleted successfully'}
        />
        <TextInput
          placeholder="Search..."
          prefixComponent={<AiOutlineSearch size="15px" />}
          value={state.search}
          onChangeText={onChange('search')}
          onEnter={onClick('search')}
        />
        <Dropdown
          data={formatDropdownData(status.data, 'name')}
          placeholder="Status --"
          selected={state['status']}
          selectedChange={onChange('status')}
        />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography className={styles.dateTypograpy}>Filter from:</Typography>
          <DatePicker
            value={state['date_start']}
            onChange={onChange('date_start')}
          />
          <Typography className={styles.dateTypograpy}>to:</Typography>
          <DatePicker
            value={state['date_end']}
            minimum={state['date_start']}
            onChange={onChange('date_end')}
          />
        </Stack>
        <Stack direction="row" spacing={2} ml={10}>
          {role === superAdmin && (
            <Button
              variant="text"
              id={items.length && 'red-btn'}
              disabled={items.length < 1}
              startIcon={<DeleteOutlineIcon />}
              sx={{fontSize: '.7rem', textTransform: 'capitalize'}}
              onClick={(e) => {
                handleDeleteButton(e);
              }}
            >
              Delete
            </Button>
          )}
          <Button
            variant="text"
            id={items.length === 1 && 'blue-btn'}
            onClick={handleAddButton}
            startIcon={<AddCircleOutlineIcon />}
            sx={{fontSize: '.7rem', textTransform: 'capitalize'}}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default connector(FilterOverview);

import {useCallback, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardLayout from '../../components/DashboardLayout';
import StepFormLayout from '../../components/StepFormLayout';

import AddFormButton from '../../components/AddFormButton';
import EditFormButton from '../../components/EditFormButton';

import AddInvoiceModalForm from '../../components/Forms/AddInvoiceModalForm';
import InvoiceEditForm from '../../components/Forms/InvoiceEditForm';
import InvoiceForm from '../../components/Forms/InvoiceForm';

import SaveButton from '../../components/StepFormButtons/SaveButton';

import CancelModal from '../../components/Modals/Cancel';

import {DASHBOARD, INVOICES} from '../../utils/const';

import {Box} from '@mui/material';
import styles from '../../styles/Form.module.scss';
import {connector} from 'ducks';
import {
  getAllInvoice,
  getInvoiceDetails,
  getOneInvoice
} from 'ducks/invoice/actions';
import invoiceForm, {invoiceFormInitState} from 'reducers/invoice';

const EditInvoice = ({dispatch, router}) => {
  const navigate = useNavigate();
  const {location} = router;

  const [cancel, setCancel] = useState(false);

  const [state, setState] = useReducer(invoiceForm, invoiceFormInitState);

  const actionState = (action) => {
    setState({type: 'SET', payload: {actions: action}});
  };

  const onSubmit = () => {
    dispatch(state.actions);
    dispatch(getAllInvoice({page: 1, size: 10}));
  };

  const handleCancelForm = useCallback(() => {
    navigate(`/${DASHBOARD}/${INVOICES}`);
  }, []);

  const initListener = () => {
    dispatch(getInvoiceDetails({id: location.state.id}));
    dispatch(getOneInvoice({id: location.state.id}));
  };
  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <CancelModal
        open={cancel}
        onClose={() => setCancel(false)}
        onCancel={handleCancelForm}
      />
      <StepFormLayout variant="edit-form" active={4}>
        <div className={styles.titleSpacing}>
          <Typography variant="h3" sx={{fontSize: {lg: '2rem', xl: 'auto'}}}>
            EDIT INVOICE
          </Typography>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" sx={{fontSize: {lg: '1rem', xl: 'auto'}}}>
            INVOICE DETAILS
          </Typography>
          <Stack spacing={2} direction="column">
            <AddFormButton title="invoice" toastSucess="Invoice">
              <AddInvoiceModalForm />
            </AddFormButton>
            <EditFormButton title="invoice">
              <InvoiceEditForm />
            </EditFormButton>
          </Stack>
        </Stack>
        <Box
          sx={{
            height: '65%',
            overflowY: 'auto',
            paddingRight: '2rem',
            // pointerEvents: 'none',
            '&::-webkit-scrollbar': {
              width: '4px',
              background: '#f0f0f0',
              color: 'black',
              borderRadius: '20rem'
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '20rem',
              background: 'gray'
            }
          }}
        >
          <InvoiceForm stateChange={actionState} />
        </Box>
        <SaveButton
          title="Save"
          absolute
          onClose={() => setCancel(true)}
          onSave={onSubmit}
        />
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(EditInvoice);

import {
  GET_ALL_INVOICE,
  GET_INVOICE,
  SET_INVOICE,
  DELETE_INVOICE,
  POST_INVOICE,
  SEARCH_INVOICE,
  GET_INVOICE_DETAILS,
  GET_ONE_INVOICE,
  PUT_INVOICE,
  SET_ERROR_INVOICE
} from './types';

const getInvoice = () => ({
  type: GET_INVOICE
});

const getAllInvoice = (params) => ({
  type: GET_ALL_INVOICE,
  params
});

const getOneInvoice = (payload) => ({
  type: GET_ONE_INVOICE,
  payload
});

const getInvoiceDetails = (payload) => ({
  type: GET_INVOICE_DETAILS,
  payload
});

const setInvoice = (payload) => ({
  type: SET_INVOICE,
  payload
});
const postInvoice = (payload, document) => ({
  type: POST_INVOICE,
  payload,
  document
});

const putInvoice = (payload, document, params) => ({
  type: PUT_INVOICE,
  payload,
  document,
  params
});
const delInvoice = (payload) => ({
  type: DELETE_INVOICE,
  payload
});

const searchInvoice = (payload) => ({
  type: SEARCH_INVOICE,
  payload
});

const setErrorInvoice = (payload) => ({
  type: SET_ERROR_INVOICE,
  payload
})

export {
  getInvoice,
  getAllInvoice,
  setInvoice,
  delInvoice,
  postInvoice,
  searchInvoice,
  getInvoiceDetails,
  getOneInvoice,
  putInvoice,
  setErrorInvoice
};

import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setGoodReceipt} from '../actions';

function* getAll({type, params}) {
  try {
    const {
      result: {data, metadata}
    } = yield call(poServer.get, '/api/v1/goods-receipt/list', {
      params
    });
    yield put(
      setGoodReceipt({
        data,
        error: '',
        size: metadata.current_size,
        page: metadata.current_page,
        total_page: metadata.total_page,
        total_data: metadata.total_size
      })
    );
  } catch (error) {
    yield put(setGoodReceipt({error}));
  } finally {
    yield put(setGoodReceipt({loading: false}));
  }
}

export default getAll;

import {poServer} from 'api';
import {call} from 'redux-saga/effects';

function* get() {
  try {
    const result = yield call(poServer.get, '/api/v1/goods-receipt/1');
  } catch (error) {
  } finally {
  }
}

export default get;

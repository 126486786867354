import storage from 'utils/storage';

function resolve(store) {
  return function (config) {
    const AccessToken = storage.get('at');

    if (AccessToken) {
      config.headers['Authorization'] = `Bearer ${AccessToken}`;
    }

    return config;
  };
}

function reject(store) {
  return function (error) {
    return Promise.reject(error);
  };
}

const request = {
  resolve,
  reject
};
export default request;

import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setInvoice} from '../actions';

function* getSearch({type, payload}) {
  try {
    const {
      result: {data, metadata}
    } = yield call(
      poServer.get,
      `/api/v1/invoice/list?search=${payload.search}&searchBy=${payload.searched_by}`
    );
    yield put(
      setInvoice({
        data,
        size: metadata.current_size,
        page: metadata.current_page,
        total_page: metadata.total_page,
        total_data: metadata.total_size
      })
    );
  } catch (error) {
    yield put(setInvoice({error}));
  } finally {
    yield put(setInvoice({loading: false}));
  }
}

export default getSearch;

export const header = (type, index) => {
  const headers = {
    0: {header: `${type} PROPOSAL`, details: 'PROPOSAL DETAILS'},
    1: {header: `${type} PO`, details: 'PURCHASE ORDER DETAILS'},
    2: {header: `${type} COCA`, details: 'COCA DETAILS'},
    3: {header: `${type} GR`, details: 'GR DETAILS'},
    4: {header: `${type} INVOICE`, details: 'INVOICE DETAILS'}
  };

  return headers[index];
};

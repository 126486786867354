// GET
const GET_PROPOSAL_RESOURCE = 'GET-PROPOSAL-RESOURCE';
const GET_ALL_PROPOSAL_RESOURCE = 'GET-ALL-PROPOSAL-RESOURCE';
const GET_ID_DETAIL_PROPOSAL_RESOURCE = 'GET-ID-DETAIL-PROPOSAL-RESOURCE';
const GET_DETAILS_PROPOSAL_RESOURCE = 'GET-DETAILS-PROPOSAL-RESOURCE';
const SEARCH_PROPOSAL_RESOURCE = 'SEARCH_PROPOSAL_RESOURCE';

// PUT
const PUT_PROPOSAL_RESOURCE = 'EDIT-PROPOSAL-RESOURCE';
const PUT_DOCUMENT_PROPOSAL_RESOURCE = 'EDIT-DOC-PROPOSAL-RESOURCE';

// POST
const POST_PROPOSAL_RESOURCE = 'POST-PROPOSAL-RESOURCE';
const POST_DOCUMENT_PROPOSAL_RESOURCE = 'POST-DOC-PROPOSAL-RESOURCE';

// DELETE
const DELETE_PROPOSAL_RESOURCE = 'DELETE-PROPOSAL-RESOURCE';
const DELETE_DOCUMENT_PROPOSAL_RESOURCE = 'DELETE-DOC-PROPOSAL-RESOURCE';

// SET
const SET_PROPOSAL_RESOURCE = 'SET-PROPOSAL-RESOURCE';
const SET_ERROR_PROPOSAL = 'SET_ERROR_PROPOSAL';

export {
  GET_PROPOSAL_RESOURCE,
  GET_ALL_PROPOSAL_RESOURCE,
  GET_ID_DETAIL_PROPOSAL_RESOURCE,
  GET_DETAILS_PROPOSAL_RESOURCE,
  PUT_PROPOSAL_RESOURCE,
  PUT_DOCUMENT_PROPOSAL_RESOURCE,
  POST_PROPOSAL_RESOURCE,
  POST_DOCUMENT_PROPOSAL_RESOURCE,
  DELETE_PROPOSAL_RESOURCE,
  DELETE_DOCUMENT_PROPOSAL_RESOURCE,
  SET_PROPOSAL_RESOURCE,
  SEARCH_PROPOSAL_RESOURCE,
  SET_ERROR_PROPOSAL
};

/* REQUEST TYPES */
const GET_POKEMONS = 'GET-POKEMONS';
const POST_POKEMON = 'POST-POKEMON';
const POST_POKEMONS = 'POST-POKEMONS'; //if we could able push multitple items
const PATCH_POKEMON = 'PATCH-POKEMON';

/* STATE TYPES */
const SET_POKEMON = 'SET-POKEMON';
const PUSH_POKEMON = 'PUSH-POKEMON';

export {
  GET_POKEMONS,
  POST_POKEMON,
  POST_POKEMONS,
  PATCH_POKEMON,
  SET_POKEMON,
  PUSH_POKEMON
};

import {useState} from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import styles from '../../styles/Table.module.scss';

const TableHeadFilter = ({headTitle, rightSpace}) => {
  const [sort, setSort] = useState('asc');
  const [sortBy, setSortBy] = useState('');

  const sortHandler = (selectedColumn) => {
    const isAsc = sortBy === selectedColumn && sort === 'asc';
    setSort(isAsc ? 'desc' : 'asc');
    setSortBy(selectedColumn);
  };

  return (
    <TableHead className={styles.tableHead}>
      <TableRow>
        <TableCell width="10px"></TableCell>
        {headTitle?.map((title, index) => (
          <TableCell
            key={`${title}-${index}`}
            sortDirection={false}
            className={styles.tableCellHeader}
            align="center"
          >
            {title}
            {/* {!title.match(/action|file actions|file uploaded/gi) ? (
              <TableSortLabel
                IconComponent={ExpandLessIcon}
                active={sortBy === title}
                direction={sortBy === title ? sort : 'asc'}
                className={styles.sortIcon}
                onClick={() => sortHandler(title)}
              >
                {title}
              </TableSortLabel>
            ) : (
              title
            )} */}
          </TableCell>
        ))}
        {rightSpace && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadFilter;

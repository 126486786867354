import {poServer} from 'api';
import {push} from 'redux-first-history';
import {call, put} from 'redux-saga/effects';
import {setErrorInvoice} from '../actions';

function* edit({type, payload, document, params}) {
  delete payload['actions'];
  try {
    if (document)
      yield call(poServer.put, '/api/v1/invoice/document', document);
    yield call(poServer.put, `/api/v1/invoice/${params}`, payload);
    yield put(push('/dashboard/invoices'));
  } catch (error) {
    yield put(setErrorInvoice(error));
  } finally {
  }
}

export default edit;

import {getStatusPrefixName} from 'utils/helper';
import styles from './.module.css';

const Status = ({id, name, color, outlined}) => {
  const status_name = getStatusPrefixName(name).toLowerCase();
  return (
    <div
      className={`${styles.status} ${
        outlined
          ? styles[`outlined-${status_name}`]
          : styles[`plain-${status_name}`]
      }`}
    >
      {name.replace(/\_/g, ' ')}
    </div>
  );
};

export default Status;

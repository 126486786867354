const {poServer} = require('api');
const {default: purchaseOrder} = require('ducks/PO');
const {call, put} = require('redux-saga/effects');
const {setStatus} = require('../actions');

function* getStatusCount({type, params}) {
  try {
    yield put(setStatus({loading: true}));
    const res = yield call(
      poServer.get,
      `/api/v1/purchase-order/summary_count`
    );
    yield put(setStatus({count: res.result}));
  } catch (error) {
    yield put(setStatus({error}));
  } finally {
    yield put(setStatus({loading: false}));
  }
}

export default getStatusCount;

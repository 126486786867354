import {useState} from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Modal from '@mui/material/Modal';

import Button from '../../Button';
import UploadModal from '../UpdateFile';

import styles from '../../../styles/ModalLayout.module.scss';

const Index = ({
    open, 
    onClose,
    handleFile
  }) => {
  const [uploadModal, setUploadModal] = useState(false);

  const handleSave = () => {
    setUploadModal(false);
    onClose();
  };

  return (
    <>
      <UploadModal
        open={uploadModal}
        onSave={handleSave}
        onClose={() => setUploadModal(false)}
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack className={styles.addLinkModal} justifyContent="space-between">
          <Typography variant="h4">ADD DRIVE LINK</Typography>
          <Stack>
            <Typography variant="p" mb={2}>
              Link (e.g. https://drive.google.com...)
            </Typography>
            <TextField 
              className={styles.inputLink}
              onChange={handleFile} />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
            <Button
              variant="outlined"
              buttonType="cancel"
              label="cancel"
              small
              onClick={onClose}
            />
            <Button
              variant="contained"
              label="add link"
              buttonType="next"
              onClick={() => setUploadModal(true)}
              small
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default Index;

export const role = 'super-admin';
export const superAdmin = 'super-admin';

export const baseUrl = '/services/app01/';

export const DASHBOARD = 'dashboard';
export const PROPOSAL = 'proposal';
export const COCA = 'coca';
export const GR = 'good-receipt';
export const INVOICES = 'invoices';
export const USERMGMT ='usermgmt'

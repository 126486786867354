import {
  GET_POKEMONS,
  POST_POKEMON,
  POST_POKEMONS,
  PATCH_POKEMON,
  PUSH_POKEMON,
  SET_POKEMON
} from './types';

/* REQUEST ACTIONS */
const getPokemons = () => ({
  type: GET_POKEMONS
});
const postPokemon = (payload) => ({
  type: POST_POKEMON,
  payload
});
const postPokemons = (payload) => ({
  type: POST_POKEMONS,
  payload
});
const patchPokemon = (payload) => ({
  type: PATCH_POKEMON,
  payload
});

/* STATE ACTIONS */
const pushPokemon = (payload) => ({
  type: PUSH_POKEMON,
  payload
});
const setPokemon = ({
  data,
  selected_data,
  page,
  size,
  total_data,
  error,
  loading
}) => ({
  type: SET_POKEMON,
  payload: {data, selected_data, page, size, total_data, error, loading}
});

export {
  getPokemons,
  postPokemon,
  postPokemons,
  patchPokemon,
  pushPokemon,
  setPokemon
};

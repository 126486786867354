import {useState} from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CancelModal from '../Cancel';
import SaveButton from '../../StepFormButtons/SaveButton';

import styles from '../../../styles/ModalLayout.module.scss';

const Index = ({children, open, onClose, onSave, title}) => {
  const [cancelModal, setCancelModal] = useState(false);

  const handleCancelForm = () => {
    setCancelModal(false);
    onClose();
  };

  return (
    <>
      <CancelModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        onCancel={handleCancelForm}
      />
      <Modal open={open}>
        <Box className={styles.editFormModal}>
          <Stack justifyContent="space-between" alignContent="center">
            <Stack spacing={6}>
              <Typography variant="h4">{title}</Typography>
              <Box>{children}</Box>
            </Stack>
            <SaveButton
              onClose={() => setCancelModal(true)}
              onSave={onSave}
              title="Add"
            />
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default Index;

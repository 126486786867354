import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom/client';

import Navigator from 'navigator';
import store, {history, persistor} from 'ducks';
import {apiConfig} from 'api';
import {PersistGate} from 'redux-persist/integration/react';

/**
 * @CONDITION
 * remove loggings when in production | build
 */
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

//configure APIs
apiConfig(store);

const root = document.getElementById('root');
const container = ReactDOM.createRoot(root);

container.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <Navigator history={history} />
    {/* </PersistGate> */}
  </Provider>
);

import coca from 'ducks/coca';
import {isType} from 'utils';

const initState = {
  gr_no: '',
  document: '',
  date_created: '',
  actions: null,
  coca: {},
  purchase_order: {}
};

const initializer = (payload) => (state) => {
  return {
    type: 'SET',
    payload: {
      ...state,
      ...payload
    }
  };
};

const grForm = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {gr_no, document, coca, purchase_order, date_created, actions} =
        action.payload;
      return {
        gr_no: isType('string', gr_no, state.gr_no),
        document: isType('string', document, state.document),
        date_created: isType('string', date_created, state.date_created),
        actions: isType('object', actions, state.actions),
        coca: isType('object', coca, state.coca),
        purchase_order: isType('object', purchase_order, state.purchase_order)
      };
    default:
      return state;
  }
};

export {initState as grFormInitState, initializer as grInitializer};

export default grForm;

import {poServerConfig} from '.';

const get = (url, config) => {
  return poServerConfig.get(url, config);
};

const post = (url, body, config) => {
  return poServerConfig.post(url, body, config);
};

const put = (url, body, config) => {
  return poServerConfig.put(url, body, config);
};

const patch = (url, body, config) => {
  return poServerConfig.patch(url, body, config);
};

const del = (url, body, config) => {
  return poServerConfig.delete(url, {
    data: body,
    config
  });
};

const poServer = {
  get,
  post,
  put,
  patch,
  del
};
export default poServer;

import createSagaMiddleware from 'redux-saga';
import {configureStore} from '@reduxjs/toolkit';
import {createReduxHistoryContext} from 'redux-first-history';
import {persistStore} from 'redux-persist';
import {createBrowserHistory} from 'history';

import {SUBPATH} from 'constants/strings';

// import persist from './persistor';
import connector from './connector';
import reducers from './reducers';
import sagas from './sagas';

const {createReduxHistory, routerMiddleware, routerReducer} =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    basename: SUBPATH
  });

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  // reducer: persist(reducers(routerReducer)),
  reducer: reducers(routerReducer),
  middleware: [routerMiddleware, sagaMiddleware]
  // devTools: true
});

const history = createReduxHistory(store);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export {history, persistor, connector};
export default store;

import { takeLatest } from "redux-saga/effects";
import { GET_USER, PUT_USER, DELETE_USER, POST_USER } from "../types";

import get from "./get";
import put_user from "./put_user";
import delete_user from "./delete_user";
import post from "./post";

function* userSaga() {
  yield takeLatest(GET_USER, get);
  yield takeLatest(PUT_USER, put_user);
  yield takeLatest(DELETE_USER, delete_user);
  yield takeLatest(POST_USER, post);
}

export default userSaga;
const getChars = (name, from, to) => {
  return String(name).substring(from, to);
};

/**
 * Get the unique identifier of selected data
 * @param {Array<object>} current_selected
 * @param {object} select
 * @return {string}
 */
const getUniqueName = (current_selected = [], select) => {
  let not_unique = true;
  let chars_length = 1;
  let unique_name = '';

  while (not_unique) {
    unique_name = getChars(select, 0, chars_length);
    const isExist = current_selected.find(
      (curr_select) => curr_select.toLowerCase() === unique_name.toLowerCase()
    );

    if (!isExist) {
      not_unique = false;
      break;
    }
    chars_length += 1;
  }
  return unique_name;
};

/**
 * Get conditional dependencies
 * @param {boolean} condition
 * @param {any} data1
 * @param {any} data2
 */
const getDataByCondition = (condition, data1, data2) => {
  if (condition) {
    return data1;
  }
  return data2;
};

export {getUniqueName, getDataByCondition};

import {pokemonServerConfig} from '.';

const get = (url, config) => {
  return pokemonServerConfig.get(url, config);
};

const post = (url, body, config) => {
  return pokemonServerConfig.post(url, body, config);
};

const put = (url, body, config) => {
  return pokemonServerConfig.put(url, body, config);
};

const patch = (url, body, config) => {
  return pokemonServerConfig.patch(url, body, config);
};

const del = (url, config) => {
  return pokemonServerConfig.delete(url, config);
};

const pokemonServer = {
  get,
  post,
  put,
  patch,
  del
};
export default pokemonServer;

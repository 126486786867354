import {all} from 'redux-saga/effects';

import {pokemonSaga} from './pokemon';

import {COCASaga} from './coca';
import {proposalResourceSaga} from './proposal_resource';
import {goodReceiptSaga} from './good_receipt';
import {invoiceSaga} from './invoice';
import {authSaga} from './auth';
import userSaga from './user-controller/saga';
import {purchaseOrderSaga} from './PO';
import {statusSaga} from './status';
import documentSaga from './document/saga';

export default function* saga() {
  yield all([
    pokemonSaga(),
    authSaga(),
    userSaga(),
    goodReceiptSaga(),
    invoiceSaga(),
    purchaseOrderSaga(),
    proposalResourceSaga(),
    COCASaga(),
    statusSaga(),
    documentSaga()
  ]);
}

import {takeLatest} from 'redux-saga/effects';

import {
  GET_PROPOSAL_RESOURCE,
  GET_ALL_PROPOSAL_RESOURCE,
  GET_ID_DETAIL_PROPOSAL_RESOURCE,
  GET_DETAILS_PROPOSAL_RESOURCE,
  POST_PROPOSAL_RESOURCE,
  PUT_PROPOSAL_RESOURCE,
  DELETE_PROPOSAL_RESOURCE,
  SEARCH_PROPOSAL_RESOURCE
} from '../types';

import get from './get';
import getAll from './getAll';
import getIDDetail from './getIDDetail';
import getDetails from './getDetails';
import post from './post';
import edit from './put';
import del from './delete';
import search from './search';

function* proposalResourceSaga() {
  yield takeLatest(GET_PROPOSAL_RESOURCE, get);
  yield takeLatest(GET_ALL_PROPOSAL_RESOURCE, getAll);
  yield takeLatest(GET_DETAILS_PROPOSAL_RESOURCE, getDetails);
  yield takeLatest(GET_ID_DETAIL_PROPOSAL_RESOURCE, getIDDetail);
  yield takeLatest(SEARCH_PROPOSAL_RESOURCE, search);
  yield takeLatest(DELETE_PROPOSAL_RESOURCE, del);
  yield takeLatest(PUT_PROPOSAL_RESOURCE, edit);
  yield takeLatest(POST_PROPOSAL_RESOURCE, post);
}

export default proposalResourceSaga;

import {
  Coca,
  EditCoca,
  EditGR,
  EditInvoice,
  GoodReceipt,
  Invoices,
  Overview,
  OverviewAddForm,
  Proposal,
  EditProposals,
  OverviewEditForm
  // For future use
  // UserManagement,
  // NotFound,
} from 'pages';
import EditPO from 'pages/Dashboard/EditPO';
// For future use
// import EditUserMgmt from 'pages/Dashboard/EditUserMgmt';

const routes = [
  {
    index: true,
    element: <Overview />
  },
  {
    path: 'add',
    element: <OverviewAddForm />
  },
  {
    path: 'edit',
    element: <OverviewEditForm />
  },
  {
    path: 'purchase-order/edit',
    element: <EditPO />
  },
  {
    path: 'coca',
    element: <Coca />
  },
  {
    path: 'coca/edit',
    element: <EditCoca />
  },
  {
    path: 'good-receipt',
    element: <GoodReceipt />
  },
  {
    path: 'good-receipt/edit',
    element: <EditGR />
  },
  {
    path: 'invoices',
    element: <Invoices />
  },
  {
    path: 'invoices/edit',
    element: <EditInvoice />
  },
  {
    path: 'proposal',
    element: <Proposal />
  },
  {
    path: 'proposal/edit',
    element: <EditProposals />
  }
  // For future use
  // {
  //   path: 'usermgmt',
  //   element: <UserManagement />
  // },
  // {
  //   path: 'usermgmt/edit',
  //   element: <EditUserMgmt />
  // }
];

export {routes as dashboardRoutes};

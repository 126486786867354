import {useEffect, useReducer, useState} from 'react';
import FileActions from '../../FileActions';
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import styles from '../../../styles/Form.module.scss';
import FileTypes from '../../FileTypes';
import poForm, {poFormInitState} from 'reducers/po';
import {format} from 'date-fns';
import {connector} from 'ducks';
import {postPurchaseOrder, putPurchaseOrder} from 'ducks/PO';
import DuplicateModal from '../../Modals/Duplicate';

const Index = ({dispatch, stateChange, data, router}) => {
  const [state, setState] = useReducer(poForm, poFormInitState);
  const [closeModal, setCloseModal] = useState(false);
  const [upload, setUpload] = useState(null);

  const routerState = router.location.state;
  const proposalID = routerState?.proposal_id || data?.proposal_id;
  const status = data?.status.id;

  const isAddType = routerState.type === 'ADD';
  const isEditType = routerState.type === 'EDIT';

  const onSetState = (key, val) => {
    setState({
      type: 'SET',
      payload: {[key]: val}
    });
  };

  const onChange = (key) => (e) => {
    if (key === 'start_date' || key === 'end_date') {
      onSetState(key, format(e, 'yyyy-MM-dd'));
    } else if (key === 'original_amount' || key === 'discount') {
      onSetState(key, parseInt(e.target.value));
    } else if (key === 'file') {
      setUpload(e);
    } else if (key === 'document') {
      onSetState(key, e);
    } else {
      onSetState(key, e.target.value);
    }
    onSetState('proposal_id', proposalID);
  };

  const handleDiscountAmount = () => {
    let discAmt = 0;
    discAmt = state.original_amount - (state.discount || 0);
    onSetState('discounted_amount', parseInt(discAmt));
    onSetState('proposal_id', parseInt(proposalID));
  };
  useEffect(handleDiscountAmount, [state.original_amount, state.discount]);

  const onFetchData = () => {
    const state = {
      type: 'SET',
      payload: {
        ...data,
        proposal_id: proposalID,
        status
      }
    };
    if (isAddType) state['payload'] = {proposal_id: proposalID};
    setState(state);
  };
  useEffect(
    onFetchData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, proposalID, routerState]
  );

  const onStateChange = () => {
    if (isEditType) stateChange(putPurchaseOrder(state, upload, state.id));
    if (isAddType) stateChange(postPurchaseOrder(state, upload));
  };
  useEffect(onStateChange, [state]);

  return (
    <form className={styles.form}>
      <DuplicateModal
        open={closeModal}
        onClose={() => setCloseModal(false)}
        field="Purchase Order Number"
      />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography mb={2}>
            Purchase Order Number<span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={state.po_number}
            onChange={onChange('po_number')}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Status <span className="required">*</span>
          </Typography>
          <Select value={state.status} onChange={onChange('status')} fullWidth>
            <MenuItem value={2}>Ongoing</MenuItem>
            <MenuItem value={4}>Paid</MenuItem>
            <MenuItem value={1}>Pending</MenuItem>
            <MenuItem value={3}>Rendered</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Name <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={state.name}
            onChange={onChange('name')}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            Start Date <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.start_date}
              onChange={onChange('start_date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <Typography mb={2}>
            End Date <span className="required">*</span>
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.datePicker}
              value={state.end_date}
              onChange={onChange('end_date')}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Original Amount <span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            value={state.original_amount}
            className={styles.textWidth}
            onChange={onChange('original_amount')}
            inputProps={{min: 0}}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Typography mb={2}>
            Document File <span className="required">*</span>
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              variant="outlined"
              className={styles.textWidth}
              value={state.document}
              fullWidth
            />
            <FileActions 
              handleFileDrive={onChange('document')} 
              docPath={onChange('file')}
              isAdd={isAddType}
              isEdit={isEditType}
              docFile={state.document}
            />
          </Stack>
          <FileTypes />
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Discount<span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            className={styles.textWidth}
            onChange={onChange('discount')}
            value={state.discount}
            inputProps={{min: 0}}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <Typography mb={2}>
            Discounted Amount<span className="required">*</span>
          </Typography>
          <TextField
            variant="outlined"
            type="number"
            value={state.discounted_amount}
            className={styles.textWidth}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default connector(Index);

import LogoutIcon from '@mui/icons-material/Logout';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export const category = {
  LOGOUT: {
    color: '#1D1C52',
    icon: <LogoutIcon />
  },
  UPDATE: {
    color: '#4A78DF',
    icon: <CloudDoneOutlinedIcon />
  },
  DELETE: {
    color: '#D03C31',
    icon: <DeleteOutlinedIcon />
  },
  CANCEL: {
    color: '#393939',
    icon: <ErrorOutlineOutlinedIcon />
  },
  SUCCESSFUL: {
    color: '#169865',
    icon: <CheckCircleOutlinedIcon />
  },
  FILEUPDATED: {
    color: '#169865',
    icon: <CloudDoneOutlinedIcon />
  },
  ERROR: {
    color: '#E87E29',
    icon: <ErrorOutlineOutlinedIcon />
  }
};

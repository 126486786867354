import {isType} from 'utils';

const initState = {
  search: '',
  start_date: '',
  end_date: '',
  status: {id: '', value: 'All'},
  search_by: '',
  seleced_items: [],
  status_count: {}
};

const overviewReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {search, search_by, status, date_start, date_end, seleced_items, status_count} =
        action.payload;
      return {
        search: isType('string', search, state.search),
        search_by: isType('string', search_by, state.search_by),
        status: isType('object', status, state.status),
        date_start: isType('string', date_start, state.date_start),
        date_end: isType('string', date_end, state.date_end),
        selected_items: isType('array', seleced_items, state.seleced_items),
        status_count: isType('object', status_count, state.status_count),
      };

    default:
      return state;
  }
};

export {initState as overviewInitState};
export default overviewReducer;

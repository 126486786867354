import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import {setProposalResource} from '../actions';

function* post({payload, document, dispatch}) {
  try {
    if (document)
      yield call(poServer.post, '/api/v1/proposal/document/', document);
    yield call(poServer.post, '/api/v1/proposal', payload);
    yield put(push('/dashboard/proposal'));
  } catch (error) {
    yield put(setProposalResource({error}));
  } finally {
  }
}

export default post;

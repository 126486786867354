import {poServer} from 'api';
import {push} from 'redux-first-history';
import {call, put} from 'redux-saga/effects';

function* edit({type, payload, document, params}) {
  delete payload['actions'];
  delete payload['po_number'];
  try {
    if (document)
      yield call(poServer.put, '/api/v1/goods-receipt/document', document);
    yield call(poServer.put, `/api/v1/goods-receipt/${params}`, payload);
    yield put(push('/dashboard/good-receipt'));
  } catch (error) {
  } finally {
  }
}

export default edit;

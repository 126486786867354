import React, {useEffect, useRef, useReducer} from 'react';

import {getDataByCondition} from './utils';

import reducer, {initState, initializer} from './reducer';
import {Input, List} from './components';
import styles from './.module.css';

const Dropdown = ({
  alt = '',
  data = [],
  disabled,
  customIcon,
  placeholder,
  selected,
  selectedList,
  display,
  sort = 'DESC',
  width,
  multiselect,
  inputClassName,
  onChange = () => {},
  selectedChange = () => {},
  selectedListChange = () => {}
}) => {
  const initRender = useRef(true);
  const dropdownRef = useRef();

  const [state, setState] = useReducer(
    reducer,
    initState,
    initializer({alt, data, selected, selectedList, multiselect})
  );

  const getStateValue = () => {
    if (multiselect) {
      let selected_list = state.selected_list;
      if (sort === 'ASC') {
        selected_list.sort(function (a, b) {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        });
      }
      selected_list = selected_list.map(({code}) => code);

      if (display === 'length') {
        return selected_list.length;
      }
      return selected_list;
    }
    return (selected && selected.value) || state.selected.value || '';
  };
  const onSetState = (key, val) => {
    setState({type: 'SET', payload: {[key]: val}});
  };
  const getList = () => {
    let _filter = () => {};
    if (selected) {
      _filter = (item) => item.value !== selected.value;
    } else {
      _filter = (item) => item.value !== state.selected.value;
    }
    return multiselect ? data : data.filter(_filter);
  };

  const onShowList = () => {
    onSetState('listVisible', !state.listVisible);
  };
  const onSelect = (item) => {
    if (multiselect) {
      const is_exist = state.selected_list.find(
        (select) => select.code === item.code
      );
      if (is_exist) {
        setState({type: 'POP-SELECTED-LIST', payload: {selected: item}});
        return;
      }
      setState({type: 'PUSH-SELECTED-LIST', payload: {selected: item}});
      return;
    }
    onSetState('selected', item);
    onSetState('listVisible', false);
  };
  const onStateChangeListener = () => {
    if (initRender.current) {
      initRender.current = false;
      return;
    }
    if (multiselect) {
      selectedListChange(state.selected_list);
      return;
    }
    selectedChange(state.selected);
  };
  const onStyleChangeListener = () => {
    const getPropertyValue = (ref, prop) => {
      return getComputedStyle(ref).getPropertyValue(prop);
    };
    if (dropdownRef.current) {
      const parentWidth = getPropertyValue(dropdownRef.current, 'width');
      const parentHeight = getPropertyValue(dropdownRef.current, 'height');
      onSetState('listStyle', {
        width: parentWidth,
        marginTop: `calc(${parentHeight} + ${parentHeight} * 0.075)`
      });
    }
  };
  useEffect(
    onStateChangeListener,
    getDataByCondition(multiselect, [state.selected_list], [state.selected])
  );
  useEffect(onStyleChangeListener, [dropdownRef.current, state.listVisible]);

  return (
    <div
      ref={dropdownRef}
      className={styles['dropdown']}
      style={{width}}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          onSetState('listVisible', false);
        }
      }}
    >
      <Input
        disabled={disabled}
        inputClassName={inputClassName}
        customIcon={customIcon}
        placeholder={placeholder}
        style={{width}}
        multiselect={multiselect}
        value={getStateValue()}
        listShow={state.listVisible}
        onChange={onChange}
        onClick={onShowList}
      />
      {state.listVisible && (
        <List
          data={getList()}
          multiselect={multiselect}
          selectedList={state.selected_list}
          onSelect={onSelect}
          style={state.listStyle}
        />
      )}
    </div>
  );
};

export default Dropdown;

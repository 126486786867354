import {call, put} from 'redux-saga/effects';

import {setPokemon} from '../actions';
import {pokemonServer} from 'api';
import {push} from 'redux-first-history';

function* get() {
  try {
    const {results} = yield call(pokemonServer.get, '/');
    yield put(setPokemon({data: results}));
    yield put(push('/sample-redirect-from-saga'));
  } catch (error) {
    yield put(setPokemon({error}));
  } finally {
    yield put(setPokemon({loading: false}));
  }
}

export default get;

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import React from 'react';

import {iconCategory} from './UpperCards.const';

const UpperCard = ({title = '', data, onClick = () => {}}) => {
  const getStyle = () => iconCategory[title.toUpperCase()];
  return (
    <Stack
      direction="row"
      sx={{
        border: `3px solid ${getStyle()?.color}`,
        borderRadius: '15px',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: {md: 0.5, lg: 1.5},
        px: {md: 2, lg: 2.5},
        minWidth: 190,
        width: {lg: 200, xl: '16.5rem'}
      }}
      onClick={onClick}
    >
      <div>
        <Typography
          sx={{
            color: getStyle()?.color,
            fontSize: {md: '2rem', lg: '2.5rem', xl: '2.75rem'}
          }}
        >
          {data}
        </Typography>
        <Typography
          sx={{
            color: getStyle()?.color,
            fontSize: {lg: '.8rem', xl: '1rem'}
          }}
        >
          {title}
        </Typography>
      </div>
      <div>{getStyle()?.icon}</div>
    </Stack>
  );
};

export default UpperCard;

import {isType} from 'utils';

const initState = {
  listVisible: false,
  listStyle: {width: ''},
  selected: {value: ''},
  selected_list: []
};

const initializer =
  ({data, selected, selectedList, multiselect}) =>
  (state) => {
    return {
      selected: selected || state.selected,
      selected_list: multiselect
        ? selectedList.map((code) => {
            const selected = data.find((item) => item.code === code);
            return selected;
          })
        : state.selected_list
    };
  };

// const initializer =
//   ({data, selected = {}, selectedList = [], multiselect}) =>
//   () => {
//     const type = 'SET';
//     return {
//       type,
//       payload: reducer(initState, {
//         type,
//         payload: {
//           selected,
//           selected_list: selectedList.map((code) => {
//             const selected = data.find((item) => item.code === code);
//             return selected;
//           })
//         }
//       })
//     };
//   };

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {listVisible, listStyle, selected, selected_list} = action.payload;
      return {
        listVisible: isType('boolean', listVisible, state.listVisible),
        listStyle: isType('object', listStyle, state.listStyle),
        selected: isType('object', selected, state.selected),
        selected_list: isType('array', selected_list, state.selected_list)
      };

    case 'PUSH-SELECTED-LIST':
      const {selected: push_selected} = action.payload;
      return {
        ...state,
        selected_list: [...state.selected_list, push_selected]
      };

    case 'POP-SELECTED-LIST':
      const {selected: pop_selected} = action.payload;
      return {
        ...state,
        selected_list: state.selected_list.filter(
          ({code}) => code !== pop_selected.code
        )
      };

    default:
      return state;
  }
};

export {initState, initializer};
export default reducer;

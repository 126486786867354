import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setGoodReceipt} from '../actions';

function* getOne({type, params}) {
  try {
    const {result: proposal} = yield call(
      poServer.get,
      `/api/v1/goods-receipt/${params.id}`
    );
    yield put(
      setGoodReceipt({
        data_edit_selected: {
          proposal
        }
      })
    );
  } catch (error) {
    yield put(setGoodReceipt({error}));
  } finally {
    yield put(setGoodReceipt({loading: false}));
  }
}

export default getOne;

function get(key) {
  return localStorage.getItem(key);
}

function set(key, value) {
  return localStorage.setItem(key, value);
}

function remove(key) {
  return localStorage.removeItem(key);
}

function removeAll() {
  return localStorage.clear();
}

const storage = {
  get,
  set,
  remove,
  removeAll
};
export default storage;

import {
  GET_PROPOSAL_RESOURCE,
  GET_ALL_PROPOSAL_RESOURCE,
  GET_ID_DETAIL_PROPOSAL_RESOURCE,
  GET_DETAILS_PROPOSAL_RESOURCE,
  PUT_PROPOSAL_RESOURCE,
  PUT_DOCUMENT_PROPOSAL_RESOURCE,
  POST_PROPOSAL_RESOURCE,
  POST_DOCUMENT_PROPOSAL_RESOURCE,
  DELETE_PROPOSAL_RESOURCE,
  DELETE_DOCUMENT_PROPOSAL_RESOURCE,
  SET_PROPOSAL_RESOURCE,
  SEARCH_PROPOSAL_RESOURCE,
  SET_ERROR_PROPOSAL
} from './types';

// GET
const getProposalResource = (payload) => ({
  type: GET_PROPOSAL_RESOURCE,
  payload
});
const getAllProposalResource = (params) => ({
  type: GET_ALL_PROPOSAL_RESOURCE,
  params
});
const getIDDetailProposalResource = (payload) => ({
  type: GET_ID_DETAIL_PROPOSAL_RESOURCE,
  payload
});
const getDetailsProposalResource = () => ({
  type: GET_DETAILS_PROPOSAL_RESOURCE
});
const searchProposalResource = (payload) => ({
  type: SEARCH_PROPOSAL_RESOURCE,
  payload
});

// POST
const postProposalResource = (payload, document) => ({
  type: POST_PROPOSAL_RESOURCE,
  payload,
  document
});

const postDocumentProposalResource = (payload) => ({
  type: POST_DOCUMENT_PROPOSAL_RESOURCE,
  payload
});

// PUT
const putProposalResource = (payload, document, params) => ({
  type: PUT_PROPOSAL_RESOURCE,
  payload,
  document,
  params
});
const putDocumentProposalResource = (payload, params) => ({
  type: PUT_DOCUMENT_PROPOSAL_RESOURCE,
  payload,
  params
});

// DELETE
const deleteProposalResource = (payload) => ({
  type: DELETE_PROPOSAL_RESOURCE,
  payload
});
const deleteDocumentProposalResource = (payload) => ({
  type: DELETE_DOCUMENT_PROPOSAL_RESOURCE,
  payload
});

const setErrorProposalResource = (payload) => ({
  type: SET_ERROR_PROPOSAL,
  payload
});

// STATE ACTIONS
const setProposalResource = ({
  data,
  error,
  loading,
  page,
  size,
  total_data,
  data_selected
}) => ({
  type: SET_PROPOSAL_RESOURCE,
  payload: {data, error, loading, page, size, total_data, data_selected}
});

export {
  getProposalResource,
  getAllProposalResource,
  getIDDetailProposalResource,
  getDetailsProposalResource,
  putProposalResource,
  putDocumentProposalResource,
  postProposalResource,
  postDocumentProposalResource,
  deleteProposalResource,
  deleteDocumentProposalResource,
  setProposalResource,
  searchProposalResource,
  setErrorProposalResource
};

import {isType} from 'utils';

import goodReceiptSaga from './saga';
import {
  getAllGoodReceipt,
  getGoodReceipt,
  getGoodReceiptDetail,
  postGoodReceipt,
  postGoodReceiptDoc,
  setGoodReceipt,
  delGoodReceipt,
  delGoodReceiptDoc,
  putGoodReceipt,
  putGoodReceiptDoc,
  searchGoodReceipt,
  setErrorGoodReceipt,
  getOneGoodReceipt
} from './actions';
import {
  GET_GOOD_RECEIPT_DETAIL,
  POST_GOOD_RECEIPT,
  POST_GOOD_RECEIPT_DOC,
  SET_ERROR_GOOD_RECEIPT,
  SET_GOOD_RECEIPT
} from './types';

const initState = {
  data: [],
  page: 0,
  size: 0,
  total_data: 0,
  error: '',
  loading: false,
  data_selected: {},
  data_edit_selected: {}
};

const goodReceipt = (state = initState, action) => {
  switch (action.type) {
    case SET_ERROR_GOOD_RECEIPT:
      return {
        ...state,
        error: action.payload
      };
    case SET_GOOD_RECEIPT:
      const {
        data,
        error,
        loading,
        page,
        size,
        total_data,
        data_selected,
        data_edit_selected
      } = action.payload;
      return {
        data: isType('object', data, state.data),
        page: isType('number', page, state.page),
        size: isType('number', size, state.size),
        total_data: isType('number', total_data, state.total_data),
        error: isType('string', error, state.error),
        loading: isType('string', loading, state.loading),
        data_selected: isType('object', data_selected, state.data_selected),
        data_edit_selected: isType(
          'object',
          data_edit_selected,
          state.data_edit_selected
        )
      };

    default:
      return state;
  }
};

export {
  getAllGoodReceipt,
  getGoodReceipt,
  getGoodReceiptDetail,
  postGoodReceipt,
  postGoodReceiptDoc,
  setGoodReceipt,
  goodReceiptSaga,
  delGoodReceipt,
  delGoodReceiptDoc,
  putGoodReceipt,
  putGoodReceiptDoc,
  searchGoodReceipt,
  setErrorGoodReceipt,
  getOneGoodReceipt
};

export default goodReceipt;

import {isType} from 'utils';

const initState = {
  invoice_no: '',
  status: '11',
  date: '',
  amount: 0,
  actions: null,
  purchase_order: {},
  coca: {},
  document: '',
  po_number: 0
};

const initializer = (payload) => (state) => {
  return {
    type: 'SET',
    payload: {
      ...state,
      ...payload
    }
  };
};

const invoiceForm = (state = initState, action) => {
  switch (action.type) {
    case 'SET':
      const {
        invoice_no,
        status,
        date,
        amount,
        purchase_order,
        coca,
        document,
        po_number,
        actions
      } = action.payload;
      return {
        invoice_no: isType('string', invoice_no, state.invoice_no),
        status: isType('string', status, state.status),
        date: isType('string', date, state.date),
        amount: isType('number', amount, state.amount),
        purchase_order: isType('object', purchase_order, state.purchase_order),
        coca: isType('object', coca, state.coca),
        document: isType('string', document, state.document),
        po_number: isType('number', po_number, state.po_number),
        actions: isType('object', actions, state.actions)
      };
    default:
      return state;
  }
};

export {
  initState as invoiceFormInitState,
  initializer as invoiceFormInitializer
};

export default invoiceForm;

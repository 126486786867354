import {replace} from 'redux-first-history';
import {call, put} from 'redux-saga/effects';

import storage from 'utils/storage';
import {setAuth} from '../actions';

function* logout({type, payload}) {
  try {
    yield put(replace('/'));
    yield call(storage.remove, 'at');
  } catch (error) {
    yield put(setAuth({error}));
  }
}

export default logout;

import {poServer} from 'api';
import {push} from 'redux-first-history';
import {call, put} from 'redux-saga/effects';
import {setErrorInvoice} from '../actions';

function* post({type, payload, document}) {
  try {
    if (document)
      yield call(poServer.post, '/api/v1/invoice/document', document);
    yield call(poServer.post, '/api/v1/invoice', payload);
    yield put(push('/dashboard'));
  } catch (error) {
    yield put(setErrorInvoice(error));
  } finally {
  }
}

export default post;

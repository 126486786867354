const ROLE = Object.freeze({
  SUPERADMIN: 'super-admin'
});

// STATUS REQUEST
const PENDING = 'PENDING';
const ONGOING = 'ONGOING';
const RENDERED = 'RENDERED';
const PAID = 'PAID';

const SUBPATH = '/po_tracker';

export {ROLE, SUBPATH, PENDING, ONGOING, RENDERED, PAID};

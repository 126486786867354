import Stack from '@mui/material/Stack';
import Button from '../../Button';

import styles from '../../../styles/Button.module.scss';

const index = ({onClose, onSave, absolute = false, title, disabled}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={2}
      className={`${absolute ? styles.absoluteBtn : styles.buttonRight}`}
    >
      <Button
        variant="outlined"
        label="cancel"
        buttonType="cancel"
        onClick={onClose}
        className={styles.stepFormButtonSize}
        small
      />
      <Button
        variant="contained"
        label={title}
        buttonType="save"
        className={styles.stepFormButtonSize}
        onClick={onSave}
        small
        disabled={disabled}
      />
    </Stack>
  );
};

export default index;

import {connect} from 'react-redux';

const mapState = ({
  router,
  pokemon,
  auth,
  invoice,
  goodReceipt,
  proposalResource,
  coca,
  purchaseOrder,
  status,
  documents
}) => ({
  router,
  pokemon,
  auth,
  invoice,
  goodReceipt,
  proposalResource,
  coca,
  purchaseOrder,
  status, 
  documents
});
const mapDispatch = (dispatch) => ({
  dispatch: (action) => dispatch(action)
});

const connector = connect(mapState, mapDispatch);

export default connector;

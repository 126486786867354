import {takeLatest} from 'redux-saga/effects';

import {
  GET_ALL_INVOICE,
  DELETE_INVOICE,
  POST_INVOICE,
  SEARCH_INVOICE,
  GET_INVOICE_DETAILS,
  GET_ONE_INVOICE,
  PUT_INVOICE
} from '../types';
import getAll from './getAll';
import del from './delete';
import post from './post';
import getSearch from './search';
import getID from './getId';
import getOne from './getOne';
import edit from './put';

function* invoiceSaga() {
  yield takeLatest(GET_ALL_INVOICE, getAll);
  yield takeLatest(SEARCH_INVOICE, getSearch);
  yield takeLatest(GET_INVOICE_DETAILS, getID);
  yield takeLatest(GET_ONE_INVOICE, getOne);
  yield takeLatest(DELETE_INVOICE, del);
  yield takeLatest(POST_INVOICE, post);
  yield takeLatest(PUT_INVOICE, edit);
}

export default invoiceSaga;

import styles from './.module.css';

const TextInput = ({
  prefixComponent,
  mainClassName,
  controllerClassName,
  placeholder,
  value,
  onEnter = () => {},
  onChangeText = () => {}
}) => {
  return (
    <div className={`${styles['text-input']} ${mainClassName}`}>
      {prefixComponent && (
        <div className={styles['prefix-pane']}>{prefixComponent}</div>
      )}
      <input
        placeholder={placeholder}
        className={`${styles['input-controller']} ${controllerClassName}`}
        value={value}
        onChange={({target: {value}}) => onChangeText(value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') onEnter();
        }}
      />
    </div>
  );
};

export default TextInput;

import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setProposalResource} from '../actions';

function* getIDDetail({type, payload}) {
  try {
    const {result: proposal} = yield call(
      poServer.get,
      `/api/v1/proposal/details/${payload.id}`
    );
    //GET REQUEST
    yield put(
      setProposalResource({
        data_selected: {
          coca: proposal.coca,
          goods_receipt: proposal.goods_receipt,
          invoice: proposal.invoice,
          proposal: proposal.proposal,
          purchase_order: proposal.purchase_order
        }
      })
    );
  } catch (error) {
    yield put(setProposalResource({error}));
  } finally {
    yield put(setProposalResource({loading: false}));
  }
}

export default getIDDetail;

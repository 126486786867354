// GET
const GET_COCA = 'GET-COCA';
const GET_ALL_COCA = 'GET-ALL-COCA';
const GET_ID_DETAIL_COCA = 'GET-ID-DETAIL-COCA';
const GET_DETAILS_COCA = 'GET-DETAILS-COCA';
const SEARCH_COCA = 'SEARCH_COCA';

// POST
const POST_COCA = 'POST-COCA';
const POST_DOCUMENT_COCA = 'POST-DOCUMENT-COCA';

// PUT
const PUT_COCA = 'EDIT-COCA';
const PUT_DOCUMENT_COCA = 'EDIT-DOCUMENT-COCA';

// DELETE
const DELETE_COCA = 'DELETE-COCA';
const DELETE_DOCUMENT_COCA = 'DELETE-DOCUMENT-COCA';

// SET
const SET_COCA = 'SET-COCA';
const SET_ERROR_COCA = 'SET_ERROR_COCA';

export {
  GET_COCA,
  GET_ALL_COCA,
  GET_ID_DETAIL_COCA,
  GET_DETAILS_COCA,
  POST_COCA,
  POST_DOCUMENT_COCA,
  PUT_COCA,
  PUT_DOCUMENT_COCA,
  DELETE_COCA,
  DELETE_DOCUMENT_COCA,
  SET_COCA,
  SET_ERROR_COCA,
  SEARCH_COCA
};

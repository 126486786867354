import {takeLatest} from 'redux-saga/effects';

import {
  DEL_PURCHASE_ORDER,
  POST_PURCHASE_ORDER,
  PUT_PURCHASE_ORDER,
  GET_ALL_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_DETAILS
} from '../types';
import del from './delete';
import post from './post';
import edit from './put';
import getAll from './getAll';
import setDetail from './getDetail';

function* purchaseOrderSaga() {
  yield takeLatest(POST_PURCHASE_ORDER, post);
  yield takeLatest(PUT_PURCHASE_ORDER, edit);
  yield takeLatest(DEL_PURCHASE_ORDER, del);
  yield takeLatest(GET_ALL_PURCHASE_ORDER, getAll);
  yield takeLatest(GET_PURCHASE_ORDER_DETAILS, setDetail);
}

export default purchaseOrderSaga;

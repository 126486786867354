import {
  GET_GOOD_RECEIPT,
  GET_ALL_GOOD_RECEIPT,
  GET_GOOD_RECEIPT_DETAIL,
  SET_GOOD_RECEIPT,
  POST_GOOD_RECEIPT,
  POST_GOOD_RECEIPT_DOC,
  DELETE_GOOD_RECEIPT,
  DELETE_GOOD_RECEIPT_DOC,
  PUT_GOOD_RECEIPT,
  PUT_GOOD_RECEIPT_DOC,
  SEARCH_GOOD_RECEIPT,
  GET_ONE_GOOD_RECEIPT,
  SET_ERROR_GOOD_RECEIPT
} from './types';

const getGoodReceipt = () => ({
  type: GET_GOOD_RECEIPT
});

const getAllGoodReceipt = (params) => ({
  type: GET_ALL_GOOD_RECEIPT,
  params
});

const getOneGoodReceipt = (params) => ({
  type: GET_ONE_GOOD_RECEIPT,
  params
});

const getGoodReceiptDetail = (payload) => ({
  type: GET_GOOD_RECEIPT_DETAIL,
  payload
});

const searchGoodReceipt = (payload) => ({
  type: SEARCH_GOOD_RECEIPT,
  payload
});

const postGoodReceipt = (payload, document) => ({
  type: POST_GOOD_RECEIPT,
  payload,
  document
});
const postGoodReceiptDoc = (payload) => ({
  type: POST_GOOD_RECEIPT_DOC,
  payload
});

const setGoodReceipt = (payload) => ({
  type: SET_GOOD_RECEIPT,
  payload
});

const delGoodReceipt = (payload) => ({
  type: DELETE_GOOD_RECEIPT,
  payload
});

const delGoodReceiptDoc = (payload) => ({
  type: DELETE_GOOD_RECEIPT_DOC,
  payload
});

const putGoodReceipt = (payload, document, params) => ({
  type: PUT_GOOD_RECEIPT,
  payload,
  document,
  params
});

const putGoodReceiptDoc = (payload) => ({
  type: PUT_GOOD_RECEIPT_DOC,
  payload
});

const setErrorGoodReceipt = (payload) => ({
  type: SET_ERROR_GOOD_RECEIPT,
  payload
})

export {
  getGoodReceipt,
  getGoodReceiptDetail,
  postGoodReceipt,
  postGoodReceiptDoc,
  getAllGoodReceipt,
  setGoodReceipt,
  delGoodReceipt,
  delGoodReceiptDoc,
  putGoodReceipt,
  putGoodReceiptDoc,
  searchGoodReceipt,
  getOneGoodReceipt,
  setErrorGoodReceipt
};

import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import {setErrorCoca} from '../actions';

function* post({payload, document}) {
  delete payload['actions'];
  try {
    if (document) yield call(poServer.post, '/api/v1/coca/document', document);
    yield call(poServer.post, '/api/v1/coca', payload);
    yield put(push('/dashboard/coca'));
  } catch (error) {
    yield put(setErrorCoca(error));
  } finally {
  }
}

export default post;

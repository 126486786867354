import {AUTH_LOGIN, AUTH_LOGOUT, SET_AUTH} from './types';

const authLogout = () => ({
  type: AUTH_LOGOUT
});

const authLogin = ({username, password}) => ({
  type: AUTH_LOGIN,
  payload: {username, password}
});

const setAuth = ({authorized}) => ({
  type: SET_AUTH,
  payload: {authorized}
});

export {authLogin, authLogout, setAuth};

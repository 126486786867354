import axios from 'axios';
import pokemonServer from './services';

import {POKEMON_API_URL} from 'constants/env';

const pokemonServerConfig = axios.create({
  baseURL: `${POKEMON_API_URL}`
});

export {pokemonServer as default, pokemonServerConfig};

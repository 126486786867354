import {takeLatest} from 'redux-saga/effects';
import {GET_ALL_STATUS, GET_STATUS_COUNT} from '../types';

import getAll from './get_all';
import getStatusCount from './getStatusCount';

function* statusSaga() {
  yield takeLatest(GET_ALL_STATUS, getAll);
  yield takeLatest(GET_STATUS_COUNT, getStatusCount);
}

export default statusSaga;

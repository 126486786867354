import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Index = ({severity, open, title, vertical, horizontal}) => {
  return (
    <Snackbar open={open} autoHideDuration={2} anchorOrigin={{vertical, horizontal}}>
      <MuiAlert
        severity={severity}
        elevation={6}
        variant="filled"
        sx={{width: '20rem', marginLeft: '10rem'}}
      >
        {title}
      </MuiAlert>
    </Snackbar>
  );
};

export default Index;

import {useState} from 'react';

import Button from '@mui/material/Button';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import EditFormModal from '../Modals/EditFormModal';

const Index = ({children, title}) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <EditFormModal
        title={`EDIT ${title.toUpperCase()} ENTRY`}
        open={openForm}
        onClose={() => setOpenForm(false)}
      >
        {children}
      </EditFormModal>
      <Button
        variant="text"
        sx={{fontSize: {md: '.7rem', lg: '.8rem', xl: '.9rem'}}}
        startIcon={<EditOutlinedIcon />}
        onClick={() => setOpenForm(true)}
      >
        Edit {title}
      </Button>
    </>
  );
};

export default Index;

import {IconButton} from "@mui/material";
import {FileDownloadOutlined} from '@mui/icons-material';
import {PO_API_URL} from "constants/env";
import {connector} from "ducks";

const DownloadFile = ({fileName, dispatch, documents, isAccordion}) => {
    return (
        <>
            {fileName ? (
                <>
                    {!isAccordion ? fileName : null}
                    <a href={`${PO_API_URL}/documents/${fileName}`} target="_blank" download>
                        <IconButton >
                            <FileDownloadOutlined />
                        </IconButton>
                    </a>
                </>
            ): null}
        </>
    )
}

export default connector(DownloadFile);
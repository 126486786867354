import {call, put} from "redux-saga/effects";

function* put_user({type, payload}) {
  try{

  } catch(error) {

  } finally {

  }
}

export default put_user;
import React from 'react';

import {MdCheckBoxOutlineBlank, MdCheckBox} from 'react-icons/md';

import styles from './.module.css';

const Checkbox = ({label, isCheck, onToggle = () => {}}) => {
  const onChangeStatus = (e) => {
    onToggle(!isCheck);
  };

  return (
    <button className={styles['checkbox']} onClick={onChangeStatus}>
      {isCheck ? (
        <MdCheckBox className={styles['icon-check']} />
      ) : (
        <MdCheckBoxOutlineBlank className={styles['icon']} />
      )}
      {label && <p>{label}</p>}
    </button>
  );
};

export default Checkbox;

import {isType} from 'utils';

import invoiceSaga from './saga';
import {
  getInvoice,
  getAllInvoice,
  setInvoice,
  delInvoice,
  postInvoice,
  searchInvoice,
  putInvoice,
  getInvoiceDetails,
  getOneInvoice,
  setErrorInvoice
} from './actions';
import {SET_ERROR_INVOICE, SET_INVOICE} from './types';

const initState = {
  data: [],
  page: 0,
  size: 0,
  total_data: 0,
  error: '',
  loading: false,
  data_selected: {},
  data_edit_selected: {}
};

const invoice = (state = initState, action) => {
  switch (action.type) {
    case SET_ERROR_INVOICE:
      return {
        ...state,
        error: action.payload
      };
    case SET_INVOICE:
      const {
        data,
        error,
        loading,
        page,
        size,
        total_data,
        data_selected,
        data_edit_selected
      } = action.payload;
      return {
        data: isType('array', data, state.data),
        page: isType('number', page, state.page),
        size: isType('number', size, state.size),
        total_data: isType('number', total_data, state.total_data),
        error: isType('string', error, state.error),
        loading: isType('boolean', loading, state.loading),
        data_selected: isType('object', data_selected, state.data_selected),
        data_edit_selected: isType(
          'object',
          data_edit_selected,
          state.data_edit_selected
        )
      };

    default:
      return state;
  }
};

export {
  getInvoiceDetails,
  getOneInvoice,
  setErrorInvoice,
  getInvoice,
  getAllInvoice,
  setInvoice,
  invoiceSaga,
  delInvoice,
  postInvoice,
  searchInvoice,
  putInvoice
};
export default invoice;

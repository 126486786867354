import {isType} from 'utils';
import {SET} from './types';

const initState = {
  username: '',
  password: '',
  showPassword: false
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case SET:
      const {username, password, showPassword} = action.payload;
      return {
        showPassword: isType('boolean', showPassword, state.showPassword),
        username: isType('string', username, state.username),
        password: isType('string', password, state.password)
      };

    default:
      return state;
  }
};

export {initState as loginInitState};
export default loginReducer;

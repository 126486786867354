import Stack from '@mui/material/Stack';
import Button from '../../Button';

import styles from '../../../styles/Button.module.scss';

const Index = ({onCancel, onBack, onNext, onSave, step}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      className={styles.multiButton}
    >
      <Stack direction="row" spacing={3}>
        {onBack && onNext ? (
          <>
            <Button
              variant="outlined"
              label="cancel"
              buttonType="cancel"
              onClick={onCancel}
              small
            />
            <Button
              variant="outlined"
              label="back"
              buttonType="back"
              onClick={onBack}
              disabled={step === 0}
              small
            />
            <Button
              variant="contained"
              label="next"
              buttonType="next"
              onClick={onNext}
              disabled={step >= 4}
              small
            />
          </>
        ) : null}
      </Stack>

      <Stack direction="row" justifyContent="flex-end" spacing={3}>
        {!onNext && !onBack ? (
          <Button
            variant="outlined"
            label="cancel"
            buttonType="cancel"
            onClick={onCancel}
            small
          />
        ) : null}
        <Button
          variant="contained"
          label="save"
          buttonType="save"
          className={styles.stepFormButtonSize}
          onClick={onSave}
          small
        />
      </Stack>
    </Stack>
  );
};

export default Index;

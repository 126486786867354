import {poServer} from 'api';
import {call} from 'redux-saga/effects';

function* putDoc({type, payload}) {
  try {
    const result = yield call(
      poServer.put,
      '/api/v1/goods-receipt/document',
      payload
    );
  } catch (error) {
  } finally {
  }
}

export default putDoc;

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from '../../Button';
import ModalLayout from '../ModalLayout';

import styles from '../../../styles/ModalLayout.module.scss';

const index = ({open, onClose, field}) => {
  return (
    <ModalLayout open={open} type="error">
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={styles.errorModal}
      >
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h5"> Oops! </Typography>
          <Typography variant="p">
            The {field} you entered already exists. Please enter a different ID.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2.5}
          justifyContent="center"
          sx={{width: '100%'}}
        >
          <Button
            buttonType="ERROR"
            variant="error"
            label="close"
            onClick={onClose}
          />
        </Stack>
      </Stack>
    </ModalLayout>
  );
};

export default index;

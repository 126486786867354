import {poServer} from 'api';

import {call, put} from 'redux-saga/effects';
import {setCOCA} from '../actions';

function* getIDDetail({type, payload}) {
  try {
    const {result: coca} = yield call(
      poServer.get,
      `/api/v1/coca/details/${payload.id}`
    );
    yield put(setCOCA({data_selected: coca}));
  } catch (error) {
    yield put(setCOCA({error}));
  } finally {
    yield put(setCOCA({loading: false}));
  }
}

export default getIDDetail;

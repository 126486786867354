import {poServer} from 'api';
import {call} from 'redux-saga/effects';

function* delDoc() {
  try {
    const result = yield call(poServer.del, '/api/v1/goods-receipt/document');
  } catch (error) {
  } finally {
  }
}

export default delDoc;

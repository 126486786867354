import Grid from '@mui/material/Grid';
import Sidebar from './Sidebar';
const DashboardLayout = ({children}) => {
  return (
    <Grid container>
      <Grid item xs={1}>
        <Sidebar />
      </Grid>

      <Grid item xs={11}>
        {children}
      </Grid>
    </Grid>
  );
};

export default DashboardLayout;

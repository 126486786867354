import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {setProposalResource} from '../actions';

function* search({type, payload}) {
  try {
    const {
      result: {data, metadata}
    } = yield call(
      poServer.get,
      `/api/v1/proposal/list?search=${payload.search}`
    );
    yield put(
      setProposalResource({
        data,
        size: metadata.current_size,
        page: metadata.current_page,
        total_page: metadata.total_page,
        total_data: metadata.total_size
      })
    );
  } catch (error) {
    yield put(setProposalResource({error}));
  } finally {
    yield put(setProposalResource({loading: false}));
  }
}

export default search;

import {useCallback, useEffect, useReducer, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardLayout from 'components/DashboardLayout';
import StepFormLayout from 'components/StepFormLayout';

import ProposalsForm from 'components/Forms/ProposalsForm';

import SaveButton from 'components/StepFormButtons/SaveButton';

import CancelModal from 'components/Modals/Cancel';

import {connector} from 'ducks';
import {PROPOSAL, DASHBOARD} from 'utils/const';
import {
  getAllProposalResource,
  getIDDetailProposalResource,
  putProposalResource
} from 'ducks/proposal_resource';

import {Box} from '@mui/material';
import styles from 'styles/Form.module.scss';
import proposalForm, {proposalInitState} from 'reducers/proposal';
import {push} from 'redux-first-history';

const EditProposal = ({dispatch, proposalResource, router}) => {
  const {location} = router;
  const navigate = useNavigate();

  const [cancel, setCancel] = useState(false);
  const [proposalResourceData, setProposalResourceData] = useState({});
  const [state, setState] = useReducer(proposalForm, proposalInitState);

  const handleSave = () => {
    if (
      proposalResourceData &&
      proposalResourceData.name &&
      proposalResourceData.proposal_no &&
      proposalResourceData.date
    ) {
      dispatch(
        putProposalResource(proposalResourceData, proposalResourceData.id)
      );
    } else {
      alert('INVALID INPUT! Please review your inputs.');
    }
  };

  const stateChange = (action) => {
    setState({type: 'SET', payload: {actions: action}});
  };

  const onSubmit = () => {
    dispatch(state.actions);
    dispatch(getAllProposalResource({page: 1, size: 10}));
  };

  const handleCancelForm = useCallback(() => {
    navigate(`/${DASHBOARD}/${PROPOSAL}`);
  }, []);

  useEffect(() => {
    let id = new URLSearchParams(window.location.search).get('id');
    // dispatch(getProposalResource(id));
  }, []);

  useEffect(() => {
    if (proposalResource.data.result) {
      setProposalResourceData(proposalResource.data.result);
    }
  }, [proposalResource.data]);

  const initListener = () => {
    dispatch(getIDDetailProposalResource({id: location.state.id}));
  };

  useEffect(initListener, []);

  return (
    <DashboardLayout>
      <CancelModal
        open={cancel}
        onClose={() => setCancel(false)}
        onCancel={handleCancelForm}
      />
      <StepFormLayout variant="edit-form" active={0}>
        <div className={styles.titleSpacing}>
          <Typography variant="h3">EDIT PROPOSAL</Typography>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">PROPOSAL DETAILS</Typography>
        </Stack>
        {proposalResourceData ? (
          <ProposalsForm
            proposalResourceData={proposalResourceData}
            setProposalResourceData={setProposalResourceData}
            router={location}
            stateChange={stateChange}
          />
        ) : null}
        <SaveButton
          title="Save"
          absolute
          onSave={onSubmit}
          disabled={!state.actions}
          onClose={() => setCancel(true)}
        />
      </StepFormLayout>
    </DashboardLayout>
  );
};

export default connector(EditProposal);

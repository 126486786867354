import {isType} from 'utils';

const initState = {
  proposal_id: 0,
  id: 0,
  name: '',
  po_number: '',
  original_amount: 0,
  discount: 0,
  discounted_amount: 0,
  status: '1',
  start_date: '',
  end_date: '',
  document: ''
};

const poForm = (state = initState, action) => {

  switch (action.type) {
    case 'SET':
      const {
        proposal_id, 
        id,
        name, 
        po_number, 
        original_amount, 
        discount, 
        discounted_amount, 
        status, 
        start_date,
        end_date,
        document } = action.payload;
      return {
        proposal: {
            id: isType('number', proposal_id, state.proposal_id)
        },
        id: isType('number', id, state.id),
        name: isType('string', name, state.name),
        po_number: isType('string', po_number, state.po_number),
        original_amount: isType('number', original_amount, state.original_amount),
        discount: isType('number', discount, state.discount),
        discounted_amount: isType('number', discounted_amount, state.discounted_amount),
        status: isType('number', status, state.status),
        start_date: isType('string', start_date, state.start_date),
        end_date: isType('string', end_date, state.end_date),
        document: isType('string', document, state.document)
      };
    default:
      return state;
  }
};

export {initState as poFormInitState};

export default poForm;

import OverviewIcon from '@mui/icons-material/DashboardCustomize';
import ProposalsIcon from '@mui/icons-material/Description';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import WorkspaceIcon from '@mui/icons-material/WorkspacePremium';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const sidebarLinks = [
  {
    path: '/dashboard',
    icon: <OverviewIcon />,
    label: 'OVERVIEW'
  },
  {
    path: '/dashboard/proposal',
    icon: <ProposalsIcon />,
    label: 'PROPOSAL'
  },
  {
    path: '/dashboard/coca',
    icon: <WorkspaceIcon />,
    label: 'COCA'
  },
  {
    path: '/dashboard/good-receipt',
    icon: <RvHookupIcon />,
    label: 'GOOD RECEIPT'
  },

  {
    path: '/dashboard/invoices',
    icon: <ReceiptLongIcon />,
    label: 'INVOICES'
  },
  {
    path: '/dashboard/usermgmt',
    icon: <ManageAccountsIcon />,
    label: "MANAGE USERS"
  }
];

import React from 'react';
import {FiEdit3} from 'react-icons/fi';
import {IoMdArrowDropdown, IoMdArrowDropup} from 'react-icons/io';

import styles from './.module.css';

const Input = ({
  listShow,
  placeholder,
  disabled,
  style,
  value,
  editable,
  onChange,
  customIcon,
  multiselect,
  inputClassName,
  onClick
}) => {
  return (
    <div className={`${styles['input']} ${inputClassName}`} style={style}>
      <input
        className={styles['input-controller']}
        disabled={!editable}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <button
        disabled={disabled}
        className={styles['btn-controller']}
        onClick={onClick}
      >
        {multiselect && <FiEdit3 className={styles['edit-icon']} />}
        {!multiselect &&
          (listShow ? (
            <IoMdArrowDropup className={styles['icon']} />
          ) : (
            <IoMdArrowDropdown className={styles['icon']} />
          ))}
      </button>
    </div>
  );
};

export default Input;

import {useReducer} from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import {IconButton} from '@mui/material';
import Link from '@mui/material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Status from '../Status';
import styles from '../../styles/Table.module.scss';
import TableDropdown from './TableDropdownDetails/TableDropdown';
import reducer, {initState} from './reducers/table_rown_default';
import DownloadFile from '../DownloadFile';
import {connector} from 'ducks';
import {format} from 'date-fns';
import {MAX_LENGTH_FILENAME} from 'constants/numbers';

const TableRowDefault = ({
  dispatch,
  content,
  index,
  selected,
  action = () => {},
  onSelectItem = () => {}
}) => {
  const [state, setState] = useReducer(reducer, initState);

  const onSetState = (key, value) => {
    setState({type: 'SET', payload: {[key]: value}});
  };

  const onChange = (key) => (val) => {
    if (key === 'is_collapsed') {
      onSetState(key, !state.is_collapsed);
      if (!state.is_collapsed) {
        dispatch(action({id: content.id}));
      }
    }
    onSetState(key, val);
  };
  const onSelect = () => {
    onSelectItem(content);
  };

  return (
    <>
      <TableRow className={index % 2 === 0 ? styles.highlight : styles.normal}>
        <TableCell
          className={`${styles.tableCellBody} ${styles.checkboxCell} ${styles.borderCell}`}
        >
          <Checkbox onChange={onSelect} />
        </TableCell>
        {Object.keys(content)?.map((item, index) => {
          if (item !== 'id') {
            if (item === 'document') {
              return (
                <TableCell
                  key={`${content[item]}-${index}`}
                  align="center"
                  className={`${styles.tableCellBody} ${styles.borderCell} `}
                >
                  {content[item].includes('https://') &&
                  content[item] !== '' ? (
                    <Link
                      href={content[item]}
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                    >
                      {`${content[item].substring(0, MAX_LENGTH_FILENAME)}...`}
                    </Link>
                  ) : (
                    <DownloadFile fileName={content[item]} />
                  )}
                </TableCell>
              );
            }

            if (item === 'status') {
              return (
                <TableCell
                  key={index}
                  align="center"
                  className={`${styles.tableCellBody} ${styles.borderCell} `}
                >
                  <Status {...content[item]} />
                </TableCell>
              );
            }

            if (
              item === 'date_created' ||
              item === 'start_date' ||
              item === 'end_date'
            ) {
              let date = new Date(content[item]);
              return (
                <TableCell
                  key={`${content[item]}-${index}`}
                  align="center"
                  className={styles.tableCellBody}
                >
                  {format(date, 'yyyy-MM-dd')}
                </TableCell>
              );
            }

            // To hide data that is needed for the form but is not part of the table
            if (item === 'po_number' || item === 'discount' || item === 'po_id')
              return;

            return (
              <TableCell
                key={`${content[item]}-${index}`}
                align="center"
                className={`${styles.tableCellBody} ${styles.borderCell} `}
              >
                {content[item]}
              </TableCell>
            );
          }
        })}
        <TableCell className={styles.tableCellBody}>
          <IconButton
            onClick={onChange('is_collapsed')}
            className={styles.toggleIcon}
          >
            {state.is_collapsed ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableDropdown open={state.is_collapsed} details={selected} />
    </>
  );
};

export default connector(TableRowDefault);

import {ALPHANUMERIC} from 'constants/regex';

const PUBLIC_URL = process.env.PUBLIC_URL;

const getImage = (file) => `${PUBLIC_URL}/assets/images${file}`;
const getIcon = (file) => `${PUBLIC_URL}/assets/icons${file}`;

/**
 * Get the status by status name
 * @param {Array} data
 * @param {string} status
 * @return object
 */
const getStatus = (data = [], status) => {
  let _status = status.toUpperCase();
  data.forEach((item) => {
    if (_status === item.name) {
      _status = item;
    }
  });
  return _status;
};

/**
 * get the status prefix name
 * @param {object} status
 * @returns {string}
 */
const getStatusPrefixName = (name) => {
  const last_index = name.indexOf('_');
  const status_name = last_index > 0 ? name.substring(0, last_index) : name;
  return status_name;
};

const isAlphaNumeric = (value) => {
  return ALPHANUMERIC.test(value);
};

export {getStatusPrefixName, getStatus, getImage, getIcon, isAlphaNumeric};

import {poServer} from 'api';
import {call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';

function* edit({type, payload, document, params}) {
  delete payload['actions'];
  try {
    if (document)
      yield call(poServer.put, '/api/v1/proposal/document/', document);
    yield call(poServer.put, `/api/v1/proposal/${params}`, payload);
    yield put(push('/dashboard/proposal'));
  } catch (error) {
  } finally {
  }
}

export default edit;
